export function returnPlainSlide(name) {
  return {
    component: "slide",
    script: {
      variables: {
        slideWidth: 700,
        numberOfSlides: 2,
        slideHeight: 400,
      },
      string: `
            const ${name}SlidesContainer = document.getElementById(
              "${name}-slidesContainer"
            );
            const ${name} = document.getElementById("${name}");
            const ${name}NumberOfSlides = ${name}ScriptVariables.numberOfSlides;
  
            let ${name}CurrentXposition = 0;
            let ${name}CurrentSlideIndex = 0;
  
            ${name}SlidesContainer.style.transition = "transform 1s";
            ${name}SlidesContainer.style.overflow = "unset";
            ${name}.style.overflow = "hidden";
  
            
  
  
            setInterval(() => {
              if (${name}CurrentSlideIndex === ${name}NumberOfSlides - 1) {
                ${name}SlidesContainer.style.transform = "translateX(0px)";
                console.log("right");
                ${name}CurrentSlideIndex = 0;
                ${name}CurrentXposition = 0;
              } else {
                ${name}CurrentXposition -= ${name}.offsetWidth;
                ${name}SlidesContainer.style.transform = \`translateX(\${${name}CurrentXposition}px)\`;
                console.log("left");
                ${name}CurrentSlideIndex += 1;
              }
              console.log("switch");
            }, 4000);`,
    },
    active: true,
    name: name,
    type: "slide",
    styles: {
      width: "700px",
      height: "360px",
      backgroundColor: "rgb(255, 0, 0)",
      display: "block",
      position: "absolute",
      padding: "0px",
      margin: "0px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      overflow: "hidden" /* auto */,
      top: "26px",
      left: "123px",
    },
    mobileStyles: {},
    tabletStyles: {},
    previewStyles: {
      overflow: "hidden",
    },
    index: "0",
    backgroundImage: {
      imgPathUrl: "",
    },
    nestedComponents: {
      [`${name}-slidesContainer`]: {
        active: true,
        name: `${name}-slidesContainer`,
        type: "relativeContainer",
        styles: {
          backgroundColor: "rgb(0, 0, 255)",
          display: "flex",
          position: "relative",
          padding: "0px",
          margin: "0px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          flexGrow: 1,
          flexShrink: 0,
          /*  overflow: "auto", */
        },

        index: "0",
        backgroundImage: {
          imgPathUrl: "",
        },
        nestedComponents: {
          [`${name}-slide1`]: {
            active: true,
            name: `${name}-slide1`,
            type: "relativeContainer",
            styles: {
              width: "700px",
              height: "360px",
              backgroundColor: "rgb(123,12,234)",
              display: "flex",
              position: "relative",
              padding: "0px",
              margin: "0px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              flexGrow: 1,
              flexShrink: 0,
            },
            index: "0",
            backgroundImage: {
              imgPathUrl: "",
            },
          },
          [`${name}-slide2`]: {
            active: true,
            name: `${name}-slide2`,
            type: "relativeContainer",
            styles: {
              width: "700px",
              height: "360px",
              backgroundColor: "rgb(43, 12, 99)",
              borderRadius: "pink",
              display: "flex",
              position: "relative",
              padding: "0px",
              margin: "0px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              flexGrow: 1,
              flexShrink: 0,
            },
            index: "1",
            backgroundImage: {
              imgPathUrl: "",
            },
          },
        },
      },
    },
  };
}

export const plainSlide = {
  category: "Slide Show",
  validParents: ["section", "relativeContainer"],
  component: "slide",
  active: true,
  name: "plainSlide",
  type: "slide",
  styles: {
    width: "700px",
    height: "360px",
    backgroundColor: "rgb(255, 0, 0)",
    display: "block",
    position: "relative",
    padding: "0px",
    margin: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden" /* "auto" */,
  },
  previewStyles: {
    overflow: "hidden",
  },
  index: "0",
  backgroundImage: {
    imgPathUrl: "",
  },
  nestedComponents: {
    [`${"plainSlide"}-slidesContainer`]: {
      active: true,
      name: `${"plainSlide"}-slidesContainer`,
      type: "relativeContainer",
      styles: {
        backgroundColor: "rgb(0, 0, 255)",
        display: "flex",
        position: "relative",
        padding: "0px",
        margin: "0px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        flexGrow: 1,
        flexShrink: 0,
        overflow: "auto",
      },

      index: "0",
      backgroundImage: {
        imgPathUrl: "",
      },
      nestedComponents: {
        [`${"plainSlide"}-slide1`]: {
          active: true,
          name: `${"plainSlide"}-slide1`,
          type: "relativeContainer",
          styles: {
            width: "700px",
            height: "360px",
            backgroundColor: "rgb(123,12,234)",
            display: "flex",
            position: "relative",
            padding: "0px",
            margin: "0px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            flexGrow: 1,
            flexShrink: 0,
          },
          index: "0",
          backgroundImage: {
            imgPathUrl: "",
          },
        },
        [`${"plainSlide"}-slide2`]: {
          active: true,
          name: `${"plainSlide"}-slide2`,
          type: "relativeContainer",
          styles: {
            width: "700px",
            height: "360px",
            backgroundColor: "rgb(43, 12, 99)",
            borderRadius: "pink",
            display: "flex",
            position: "relative",
            padding: "0px",
            margin: "0px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",

            flexGrow: 1,
            flexShrink: 0,
          },
          index: "1",
          backgroundImage: {
            imgPathUrl: "",
          },
        },
      },
    },
  },
};

export function generateRandomId(length = 15, myExistingIds = []) {
  let existingIds = [...myExistingIds];
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let id = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }

  if (existingIds.includes(id)) {
    return generateRandomId(length, existingIds);
  }
  return id;
}

import { separateNumberFromUnit } from "./separateNumberFromUnit";

export function convertAbsolutePositionToPercentage(
  parentWidth,
  parentHeight,
  childLeft,
  childTop
) {
  // Calculate percentages
  let leftPercentage = (childLeft / parentWidth) * 100;
  let topPercentage = (childTop / parentHeight) * 100;

  const leftPercentageString = leftPercentage.toString();
  const topPercentageString = topPercentage.toString();

  if (leftPercentageString > 6) {
    leftPercentage = leftPercentageString.slice(0, 6);
  }
  if (topPercentageString > 6) {
    topPercentage = topPercentageString.slice(0, 6);
  }

  // Return an object with the calculated percentages
  return {
    left: leftPercentage,
    top: topPercentage,
  };
}

function convertAbsolutePositionToPercentageForDeployment(
  parentWidth,
  parentHeight,
  childLeft,
  childTop
) {
  if (childLeft > 1 && childTop > 1) {
    // Calculate percentages
    let leftPercentage = (childLeft / parentWidth) * 100;
    let topPercentage = (childTop / parentHeight) * 100;

    const leftPercentageString = leftPercentage.toString();
    const topPercentageString = topPercentage.toString();

    if (leftPercentageString > 6) {
      leftPercentage = leftPercentageString.slice(0, 6);
    }
    if (topPercentageString > 6) {
      topPercentage = topPercentageString.slice(0, 6);
    }

    // Return an object with the calculated percentages
    return {
      left: `${leftPercentage}%`,
      top: `${topPercentage}%`,
    };
  } else if (childLeft > 1) {
    let leftPercentage = (childLeft / parentWidth) * 100;

    const leftPercentageString = leftPercentage.toString();

    if (leftPercentageString > 6) {
      leftPercentage = leftPercentageString.slice(0, 6);
    }

    // Return an object with the calculated percentages
    return {
      left: `${leftPercentage}%`,
      top: `${childTop}px`,
    };
  } else if (childTop > 1) {
    let topPercentage = (childTop / parentHeight) * 100;

    const topPercentageString = topPercentage.toString();

    if (topPercentageString > 6) {
      topPercentage = topPercentageString.slice(0, 6);
    }
    return {
      left: `${childLeft}px`,
      top: `${topPercentage}%`,
    };
  } else {
    // return unchanged object
    return {
      left: `${childLeft}px`,
      top: `${childTop}px`,
    };
  }
}

export function convertFilePositioningToPercentage(
  fileComponentsArray,
  parentWidth,
  parentHeight,
  stylesKey
) {
  if (fileComponentsArray === undefined || fileComponentsArray?.length === 0) {
    return [];
  } else if (fileComponentsArray) {
    const newComponentsArray = fileComponentsArray.map((currentComponent) => {
      if (
        currentComponent.type === "section" &&
        currentComponent?.nestedComponents
      ) {
        let modifiedComponentsArray = convertFilePositioningToPercentage(
          currentComponent?.nestedComponents,
          960,
          separateNumberFromUnit(currentComponent?.styles?.height)[0],
          "styles"
        );

        modifiedComponentsArray = convertFilePositioningToPercentage(
          modifiedComponentsArray,
          360,
          separateNumberFromUnit(
            currentComponent?.mobileStyles?.height ||
              currentComponent?.styles?.height
          )[0],
          "mobileStyles"
        );

        return {
          ...currentComponent,
          nestedComponents: modifiedComponentsArray,
        };
      }

      if (
        parentWidth !== undefined &&
        currentComponent[stylesKey] &&
        currentComponent[stylesKey].left
      ) {
        const newPosition = convertAbsolutePositionToPercentageForDeployment(
          parentWidth,
          parentHeight,
          separateNumberFromUnit(currentComponent[stylesKey].left)[0],
          separateNumberFromUnit(currentComponent[stylesKey].top)[0]
        );

        return {
          ...currentComponent,
          [stylesKey]: {
            ...currentComponent[stylesKey],
            top: newPosition.top,
            left: newPosition.left,
          },
        };
      } else {
        return currentComponent;
      }
    });

    return newComponentsArray;
  }
}

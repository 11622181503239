import { arrayToObject } from "../../../../../helperFunctions/arrayToObject";
import { cloneAndRename } from "../../../../../helperFunctions/cloneAndRename";
import { deleteObjectAtPath } from "../../../../../helperFunctions/deleteObjectAtPath";
import { getValueAtPath } from "../../../../../helperFunctions/getValueAtPath";

import {
  objectToArray,
  oneLayerObjectToArray,
} from "../../../../../helperFunctions/objectToArray";
import { generateRandomId } from "../../../../../helperFunctions/randomIdGenerator";
import { returnNumberOfSiblingAtPath } from "../../../../../helperFunctions/returnNumberOfSiblingsAtPath";
import { separateNumberFromUnit } from "../../../../../helperFunctions/separateNumberFromUnit";
import { addNewObjectAtPath } from "../../../../../helperFunctions/updateObjectAtPath";

export function returnPcStripBarButtons(
  setSelectedComponent,
  selectedComponent,
  setSelectedComponentId,
  setSelectedComponentPath,
  setFileComponentsObject,
  selectedComponentPath,
  fileComponentsObject,
  editorClipBoard,
  setEditorClipBoard
) {
  return [
    {
      inputValue: selectedComponent?.styles?.backgroundColor,
      title: "Background Color",
      type: "color",
      category: "Background",
      onChange: async (v) => {
        try {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              backgroundColor: v,
            },
          });
        } catch (error) {
          console.log(error);
        }
      },
    },
    {
      inputValue: selectedComponent.styles.backgroundColor ? true : false,
      title: "Background Visiblility",
      type: "checkbox",
      category: "Background",
      onChange: async (v) => {
        if (v) {
          try {
            setSelectedComponent({
              ...selectedComponent,
              styles: {
                ...selectedComponent.styles,
                backgroundColor: "rgb(255,255,255)",
              },
            });
          } catch (error) {
            console.log(error);
          }
        } else {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "backgroundColor"])
          );
        }
      },
    },

    {
      inputValue: selectedComponent?.styles?.height,
      title: "Height",
      type: "number",
      category: "Size",
      units: ["px", "%"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "height"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            // changing the height of the column's top layer
            styles: {
              ...selectedComponent.styles,
              height: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.borderRadius,
      title: "Border Raduis",
      category: "Border",
      type: "number",
      units: ["px", "%"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "borderRadius"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              borderRadius: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.borderStyle,
      title: "Border Style",
      category: "Border",
      type: "select",
      options: ["solid", "dashed", "dotted", "double"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "borderStyle"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              borderStyle: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.borderWidth,
      title: "Border Width",
      category: "Border",
      type: "number",
      units: ["px"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "borderWidth"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              borderWidth: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.borderColor,
      title: "Border Color",
      category: "Border",
      type: "color",

      onChange: (v) => {
        setSelectedComponent({
          ...selectedComponent,
          styles: {
            ...selectedComponent.styles,
            borderColor: v,
          },
        });
      },
    },
    {
      inputValue: selectedComponent?.styles?.visibility,
      title: "Visibility",
      type: "select",
      options: ["visible", "hidden", "collapse"],
      category: "Flex Styles",
      onChange: async (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "visibility"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              visibility: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.flexDirection,
      title: "Flex Direction",
      type: "select",
      options: ["row", "column", "row-reverse", "column-reverse"],
      category: "Flex Styles",
      onChange: async (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "flexDirection"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              flexDirection: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.justifyContent,
      title: "Justify Content",
      type: "select",
      options: [
        "flex-start",
        "flex-end",
        "center",
        "space-between",
        "space-around",
      ],
      category: "Flex Styles",
      onChange: async (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "justifyContent"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              justifyContent: v,
            },
          });
        }
      },
    },

    {
      inputValue: selectedComponent?.styles?.rowGap,
      title: "Row Gap",
      type: "number",
      category: "Flex Styles",
      units: ["%", "px"],
      includeAuto: true,
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "rowGap"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              rowGap: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.columnGap,
      title: "Column Gap",
      type: "number",
      category: "Flex Styles",
      units: ["%", "px"],
      includeAuto: true,
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "columnGap"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              columnGap: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.zIndex,
      title: "Z-Index",
      type: "number",
      category: "Flex Styles",

      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "zIndex"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              zIndex: v,
            },
          });
        }
      },
    },

    {
      value: "Clone",
      type: "plain",
      onClick: () => {
        const pathToParent = [...selectedComponentPath].slice(0, -2);
        const selectedComponent = getValueAtPath(
          fileComponentsObject,
          selectedComponentPath
        );
        const cloneName = `${selectedComponent.name}clone${generateRandomId(
          2,
          []
        )}`;

        let newComponent = {
          ...getValueAtPath(fileComponentsObject, selectedComponentPath),
          name: cloneName,
          styles: {
            ...selectedComponent?.styles,
            top:
              separateNumberFromUnit(selectedComponent?.styles?.top)[0] +
              20 +
              "px",
          },
        };

        delete newComponent?.mobileStyles?.top;
        delete newComponent?.mobileStyles?.left;

        setFileComponentsObject(
          addNewObjectAtPath(
            fileComponentsObject,
            [...pathToParent, "nestedComponents", cloneName],
            newComponent
          )
        );
        setSelectedComponent(newComponent);
        setSelectedComponentPath([
          ...pathToParent,
          "nestedComponents",
          cloneName,
        ]);
        setSelectedComponentId(cloneName);
      },
    },
    {
      type: "plain",
      value: "copy",
      onClick: () => {
        setEditorClipBoard({
          ...cloneAndRename(selectedComponent),
          name: selectedComponent.name + "Clone" + generateRandomId(3, []),
        });
      },
    },
    {
      type: "plain",
      value: "delete",
      onClick: () => {
        let currentObj = deleteObjectAtPath(fileComponentsObject, [
          ...selectedComponentPath,
        ]);

        setFileComponentsObject(currentObj);
        setSelectedComponent({});
        setSelectedComponentId("");
        setSelectedComponentPath([]);
      },
    },

    {
      type: "plain",
      title: "add Element",
      value: "Add Column",
      onClick: () => {
        const numberOfColumns =
          selectedComponent?.script?.variables?.numberOfColumns || 0;

        const newColumnName = `${
          selectedComponent.name
        }PlainStripColumn${generateRandomId(4, [])}`;

        /*  setNewElementForm({ ...newElementForm, active: true }); */
        const newSelectedComponent = addNewObjectAtPath(
          selectedComponent,
          ["nestedComponents", newColumnName],
          {
            active: true,
            name: newColumnName,
            type: "relativeContainer",
            styles: {
              height: "100%",

              backgroundColor: "blue",
              position: "relative",
              padding: "0px",
              margin: "0px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              flexGrow: 1,
              flexShrink: 0,
              minWidth: "10px",
            },
            index: numberOfColumns + 1,
            backgroundImage: {
              imgPathUrl: "",
            },
          }
        );
        setSelectedComponent({
          ...newSelectedComponent,
          script: {
            ...newSelectedComponent.script,
            variables: {
              ...newSelectedComponent.script.variables,
              numberOfColumns: numberOfColumns + 1,
            },
          },
        });
      },
    },
  ];
}

import { useState, useEffect, useRef } from "react";
import { StaticRecursiveComponentRenderer } from "./staticRecursiveComponentRenderer";
import {
  componentsObjects,
  returnComponentData,
} from "../../data/componentsObjects/componentsObjects";
import { SideBar } from "../../../../reausable Components/sideBar";
import {
  getAllImagesUrlsFromStorage,
  getImageUrlFromStorage,
  uploadFileToStorage,
} from "../../../../firebase-config";

const ImageViewer = ({
  image,
  setSelectedImage,
  selectedImage,
  setSelectedComponentName,
  setSelectedImageUrl,
}) => {
  const [hovering, setHovering] = useState();
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    async function getImageUrl() {
      const url = await getImageUrlFromStorage(image?.fullPath);
      setImageUrl(url);
    }

    getImageUrl();
  }, []);
  return (
    <img
      onClick={() => {
        setSelectedImage(image);
        setSelectedComponentName("img");
        setSelectedImageUrl(imageUrl);
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      src={imageUrl}
      alt=""
      style={{
        width: "100%",
        borderRadius: hovering ? "25px" : "5px",
        border:
          selectedImage?.name === image?.name
            ? "solid blue 2px"
            : hovering
            ? "solid steelblue 2px"
            : "solid lightblue 2px",
        padding: "5px",
        boxSizing: "border-box",
      }}
    />
  );
};
const NewComponent = ({
  component,
  selectedComponentName,
  setSelectedComponentName,
  setSelectedComponent,
  MySelectedComponent,
}) => {
  const [hovering, setHovering] = useState(false);
  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={() => {
        setSelectedComponentName(component.name);
        setSelectedComponent(component);
      }}
      style={{
        borderRadius: hovering ? "25px" : "5px",
        width: "90%",
        alignSelf: "center",
        border:
          MySelectedComponent === component
            ? "solid blue 2px"
            : hovering
            ? "solid steelblue 2px"
            : "solid lightblue 2px",
        boxSizing: "border-box",
        position: "relative",
        display: "flex",
        padding: "10px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <StaticRecursiveComponentRenderer components={[component]} />
    </div>
  );
};

export const NewComponentSelector = ({
  onChooseComponent,
  setExistingComponentIds,
  existingComponentIds = [],
  selectedComponent,
  currentSchool,
  templateId,
  editorMode,
}) => {
  const newFileInputRef = useRef(null);
  const [selectedComponentName, setSelectedComponentName] = useState({});
  const [MySelectedComponent, setMySelectedComponent] = useState({});

  const [selectedCategory, setSelectedCategory] = useState("text");

  const [allImages, setAllImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState({});
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  useEffect(() => {
    async function getImages() {
      let imageFiles = await getAllImagesUrlsFromStorage(
        `schools/De7vzAzZgiSYm8W2VPwljGoxb8h2/savedWebsiteTemplates/hellowrld/images`
      );

      setAllImages([...imageFiles]);
    }
    getImages();
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(255,255,255,0.6)",
        zIndex: 10000000,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "110px",
      }}
    >
      <div
        style={{
          width: "70%",
          backgroundColor: "white",
          padding: "2px",
          border: "steelblue solid 2px",
          borderRadius: "12px",
          height: "650px",
          position: "relative",
        }}
      >
        <h2
          style={{
            width: "100%",
            borderBottom: "solid 1.8px #333",
            textAlign: "center",
            fontSize: "28px",
            color: "blue",
          }}
        >
          Choose A Component
        </h2>
        <div
          style={{
            height: "80%",
            overflow: "auto",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            rowGap: "8px",
          }}
        >
          <SideBar
            side={"left"}
            buttons={[
              {
                value: "Text",
                type: "plain",
                onClick: () => {
                  setSelectedCategory("Text");
                  setSelectedImage({});
                },
              },
              {
                value: "Button",
                type: "plain",
                onClick: () => {
                  setSelectedCategory("Button");
                  setSelectedImage({});
                },
              },
              {
                value: "Input",
                type: "plain",
                onClick: () => {
                  setSelectedCategory("Input");
                  setSelectedImage({});
                },
              },
              {
                value: "Form",
                type: "plain",
                onClick: () => {
                  setSelectedCategory("Form");
                  setSelectedImage({});
                },
              },
              {
                value: "Slide show",
                type: "plain",
                onClick: () => {
                  setSelectedCategory("Slide Show");
                  setSelectedImage({});
                },
              },
              {
                value: "Strip",
                type: "plain",
                onClick: () => {
                  setSelectedCategory("Strip");
                  setSelectedImage({});
                },
              },
              {
                value: "Image",
                type: "plain",
                onClick: () => {
                  setSelectedCategory("Image");
                  setSelectedImage({});
                },
              },
              {
                value: "Menu",
                type: "plain",
                onClick: () => {
                  setSelectedCategory("Menu");
                  setSelectedImage({});
                },
              },
              {
                value: "Icon",
                type: "plain",
                onClick: () => {
                  setSelectedCategory("Icon");
                  setSelectedImage({});
                },
              },
            ]}
          />
          <div
            style={{
              height: "100%",
              overflow: "auto",
              width: "100%",
              display: selectedCategory !== "Image" ? "flex" : "grid",
              flexDirection: "column",
              gridTemplateColumns: "repeat(4, auto)",
              gridTemplateRows: "repeat(auto-fit, auto)",
              rowGap: "8px",
            }}
          >
            {selectedCategory !== "Image" &&
              componentsObjects.map((component) => {
                if (
                  component?.validParents?.includes(selectedComponent?.type) &&
                  component?.category === selectedCategory
                ) {
                  return (
                    <NewComponent
                      MySelectedComponent={MySelectedComponent}
                      setSelectedComponent={setMySelectedComponent}
                      setSelectedComponentName={setSelectedComponentName}
                      selectedComponentName={selectedComponentName}
                      component={component}
                    />
                  );
                }
              })}
            {selectedCategory === "Image" && (
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <button
                  style={{ zIndex: 1 }}
                  onClick={() => newFileInputRef.current.click()}
                  className="button"
                >
                  Upload
                </button>
                <input
                  style={{
                    position: "absolute",
                    visibility: "hidden",
                    zIndex: 0,
                  }}
                  ref={newFileInputRef}
                  type="file"
                  onChange={async (v) => {
                    try {
                      const response = await uploadFileToStorage(
                        `schools/${currentSchool.schoolId}/savedWebsiteTemplates/${templateId}/images/${v?.target?.files[0].name}`,
                        v?.target?.files[0]
                      );

                      let newComponent = returnComponentData(
                        "image",
                        existingComponentIds,
                        setExistingComponentIds,
                        currentSchool,
                        "Image"
                      );

                      if (editorMode === "pc") {
                        onChooseComponent({
                          ...newComponent,
                          imgPathUrl: await getImageUrlFromStorage(
                            response.ref.fullPath
                          ),
                        });
                      } else {
                        onChooseComponent({
                          ...newComponent,
                          imgPathUrl: await getImageUrlFromStorage(
                            response.ref.fullPath
                          ),
                          styles: {
                            ...newComponent.styles,
                            visibility: "collapse",
                          },
                          mobileStyles: {
                            ...newComponent.mobileStyles,
                            visibility: "visible",
                          },
                        });
                      }
                    } catch (error) {
                      console.log("Failed to upload: " + error);
                    }
                  }}
                />
              </div>
            )}
            {selectedCategory === "Image" &&
              allImages.map((image) => {
                return (
                  <ImageViewer
                    setSelectedImageUrl={setSelectedImageUrl}
                    setSelectedComponentName={setSelectedComponentName}
                    image={image}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                  />
                );
              })}
          </div>
        </div>
        <button
          style={{ position: "absolute", bottom: "5px", left: "8px" }}
          onClick={() => {
            let newComponent = returnComponentData(
              selectedComponentName,
              existingComponentIds,
              setExistingComponentIds,
              currentSchool,
              selectedImage?.fullPath !== undefined ? "Image" : ""
            );
            if (editorMode === "pc") {
              if (newComponent.type === "icon") {
                onChooseComponent({
                  ...newComponent,
                  imgPathUrl: selectedImageUrl,
                  icon: MySelectedComponent?.icon,
                });
              } else {
                onChooseComponent({
                  ...newComponent,
                  imgPathUrl: selectedImageUrl,
                });
              }
            } else {
              onChooseComponent({
                ...newComponent,
                imgPathUrl: selectedImageUrl,
                styles: {
                  ...newComponent?.styles,
                  visibility: "collapse",
                },
                mobileStyles: {
                  ...newComponent?.mobileStyles,
                  visibility: "visible",
                },
              });
            }
          }}
          className="button"
        >
          Choose
        </button>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { List } from "../../../reausable Components/list";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase-config";

export const SchoolsMananger = ({ setPageState, setSelectedSchool }) => {
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "schools"), (querySnap) => {
      let tempSchools = [];
      querySnap.forEach((school) => {
        tempSchools.push(school.data());
      });
      setSchools(tempSchools);
    });
    return () => {
      unsub();
    };
  }, []);
  return (
    <div style={{ paddingTop: "100px" }}>
      <h1 style={{ textAlign: "center" }}>SupperAdminConsole</h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <List
          items={schools.map((school) => {
            return {
              value: school?.schoolName,
              onClick: () => {
                setSelectedSchool(school);
                setPageState("schoolMananger");
              },
            };
          })}
        />
      </div>
    </div>
  );
};

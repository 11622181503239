export function returnNumberOfChildrenAtPath(obj, path = []) {
  if (!obj || !path || !Array.isArray(path)) {
    return null;
  }
  let numberOfSiblings = 0;
  let currentObj = { ...obj };

  let pathToParent = [...path];

  for (let key of pathToParent) {
    if (currentObj) {
      if (Array.isArray(currentObj) && !Number.isNaN(Number(key))) {
        // If the current object is an array and the key is a valid index
        key = Number(key);
        if (key < 0 || key >= currentObj.length) {
          return null; // Index out of bounds
        }
      } else if (currentObj.hasOwnProperty(key)) {
        currentObj = currentObj[key];
        continue;
      }
    }

    // Return undefined if the path is not valid
    return "invalid Path";
  }

  for (const childKey in currentObj) {
    if (currentObj.hasOwnProperty(childKey)) {
      numberOfSiblings += 1;
      continue;
    }
  }

  return numberOfSiblings;
}

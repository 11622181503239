export function getNewIndexForObjectAtPath(obj, path) {
  // Creating copy of object to avoid mutation
  let current = { ...obj };

  // Traverse the object using the path

  for (const key of path) {
    if (!current[key] || typeof current[key] !== "object") {
      // If the path doesn't exist or is not an object, return 0
      return 0;
    }
    current = current[key];
  }

  // Find the highest index within nested components
  let highestIndex = -1;
  for (const component of Object.values(current)) {
    if (typeof component === "object" && component.hasOwnProperty("index")) {
      const index = parseInt(component.index);
      if (!isNaN(index) && index > highestIndex) {
        highestIndex = index;
      }
    }
  }

  // Return the highest index + 1
  return highestIndex + 1;
}

export function returnParagraph(name) {
  return {
    index: "5",
    value: "This is a paragraph. And so all paragraph text goes here",
    active: true,
    name: name,
    type: "text",
    mobileStyles: {},
    styles: {
      left: "10px",
      position: "absolute",
      top: "10px",
      color: "#000",
      padding: "0px",
      margin: "0px",
    },
  };
}

export const paragraph = {
  category: "Text",
  index: "5",
  value: "This is a paragraph. And so all paragraph text goes here",
  active: true,
  name: "paragraph",
  type: "text",
  validParents: ["form", "section", "box", "container", "relativeContainer"],
  styles: {
    position: "relative",
    color: "#000",
    /* margin: "auto", */
  },
};

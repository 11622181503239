import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareCheck,
  faSquareXmark,
} from "@fortawesome/free-solid-svg-icons";

export const NotificationBar = ({ notify, setNotify }) => {
  const countDown = notify.timeOut ? notify.timeOut : 4000;
  useEffect(() => {
    let timeOutId;
    if (notify.type === "notification") {
      timeOutId = setTimeout(() => {
        setNotify({ ...notify, on: false });
      }, countDown);
    }
    return () => {
      clearTimeout(timeOutId);
    };
  }, []);
  return (
    notify.on && (
      <div
        onClick={() => {
          setNotify({ ...notify, on: false });
        }}
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          width: "100%",
          backgroundColor: "rgba(255,255,255, 57%)",
          zIndex: 50,
        }}
      >
        <div
          style={{
            padding: "5px",

            backgroundColor: "white",
            borderRadius: 7,
            display: "flex",

            maxWidth: "40%",
            border: `2px solid ${
              notify?.type === "notification" ? "lightgreen" : "red"
            }`,
            position: "absolute",
            top: "12px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {notify?.type === "notification" && (
            <FontAwesomeIcon
              color="lightgreen"
              style={{}}
              fontSize={"25px"}
              icon={faSquareCheck}
            />
          )}

          {notify?.type === "error" && (
            <FontAwesomeIcon
              color="red"
              style={{}}
              fontSize={"25px"}
              icon={faSquareXmark}
            />
          )}

          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4 style={{ padding: 0, margin: 0 }}>{notify?.notification}</h4>
            {notify?.solution && (
              <p style={{ padding: 0, margin: 0 }}>{notify.solution}</p>
            )}
          </div>
        </div>
      </div>
    )
  );
};

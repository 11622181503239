import { useEffect } from "react";
import { oneLayerObjectToArray } from "../../../../helperFunctions/objectToArray";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import * as Icons from "@fortawesome/free-solid-svg-icons";

export const StaticRecursiveComponentRenderer = ({ components }) => {
  return (
    <>
      {components?.map((myCurrentComponent) => {
        let currentComponent = { ...myCurrentComponent };
        let componetsEventStringsArray = [];

        // Making sure I have an array of the names of all used events
        for (let eventKey in currentComponent?.events) {
          componetsEventStringsArray.push(eventKey);
        }

        // Rendering The Dom Elements
        if (
          currentComponent.type === "text" ||
          currentComponent.type === "Link Text"
        ) {
          return (
            <p key={currentComponent.name} style={currentComponent.styles}>
              {currentComponent.value}
            </p>
          );
        } else if (
          currentComponent.type === "heading" ||
          currentComponent.type === "heading2" ||
          currentComponent.type === "heading3" ||
          currentComponent.type === "heading4"
        ) {
          return (
            <h1 key={currentComponent.name} style={currentComponent.styles}>
              {currentComponent.value}
            </h1>
          );
        } else if (currentComponent.type === "menu") {
          return <FontAwesomeIcon size="2x" icon={faBars} />;
        } else if (currentComponent.type === "icon") {
          return (
            <FontAwesomeIcon size="2x" icon={Icons[currentComponent.icon]} />
          );
        } else if (currentComponent.type === "button") {
          return (
            <button
              type={currentComponent.buttonType}
              style={currentComponent.styles}
            >
              {currentComponent.value}
            </button>
          );
        } else if (currentComponent.type === "input") {
          return (
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: 2,
                }}
              ></div>
              <input
                style={{ ...currentComponent.styles }}
                placeholder={currentComponent?.placeHolder}
                type={currentComponent?.inputType}
              />
            </div>
          );
        } else if (currentComponent.type === "textArea") {
          return (
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: 2,
                }}
              ></div>
              <textarea
                style={{ ...currentComponent.styles }}
                placeholder={currentComponent?.placeHolder}
                type={currentComponent?.inputType}
              />
            </div>
          );
        } else if (currentComponent.type === "image") {
          return (
            <img
              style={currentComponent.styles}
              key={currentComponent.name}
              src={currentComponent.imgPathUrl}
              alt=""
            />
          );
        } else if (
          currentComponent.type === "container" ||
          currentComponent.type === "relativeContainer" ||
          currentComponent.type === "box" ||
          currentComponent.type === "form" ||
          currentComponent.type === "strip" ||
          currentComponent.type === "slide"
        ) {
          return (
            <div key={currentComponent.name} style={currentComponent.styles}>
              <StaticRecursiveComponentRenderer
                components={oneLayerObjectToArray(
                  currentComponent.nestedComponents
                )}
                key={currentComponent.name + "-nested"}
              />
            </div>
          );
        } else if (currentComponent.type === "section") {
          return (
            <section
              key={currentComponent.name}
              style={currentComponent.styles}
            >
              <StaticRecursiveComponentRenderer
                components={oneLayerObjectToArray(
                  currentComponent.nestedComponents
                )}
                key={currentComponent.name + "-nested"}
              />
            </section>
          );
        }
      })}
    </>
  );
};

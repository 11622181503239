import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase-config";

import logo from "../assets/myLogo.png";
import { MyLoader } from "../reausable Components/myLoader";
import { Link } from "react-router-dom";
import { NotificationBar } from "../reausable Components/myNotificationBar";

export const RequestAccountRemoval = () => {
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    on: false,
    notification: "",
    type: "",
  });
  const [messageInfo, setMessageInfo] = useState({
    accountHolderEmail: "",
    reason: "",
    accountHolderName: "",
    accountHolderPassowrd: "",
    childName: "",
    schoolEmail: "",
  });

  async function handleMessageSend() {
    const messageRef = collection(db, "requestRemoval");
    try {
      setLoading(true);
      await addDoc(messageRef, messageInfo);
      setLoading(false);
      setNotify({
        on: true,
        type: "notification",
        notification: `Please note, empEd is not responible for account creations or deletions of individual parents or teachers.
          However your request for your account to be deleted will be sent to the main account administrator (school leader) as they
          are the only ones with the ability to delete or create individual accounts.`,
        timeOut: 10000,
      });
      setMessageInfo({
        accountHolderEmail: "",
        reason: "",
        accountHolderName: "",
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  return (
    <section className="formsContainer console">
      {loading && <MyLoader />}
      {notify && <NotificationBar notify={notify} setNotify={setNotify} />}
      <div className="consoleHeader">
        <div className="backButton"></div>
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      <h1 style={{ marginTop: 0 }}>Request Account Removal</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleMessageSend();
        }}
        className="form largeForm"
      >
        <div>
          <label htmlFor="name">Account Holder Name: </label>
          <input
            name="name"
            onChange={(e) => {
              setMessageInfo({
                ...messageInfo,
                accountHolderName: e.target.value,
              });
            }}
            value={messageInfo.accountHolderName}
            required
            type="text"
            placeholder="Your name goes here"
            className="mediumFormInput"
          />
        </div>

        <div>
          <label>Account Holder Email: </label>
          <input
            onChange={(e) => {
              setMessageInfo({
                ...messageInfo,
                accountHolderEmail: e.target.value,
              });
            }}
            value={messageInfo.accountHolderEmail}
            required
            type="email"
            placeholder="Your email goes here"
            className="mediumFormInput"
          />
        </div>
        <div>
          <label>Account Holder Password: </label>
          <input
            onChange={(e) => {
              setMessageInfo({
                ...messageInfo,
                accountHolderPassowrd: e.target.value,
              });
            }}
            value={messageInfo.accountHolderPassowrd}
            required
            type="password"
            placeholder="Your password goes here"
            className="mediumFormInput"
          />
        </div>
        <div>
          <label htmlFor="name">Child Name: </label>
          <input
            name="name"
            onChange={(e) => {
              setMessageInfo({
                ...messageInfo,
                childName: e.target.value,
              });
            }}
            value={messageInfo.childName}
            required
            type="text"
            placeholder="name goes here"
            className="mediumFormInput"
          />
        </div>
        <div>
          <label>SchoolEmail: </label>
          <input
            onChange={(e) => {
              setMessageInfo({
                ...messageInfo,
                schoolEmail: e.target.value,
              });
            }}
            value={messageInfo.schoolEmail}
            required
            type="email"
            placeholder="School email goes here"
            className="mediumFormInput"
          />
        </div>

        <div>
          <label>Reason For Removal: </label>
          <textarea
            onChange={(e) => {
              setMessageInfo({ ...messageInfo, reason: e.target.value });
            }}
            value={messageInfo.reason}
            required
            placeholder="Your message goes here"
          />
        </div>
        <button type="submit" className="button">
          Send
        </button>
      </form>
    </section>
  );
};

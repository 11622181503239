import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "../css/about.css";
import logo from "../assets/myLogo.png";
import { Link } from "react-router-dom";

export const FreeAccountTsAndCs = ({ setAppState }) => {
  return (
    <div className="formsContainer">
      <div className="consoleHeader">
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      <h1>Terms And Conditions</h1>
      <div className="paragraphContainer">
        <h4>Effective Date: 13 September 2023</h4>
        <h3>1. Introduction</h3>
        <p>
          Welcome to EmpEd ("the App"), provided by ml-zaria ("the Company"). By
          accessing and using this App, you agree to comply with and be bound by
          the following Terms and Conditions ("T&C"). If you do not agree to
          these T&C, please refrain from using the App.
        </p>
        <h3>2. Early Access Perks for Schools</h3>
        <p>
          ml-zaria is offering schools that sign up for the EmpEd App before 28
          September 2023, free access to all current and future services
          provided by EmpEd.
        </p>
        <p>
          This free access offer includes all services available on the App,
          both current and any future services introduced by the Company.
          Schools that take advantage of this offer will continue to enjoy free
          access to these services for the duration of their usage of the App.
        </p>
        <p>
          To maintain eligibility for this free access offer, schools must
          ensure that at least all teachers and a minimum of 5% of parents have
          the App installed and log in at least once within 40 days after
          sign-up.
        </p>
        <h3>3. Advertisements</h3>
        <p>
          The App may display advertisements to parents and students. These
          advertisements are an integral part of our free access offering and
          help support the sustainability of the App.
        </p>

        <h3>4. Premium Services</h3>
        <p>
          In the future, the Company plans to introduce premium services,
          including AI tools utilizing GPT-3.5-4 API. These premium services may
          be subject to separate fees and conditions.
        </p>
        <h3>5. Premium Subscription</h3>
        <p>
          Users of the App, including parents, students, and teachers, will have
          the option to subscribe to a premium plan for an enhanced experience.
          Premium plans may include benefits such as ad removal and access to
          premium services.
        </p>
        <h3>6. Ad Removal Option</h3>
        <p>
          Users who sign up for the App before 28 September 2023 will have the
          opportunity to remove ads from their experience at a reduced cost
          compared to future users who opt for ad removal.
        </p>
        <h3>7. Termination</h3>
        <p>
          The Company reserves the right to terminate or suspend access to the
          App or any part of it, with or without notice, for any reason.
        </p>
        <h3>8. Changes to T&C</h3>
        <p>
          The Company may revise these T&C at any time without prior notice. By
          continuing to use the App, you agree to be bound by the updated T&C.
        </p>

        <h3>9. Contact Information</h3>
        <p>
          If you have any questions or concerns regarding these T&C, please
          <span>
            {" "}
            <Link to="/contactUs" className="textLink">
              contact us
            </Link>
          </span>
          .
        </p>
      </div>
    </div>
  );
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/myLogo.png";

export const ClassroomPicker = ({
  classrooms,
  handleClassRoomSelect,
  onBackPress,
  heading,
}) => {
  return (
    <div style={{ padding: "0" }} className="console">
      <div className="consoleHeader">
        <div className="backButton" onClick={onBackPress}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      <h3 style={{ textAlign: "center" }}>{heading}</h3>
      <div className="classRoomsContainer">
        <ul className="classRooms">
          {classrooms.map((classRoom) => (
            <li
              className="classRoom"
              key={classRoom.className}
              onClick={() => handleClassRoomSelect(classRoom.className)}
            >
              <div className="classRoomName">{classRoom?.className}</div>
              <div className="classRoomTeacher">{classRoom?.classTeacher}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

import "../css/console.css";
import { InfoBox } from "../reausable Components/infoBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/myLogo.png";

export const StudentDisplayer = ({ selectedStudent, onBackPress }) => {
  return (
    <div className="console">
      <div className="consoleHeader">
        <div className="backButton" onClick={onBackPress}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      <InfoBox
        fieldStyles={{ color: "blue" }}
        containerStyles={{ alignSelf: "center" }}
        fieldsAndValues={[
          { field: "Name", value: selectedStudent?.childName },
          { field: "Surname", value: selectedStudent?.surname },
          { field: "Class Room", value: selectedStudent.class },
          { field: "Parent Name", value: selectedStudent?.parentName },
          { field: "Parent Email", value: selectedStudent?.parentEmail },
        ]}
      />
    </div>
  );
};

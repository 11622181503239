import React, { useEffect, useRef, useState } from "react";
import { updateObjectAtPath } from "../../../../helperFunctions/updateObjectAtPath";
import { separateNumberFromUnit } from "../../../../helperFunctions/separateNumberFromUnit";

import { convertAbsolutePositionToPixels } from "../../../../helperFunctions/convertAbsolutePositionToPixel";
import { SlidePicker } from "../customComponents/slidePicker";
import { returnNumberOfChildrenAtPath } from "../../../../helperFunctions/returnNumberOfChildrenAtPath";

export const TemplateSlide = ({
  children,
  style,
  infoObject,
  setSelectedComponent,
  selectedComponentPath,
  fileComponentsObject,
  setFileComponentsObject,
  selectedComponent,
  id,
  selectedComponentId,
  setSelectedComponentId,
  setSelectedComponentPath,
  domWidth,
  domHeight,
  editorMode,
}) => {
  const currentComponentRef = useRef(null);
  const [initialMousePosition, setInitialMousePosition] = useState({
    y: 0,
    x: 0,
  });

  const [currentComponentPosition, setCurrentComponentPosition] = useState({});
  const [dragging, setDragging] = useState(false);
  const [componentStyles, setComponentStyles] = useState({});
  const [position, setPosition] = useState({
    top:
      (separateNumberFromUnit(infoObject?.styles?.top).at(1) === "px"
        ? separateNumberFromUnit(infoObject?.styles?.top).at(0)
        : convertAbsolutePositionToPixels(
            domWidth,
            domHeight,
            separateNumberFromUnit(infoObject?.styles?.left).at(0),
            separateNumberFromUnit(infoObject?.styles?.top).at(0)
          ).top) || 0,
    left:
      (separateNumberFromUnit(infoObject?.styles?.left).at(1) === "px"
        ? separateNumberFromUnit(infoObject?.styles?.left).at(0)
        : convertAbsolutePositionToPixels(
            domWidth,
            domHeight,
            separateNumberFromUnit(infoObject?.styles?.left).at(0),
            separateNumberFromUnit(infoObject?.styles?.top).at(0)
          ).left) || 0,
  });

  const [mobilePosition, setMobilePosition] = useState({});

  /* This Effect is responsible for assigning default values to tabletPosition and mobilePosition */
  useEffect(() => {
    if (infoObject?.mobileStyles?.left) {
      setMobilePosition({
        top: separateNumberFromUnit(infoObject?.mobileStyles?.top)[0],
        left: separateNumberFromUnit(infoObject?.mobileStyles?.left)[0],
      });
    }
  }, []);

  //This effect is updating offset styles for all editorModes
  useEffect(() => {
    function updataOffsetStyles(device) {
      setSelectedComponent((mySelectedComponent) => ({
        ...mySelectedComponent,
        [`offset${device}Styles`]: {
          width: currentComponentRef.current.offsetWidth,
          height: currentComponentRef.current.offsetHeight,
        },
      }));
    }

    if (editorMode === "pc") {
      updataOffsetStyles("pc");
    } else if (editorMode === "mobile") {
      updataOffsetStyles("mobile");
    }
  }, [
    editorMode,
    currentComponentRef,
    position,
    mobilePosition,
    selectedComponent.styles,
    selectedComponent.tabletStyles,
    selectedComponent.mobileStyles,
  ]);

  /* This effect is responsible for updateing the position of the selcted component
   when it is dragged regardless of the editor mode */
  useEffect(() => {
    if (editorMode === "pc") {
      if (!dragging && selectedComponentId === id) {
        setSelectedComponent((mySelectedComponent) => ({
          ...mySelectedComponent,
          styles: {
            ...mySelectedComponent.styles,
            top: position.top + "px",
            left: position.left + "px",
          },
        }));
      }
    } else if (editorMode === "mobile") {
      if (!dragging && selectedComponentId === id) {
        setSelectedComponent((mySelectedComponent) => ({
          ...mySelectedComponent,
          mobileStyles: {
            ...mySelectedComponent.mobileStyles,
            top:
              (mobilePosition?.top !== undefined
                ? mobilePosition.top
                : position.top) + "px",
            left:
              (mobilePosition?.left !== undefined
                ? mobilePosition.left
                : position.left) + "px",
          },
        }));
      }
    }
  }, [dragging, selectedComponentId, editorMode, position, id, mobilePosition]);

  /* This Effect is used to keep the currentComponentPosition statte variable up to date */
  useEffect(() => {
    if (editorMode === "pc") {
      setCurrentComponentPosition({ ...position });
    } else if (editorMode === "mobile") {
      setCurrentComponentPosition({
        ...position,
        ...mobilePosition,
      });
    }
  }, [editorMode, position, mobilePosition]);

  /* This Effect is used to make sure the componentStyles stateVariable is always up to date */
  useEffect(() => {
    if (editorMode === "pc") {
      setComponentStyles({ ...infoObject?.styles });
    } else if (editorMode === "mobile") {
      setComponentStyles({
        ...infoObject?.styles,
        ...infoObject?.mobileStyles,
      });
    }
  }, [editorMode, infoObject]);

  /*  */
  useEffect(() => {
    if (selectedComponentId === id) {
      const myNumberOfSlides = returnNumberOfChildrenAtPath(selectedComponent, [
        "nestedComponents",
        `${selectedComponent.name}-slidesContainer`,
        "nestedComponents",
      ]);
      setSelectedComponent({
        ...selectedComponent,
        script: {
          ...selectedComponent?.script,
          variables: {
            ...selectedComponent?.script?.variables,
            numberOfSlides: myNumberOfSlides,
          },
        },
      });
      console.log("numberOfSlides: " + myNumberOfSlides);
    }
  }, [selectedComponent.nestedComponents]);

  useEffect(() => {
    if (id === selectedComponentId) {
      setFileComponentsObject((myFileComponentsObject) =>
        updateObjectAtPath(
          myFileComponentsObject,
          selectedComponentPath,
          selectedComponent
        )
      );
    }
  }, [selectedComponent]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setInitialMousePosition({ x: e.pageX, y: e.pageY });
    setDragging(true);
  };

  const handleMouseUp = () => {
    if (dragging) {
      setDragging(false);
    }
  };

  return (
    <div
      ref={currentComponentRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={() => {
        if (selectedComponentId === id) {
          setDragging(false);
        }
      }}
      onMouseMove={(e) => {
        e.stopPropagation();
        if (dragging && selectedComponentId === id) {
          console.log("pageX: " + e.pageX);
          console.log("pageY: " + e.pageY);
          const deltaX = e.pageX - initialMousePosition.x;
          const deltaY = e.pageY - initialMousePosition.y;

          if (editorMode === "pc") {
            setPosition({
              top: position.top + deltaY,
              left: position.left + deltaX,
            });
          } else if (editorMode === "mobile") {
            if (
              mobilePosition?.left === undefined &&
              mobilePosition?.top === undefined
            ) {
              setMobilePosition({
                top: position.top + deltaY,
                left: position.left + deltaX,
              });
            } else {
              setMobilePosition({
                top: mobilePosition.top + deltaY,
                left: mobilePosition.left + deltaX,
              });
            }
          }
          setInitialMousePosition({ x: e.pageX, y: e.pageY });
        }
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (id !== selectedComponentId) {
          setSelectedComponent(infoObject);
          setSelectedComponentId(infoObject?.name);
          setSelectedComponentPath(selectedComponentPath);
        }
      }}
      style={{
        position: "absolute",
        cursor: dragging ? "grabbing" : "pointer",
        padding: 0,
        margin: 0,
        userSelect: "none", // Prevent text selection
        backgroundImage: `url(${infoObject?.backgroundImage?.imgPathUrl})`,
        ...componentStyles,
        ...style,
        boxShadow:
          id === selectedComponentId
            ? "inset 0 0 10px 5px rgba(255, 119, 0, 0.9)"
            : infoObject?.styles?.boxShadow,
        top: currentComponentPosition.top + "px",
        left: currentComponentPosition.left + "px",
      }}
    >
      {children}
      {selectedComponentId === id && (
        <div // this inner div is used to drag the div if it's obstructed by a "relativeContainer" which cant be dragged
          style={{
            position: "absolute",
            backgroundColor: "red",
            width: "20px",
            height: "35px",
            left: "50%",
            top: "0px",
            transform: "translateX(-50%)",
            borderRadius: "0 0 10px 10px",
          }}
        ></div>
      )}
      {infoObject.component === "slide" && selectedComponentId === id && (
        <SlidePicker
          editorMode={editorMode}
          setSelectedComponent={setSelectedComponent}
          infoObject={infoObject}
        />
      )}
    </div>
  );
};

import {
  collection,
  getDocs,
  where,
  query,
  setDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase-config";

export async function getStudents(currentStudent, setStudents, currentSchool) {
  const studentsRef = query(
    collection(db, "schools", currentSchool.schoolId, "users"),
    where("class", "==", currentStudent.class)
  );
  const response = await getDocs(studentsRef);
  let newArr = [];
  response.forEach((student) => {
    newArr.push(student.data());
  });
  newArr = newArr.filter((ele) => {
    return ele.accountType !== "teacher";
  });
  setStudents(newArr);
}

export async function getClassRooms(ref, setClassRooms) {
  let newArr = [];
  const response = await getDocs(ref);
  response.forEach((classRoom) => {
    newArr.push(classRoom.data());
  });
  setClassRooms(newArr);
}

import "../css/reuserbleCoponents/infoBox.css";

export const InfoBox = ({
  fieldsAndValues,
  containerStyles,
  fieldStyles,
  valueStyles,
}) => {
  return (
    <div className="infoBox" style={{ ...containerStyles }}>
      {fieldsAndValues.map((fieldAndValue) => (
        <div className="textContainer">
          <h4
            className="field"
            style={{ ...fieldStyles }}
          >{`${fieldAndValue.field}: `}</h4>{" "}
          <span style={{ ...valueStyles }} className="value">
            {fieldAndValue.value}
          </span>
        </div>
      ))}
    </div>
  );
};

import "../css/about.css";
import logo from "../assets/myLogo.png";

export const About = () => {
  return (
    <section className="about console">
      <div className="consoleHeader">
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      <h2>About</h2>
      <div className="paragraphContainer">
        <h3>Services</h3>
        <p>
          At EmpEd, we are dedicated to delivering a suite of premium services
          tailored to educational institutions worldwide. Our app offers a
          comprehensive range of features, including:
        </p>
        <ul>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>
                Homework and Assignment Reporting -
              </span>{" "}
              Our Homework and Assignment Reporting feature empowers teachers to
              effortlessly share homework and assignments with parents,
              fostering seamless communication. Parents, in turn, can access
              assignments for multiple children across various schools at their
              convenience.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>One Way Messenger -</span>{" "}
              Designed exclusively for teachers and parents, our One Way
              Messenger facilitates effective communication. Teachers can
              initiate messages to parents, who can respond only when permitted
              by teachers, ensuring controlled and secure interactions.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>
                Attendance & Behavior Tracking -
              </span>{" "}
              For students in grades 4 to 12, our Attendance & Behavior Tracking
              provides real-time updates on attendance and behavior for each
              class period. Parents can access this information through the
              EmpEd app, promoting transparency and engagement.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>
                Daily Attendance & Behavior-
              </span>{" "}
              Tailored for grades 1 to 3, our Daily Attendance & Behavior
              feature simplifies attendance and behavior tracking. It provides a
              once-a-day snapshot of crucial information to keep parents
              informed.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>
                Upcoming Tests Notification -
              </span>{" "}
              EmpEd enables teachers to share timely information about upcoming
              tests, enabling parents to support their children's study efforts
              and ensure preparedness for examinations.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>Test Results Sharing -</span>{" "}
              Our app allows teachers to promptly share students' test results
              with parents, providing insight into academic progress and
              achievements. Parents can easily access test results for all their
              children in one place.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>
                Letters and Announcements -
              </span>{" "}
              Utilizing the admin console, schools can efficiently distribute
              letters to either the entire parent body or specific classes.
              Parents can access these letters within the EmpEd app, promoting
              effective school-home communication.
            </p>
          </li>
        </ul>
        <p>
          At EmpEd, we're committed to enhancing the educational experience for
          both educators and parents. Our services are designed to foster
          collaboration, transparency, and engagement, ultimately enriching the
          academic journey for students.
        </p>
      </div>
      <div className="paragraphContainer">
        <h3>Sign Up and Empower Your Educational Journey</h3>
        <p>
          At EmpEd, we are dedicated to fostering strong partnerships between
          schools, parents, and teachers to create a seamless and enriched
          educational experience. Join us in this journey of empowerment by
          signing up today.
        </p>
        <h4>For Schools:</h4>
        <p>Unlock the Full Potential of EmpEd</p>
        <ul>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>Admin Console Access:</span> As
              a school, you gain access to our powerful Admin Console,
              absolutely free. With this console, you can efficiently manage and
              facilitate communication between teachers, parents, and students.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>Control and Convenience:</span>{" "}
              Schools have the unique ability to create and manage parent and
              teacher accounts effortlessly. Seamlessly integrate EmpEd into
              your educational ecosystem, ensuring transparency and engagement.
            </p>
          </li>
        </ul>
        <h4>For Parents and Teachers:</h4>
        <p>Don't Miss Out – Advocate for Your School</p>
        <ul>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>Empower Your School:</span>{" "}
              Parents and teachers, you hold the key to enhancing your school's
              communication and educational support. Advocate for EmpEd within
              your institution to unlock its full potential.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>
                User-Friendly Registration:
              </span>{" "}
              Once your school is registered, parents and teachers can easily
              create their accounts, gaining access to valuable features like
              Homework and Assignment Reports, One Way Messenger, Attendance &
              Behavior Tracking, and much more.
            </p>
          </li>
        </ul>
        <h4>How to Get Started:</h4>
        <ol>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>For Schools:</span> You can
              register your school on our website or contact our support team.
              We'll provide you with a free admin account. Schools don't pay a
              cent.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>For Parents and Teachers:</span>{" "}
              Speak to your school administration about the benefits of EmpEd.
              Encourage them to register and create accounts for parents and
              teachers, making educational communication more efficient and
              transparent.
            </p>
          </li>
        </ol>
        <h4>Join Us Today and Transform Education</h4>
        <p>
          EmpEd is more than just an app; it's a platform that empowers
          educational institutions, teachers, parents, and students. By signing
          up and embracing EmpEd, you're taking a significant step towards a
          more connected, informed, and supportive educational community.
        </p>
        <p>
          Start the conversation within your school, and let's work together to
          transform education. Join EmpEd today and experience the future of
          educational communication.
        </p>
      </div>
    </section>
  );
};

import {
  getImageUrlFromStorage,
  uploadFileToStorage,
} from "../../../../../firebase-config";
import { deleteObjectAtPath } from "../../../../../helperFunctions/deleteObjectAtPath";
import { addNewObjectAtPath } from "../../../../../helperFunctions/updateObjectAtPath";

export function returnMobileSectionSideBarButtons(
  setSelectedComponent,
  selectedComponent,
  setSelectedComponentId,
  setSelectedComponentPath,
  setFileComponentsObject,
  selectedComponentPath,
  fileComponentsObject,
  currentSchool,
  templateId,
  setNewComponentSelector,
  editorClipBoard
) {
  return [
    {
      inputValue: selectedComponent?.name,
      title: "Name",
      type: "input",
      category: "Name",
      onChange: async (v) => {
        try {
          setSelectedComponent({
            ...selectedComponent,
            name: v,
          });
        } catch (error) {
          console.log(error);
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.backgroundColor,
      title: "Background Color",
      type: "color",
      category: "Background",
      onChange: async (v) => {
        try {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              backgroundColor: v,
            },
          });
        } catch (error) {
          console.log(error);
        }
      },
    },

    {
      inputValue: selectedComponent?.backgroundImage?.imgPathUrl,
      title: "Background Image",
      type: "image",
      category: "Background",
      onChange: async (v) => {
        try {
          const response = await uploadFileToStorage(
            `schools/${currentSchool.schoolId}/savedWebsiteTemplates/${templateId}/images/${v?.target?.files[0].name}`,
            v?.target?.files[0]
          );
          setSelectedComponent({
            ...selectedComponent,
            backgroundImage: {
              imgPathUrl: await getImageUrlFromStorage(response.ref.fullPath),
              fileName: response.ref.name,
            },
          });
        } catch (error) {
          throw error;
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.visibility,
      title: "Visibility",
      type: "select",
      options: ["visible", "hidden", "collapse"],
      category: "Flex Styles",
      onChange: async (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "visibility",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              visibility: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.zIndex,
      title: "Z-Index",
      type: "number",
      category: "Flex Styles",
      /*   units: ["px", "%"], */
      /*  includeAuto: true, */
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["mobileStyles", "zIndex"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              zIndex: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.height,
      title: "Height",
      type: "number",
      category: "Size",
      units: ["px", "%"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["mobileStyles", "height"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              height: v,
            },
          });
        }
      },
    },
    {
      type: "plain",
      value: "paste",
      onClick: () => {
        setFileComponentsObject(
          addNewObjectAtPath(
            fileComponentsObject,
            [
              ...selectedComponentPath,
              "nestedComponents",
              editorClipBoard.name,
            ],
            editorClipBoard
          )
        );
        setSelectedComponent({});
        setSelectedComponentId("");
        setSelectedComponentPath([]);
      },
    },
    {
      type: "plain",
      value: "delete",
      onClick: () => {
        setFileComponentsObject(
          deleteObjectAtPath(fileComponentsObject, [...selectedComponentPath])
        );
        setSelectedComponent({});
        setSelectedComponentId("");
        setSelectedComponentPath([]);
      },
    },
    {
      type: "plain",
      title: "add Element",
      value: "add Element",
      onClick: () => {
        /* setNewElementForm({ ...newElementForm, active: true }); */
        setNewComponentSelector((newSelectedComponent) => ({
          ...newSelectedComponent,
          active: true,
        }));
      },
    },
  ];
}

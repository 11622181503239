import { useState, useEffect } from "react";
import { getClassRooms } from "../../helperFunctions/dataFetcher";
import "../../css/letterSender.css";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  where,
  writeBatch,
} from "firebase/firestore";
import { auth, db } from "../../firebase-config";
import { ClassroomPicker } from "../../reausable Components/classRoomPicker";
import { generateRandomId } from "../../helperFunctions/randomIdGenerator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const LetterBody = ({ letter }) => {
  return (
    <div className="letterBody">
      {letter?.paragraphs.map((paragraph) => (
        <p className="letterParagraph">{paragraph.paragraph}</p>
      ))}
    </div>
  );
};

const Letter = ({ sendLetter, currentSchool, letter }) => {
  return (
    <div className="letter">
      <h3 className="letterIntro">Dear Parents/Guardians:</h3>
      <LetterBody letter={letter} />
      <h3 className="letterClosing">Best Regards</h3>
      <h3 className="letterSignature">{currentSchool?.principalName}</h3>
      <button
        className="button"
        onClick={() => {
          sendLetter();
        }}
      >
        Send Letter
      </button>
    </div>
  );
};

const LetterSenderForm = ({ setLetter, letter, nextParagraph }) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        nextParagraph();
      }}
      className="letterSenderForm"
    >
      <div>
        <label htmlFor="letter">
          <textarea
            value={letter?.paragraphs[letter?.paragraphs.length - 1].paragraph}
            onChange={(e) =>
              setLetter((prevLetter) => {
                const updatedParagraphs = [...prevLetter.paragraphs]; // Create a copy of the paragraphs array
                const lastParagraph =
                  updatedParagraphs[updatedParagraphs.length - 1]; // Get the last paragraph object
                lastParagraph.paragraph = e.target.value; // Update the paragraph property of the last object
                return { ...prevLetter, paragraphs: updatedParagraphs }; // Return the updated letter object
              })
            }
            name="letter"
            rows={10}
            cols={30}
          />
        </label>
      </div>
      <button className="button">Next Paragraph</button>
    </form>
  );
};

export const LetterSender = ({
  setAppState,
  currentSchool,
  setLoading,
  setNotify,
}) => {
  const [pageState, setPageState] = useState("initial");
  const [classRooms, setClassRooms] = useState([]);
  const [learners, setLearners] = useState([]);
  const [letter, setLetter] = useState({
    paragraphs: [{ id: 0, paragraph: "" }],
    for: "",
  });
  useEffect(() => {
    async function getData() {
      const classRoomsRef = collection(
        db,
        "schools",
        currentSchool.schoolId,
        "classrooms"
      );
      let arr = [];
      const learnersRef = query(
        collection(db, "schools", currentSchool.schoolId, "users"),
        where("accountType", "!=", "teacher")
      );

      const learnerDocs = await getDocs(learnersRef);
      learnerDocs.forEach((learner) => {
        arr.push({ ...learner.data(), docId: learner.id });
      });
      setLearners(arr);
      getClassRooms(classRoomsRef, setClassRooms);
    }
    getData();
  }, []);

  async function sendLetter() {
    try {
      setLoading(true);
      let writeCount = 0;
      const writes = [];
      const learnerCount = learners.length;
      let leftOver = learnerCount;

      for (const learner of learners) {
        writeCount += 1;
        leftOver -= 1;
        console.log(learner);
        writes.push({
          write: doc(
            db,
            "schools",
            currentSchool.schoolId,
            "users",
            learner.docId,
            "letters",
            generateRandomId()
          ),
          learner: learner,
        });

        /* I can only make 200 writes at a time 
        so this statement checks if I either have 
        200 writes ready or I have 0 writes leftOver and
        all writes stored in "writes" are ready to be intitialized
        and commited */
        if (writeCount === 200 || leftOver === 0) {
          const writeNewLetter = writeBatch(db);
          const writeLetters = writeBatch(db);
          writes.forEach((write) => {
            writeLetters.set(write.write, {
              ...letter,
              serverTimeStamp: serverTimestamp(),
            });
            writeNewLetter.update(
              doc(
                db,
                "schools",
                currentSchool.schoolId,
                "users",
                write.learner.docId
              ),
              {
                newLetters: true,
              }
            );
          });
          await writeLetters.commit();
          await writeNewLetter.commit();

          // Reset the writeCount and clear the writes array
          writeCount = 0;
          writes.length = 0;
        }
      } /* automaticaly exit the loop once all learners 
      have been looped through */

      setAppState("frontPage");
      setLetter({});
      setLoading(false);
      setNotify({
        on: true,
        type: "notification",
        notification: "Letter was sent successfully",
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      setNotify({
        on: true,
        type: "error",
        notification: "Something went wrong when trying to send the letter",
        solution:
          "Please make sure you have a strong and stable internet connection before trying again",
      });
    }
  }

  function logOut() {
    auth.signOut();
    setAppState("login");
  }
  function nextParagraph() {
    setLetter({
      ...letter,
      paragraphs: [
        ...letter.paragraphs,
        { id: letter.paragraphs.length, paragraph: "" },
      ],
    });
  }
  return (
    <div className="console">
      <div className="consoleHeader">
        {pageState === "letterSenderForm" && (
          <div
            className="backButton"
            onClick={() => setAppState("adminConsole")}
          >
            <FontAwesomeIcon size="2x" icon={faArrowLeft} />
          </div>
        )}
      </div>

      {pageState === "initial" && (
        <ClassroomPicker
          heading={"Please choose a class to send a letter to"}
          onBackPress={() => setAppState("adminConsole")}
          logOut={logOut}
          handleClassRoomSelect={(classroom) => {
            classroom !== "all" &&
              setLearners(
                learners.filter((learner) => {
                  return learner.class === classroom;
                })
              );
            setLetter({
              ...letter,
              new: true,
              principalName: currentSchool.principalName,
              date: `${new Date().getFullYear()}-${
                new Date().getMonth() + 1
              }-${new Date().getDate()}`,
            });
            setPageState("letterSenderForm");
            console.log(classroom);
          }}
          classrooms={[{ className: "all", classTeacher: "" }, ...classRooms]}
        />
      )}
      {pageState === "letterSenderForm" && (
        <div className="letterSenderFormContainer">
          <LetterSenderForm
            nextParagraph={nextParagraph}
            setLetter={setLetter}
            letter={letter}
          />
          <Letter
            sendLetter={sendLetter}
            letter={letter}
            currentSchool={currentSchool}
          />
        </div>
      )}
    </div>
  );
};

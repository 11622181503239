import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/myLogo.png";

export const Classrooms = ({
  classrooms,
  handleClassRoomSelect,
  handleClassRoomDelete,
  setPageState,
  setAppState,
  currentSchool,
  onGoBack,
}) => {
  function goBack() {
    onGoBack();
  }
  return (
    <div className="console">
      <div className="consoleHeader">
        <div className="backButton" onClick={goBack}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>

        <img alt="logo" src={logo} className="consoleLogo" />
      </div>

      <div className="classRoomsContainer">
        <ul className="classRooms">
          {classrooms.map((classRoom) => (
            <li
              className="classRoom"
              key={classRoom.className}
              onClick={() => handleClassRoomSelect(classRoom)}
            >
              <div className="classRoomName">{classRoom?.className}</div>
              <div className="classRoomTeacher">{classRoom?.classTeacher}</div>
              <div
                onClick={async (e) => {
                  e.stopPropagation();

                  await handleClassRoomDelete(classRoom);
                }}
                style={{
                  position: "absolute",
                  right: "60px",
                  zIndex: 30,
                }}
              >
                <FontAwesomeIcon
                  className="deleteButton"
                  style={{ fontSize: "20px" }}
                  size="2x"
                  icon={faTrashCan}
                />
              </div>
            </li>
          ))}
        </ul>
        <div className="createClassRoomBtnContainer">
          <button
            className="button createClassRoomBtn"
            onClick={() => setPageState("classCreator")}
          >
            Create Class
          </button>
        </div>
      </div>
    </div>
  );
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase-config";

import logo from "../assets/myLogo.png";
import { MyLoader } from "../reausable Components/myLoader";
import { Link } from "react-router-dom";
import { NotificationBar } from "../reausable Components/myNotificationBar";

export const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    on: false,
    notification: "",
    type: "",
  });
  const [messageInfo, setMessageInfo] = useState({
    senderEmail: "",
    title: "",
    message: "",
    senderName: "",
  });

  async function handleMessageSend() {
    const messageRef = collection(db, "supportMessages");
    try {
      setLoading(true);
      await addDoc(messageRef, messageInfo);
      setLoading(false);
      setNotify({
        on: true,
        type: "notification",
        notification:
          "Your message was sent, valid messages will recieve a resposne in 2 - 4 bussiness days",
        timeOut: 7000,
      });
      setMessageInfo({
        senderEmail: "",
        title: "",
        message: "",
        senderName: "",
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  return (
    <section className="formsContainer console">
      {loading && <MyLoader />}
      {notify && <NotificationBar notify={notify} setNotify={setNotify} />}
      <div className="consoleHeader">
        <div className="backButton"></div>
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      <h1 style={{ marginTop: 0 }}>Contact Us</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleMessageSend();
        }}
        className="form largeForm"
      >
        <div>
          <label htmlFor="name">Name: </label>
          <input
            name="name"
            onChange={(e) => {
              setMessageInfo({ ...messageInfo, senderName: e.target.value });
            }}
            value={messageInfo.senderName}
            required
            type="text"
            placeholder="Your name goes here"
            className="mediumFormInput"
          />
        </div>
        <div>
          <label>Email: </label>
          <input
            onChange={(e) => {
              setMessageInfo({ ...messageInfo, senderEmail: e.target.value });
            }}
            value={messageInfo.senderEmail}
            required
            type="email"
            placeholder="Your email goes here"
            className="mediumFormInput"
          />
        </div>
        <div>
          <label>Title: </label>
          <input
            onChange={(e) => {
              setMessageInfo({ ...messageInfo, title: e.target.value });
            }}
            value={messageInfo.title}
            required
            placeholder="What is your message about ?"
            className="mediumFormInput"
          />
        </div>
        <div>
          <label>Message: </label>
          <textarea
            onChange={(e) => {
              setMessageInfo({ ...messageInfo, message: e.target.value });
            }}
            value={messageInfo.message}
            required
            placeholder="Your message goes here"
          />
        </div>
        <button type="submit" className="button">
          Send
        </button>
      </form>
    </section>
  );
};

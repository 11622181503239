export function objectToArray(obj = {}) {
  const newArray = [];

  function processObject(obj) {
    const keys = Object?.keys(obj)?.sort(
      (a, b) => obj[a]?.index - obj[b]?.index
    );

    for (const key of keys) {
      const item = obj[key];
      const newItem = { ...item };

      if (item?.nestedComponents) {
        newItem.nestedComponents = objectToArray(item?.nestedComponents);
      }

      newArray?.push(newItem);
    }
  }

  processObject(obj);

  return newArray;
}

export function oneLayerObjectToArray(obj = {}) {
  const newArray = [];

  function processObject(obj) {
    const keys = Object?.keys(obj)?.sort(
      (a, b) => obj[a]?.index - obj[b]?.index
    );

    for (const key of keys) {
      const item = obj[key];
      const newItem = { ...item };

      newArray?.push(newItem);
    }
  }
  processObject(obj);

  return newArray;
}

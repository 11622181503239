import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { separateNumberFromUnit } from "../helperFunctions/separateNumberFromUnit";
import { getImageUrlFromStorage } from "../firebase-config";

export const PlainButton = ({ value, active, onClick }) => {
  return (
    <div
      onClick={(e) => {
        /*  e.stopPropagation(); */
        onClick(e);
      }}
      style={{
        boxSizing: "border-box",
        border: "#333 solid 1px",
        width: "100%",
        padding: 10,
        margin: 0,
        backgroundColor: !active ? "lightgrey" : "darkgray",
        marginBottom: "10px",
      }}
    >
      {value}
    </div>
  );
};

const SlideButton = ({
  value,
  active,
  innerButtons = [{ value: "", onClick: () => {} }],
  onClick,
}) => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <div
        onClick={(e) => {
          onClick(e);
        }}
        style={{
          boxSizing: "border-box",
          border: "#333 solid 1px",
          width: "100%",
          padding: 10,
          margin: 0,
          backgroundColor: !active ? "lightgrey" : "darkgray",
        }}
      >
        {value}
      </div>
      {innerButtons.map((innerButton) => {
        return (
          active && (
            <button onClick={() => innerButton.onClick(innerButton.value)}>
              {innerButton.value}
            </button>
          )
        );
      })}
    </div>
  );
};

const TextInput = ({ title, onChange, inputValue }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        border: "#333 solid 1px",
        marginBottom: "10px",
      }}
    >
      {title}
      <input
        type="text"
        onChange={(e) => {
          if (!e?.target?.value || e?.target?.value === "") {
            onChange("");
          } else {
            onChange(e);
          }
        }}
        value={inputValue ? inputValue : ""}
      />
    </div>
  );
};
const ColorInput = ({ title, onChange, inputValue }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        border: "#333 solid 1px",
        marginBottom: "10px",
      }}
    >
      {title}
      <input
        type="color"
        onChange={onChange}
        value={inputValue ? inputValue : ""}
      />
    </div>
  );
};
const ImageInput = ({
  title,
  onChange,
  inputValue,
  setNewImageSelector,
  fileSelectorActive,
  setFileSelectorActive,
}) => {
  const [imgUrl, setImgUrl] = useState("");

  const fileInputRef = useRef();

  useEffect(() => {
    if (fileSelectorActive) {
      fileInputRef.current.click();
      setFileSelectorActive(false);
    }
  }, [fileSelectorActive]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        border: "#333 solid 1px",
        marginBottom: "10px",
      }}
    >
      {title}

      <img style={{ width: "65%" }} src={inputValue} alt="" />

      <input
        style={{ visibility: "hidden" }}
        onTouchCancel={() => console.log("cancelled")}
        ref={fileInputRef}
        type="file"
        onChange={(e) => {
          onChange(e);
        }}
      />

      <button
        style={{ maxWidth: "80%" }}
        onClick={() => {
          /*  fileInputRef.current.click(); */
          setNewImageSelector({ active: true });
        }}
      >
        switch image
      </button>
    </div>
  );
};

const NumberInput = ({
  title,
  onChange,
  inputValue,
  units,
  includeAuto = false,
}) => {
  const [value, setValue] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [autoState, setAutoState] = useState(false);

  useEffect(() => {
    if (!typeof inputValue === "number") {
      if (!separateNumberFromUnit(inputValue).at(1)) {
        setSelectedUnit(units[0]);
      }
    }
  }, []);

  useEffect(() => {
    let myNumericValue =
      typeof inputValue === "number"
        ? inputValue
        : separateNumberFromUnit(inputValue).at(0);
    let myUnit =
      typeof inputValue === "number"
        ? undefined
        : separateNumberFromUnit(inputValue).at(1);

    if (myUnit) {
      setValue(myNumericValue);
      setSelectedUnit(myUnit);
    } else if (!myUnit && typeof myNumericValue === "number") {
      setValue(myNumericValue);
    } else if (inputValue === "auto") {
      setValue("auto");
      setAutoState(true);
    } else if (!inputValue || inputValue === "" || inputValue === "unset") {
      setValue("");
      setSelectedUnit(units[0]);
      setAutoState(false);
    }
  }, [inputValue]);

  function handleInputChange(e) {
    setValue(e.target.value);
    if (!e.target.value || e.target.value === "") {
      onChange("");
    } else {
      if (units.length > 0) {
        onChange(e.target.value + selectedUnit);
      } else {
        onChange(e.target.value);
      }
    }
  }

  function handleUnitChange(e) {
    setSelectedUnit(e.target.value);
    onChange(value + e.target.value);
  }

  function handleAutoChange(e) {
    if (autoState) {
      setAutoState(false);
      setValue("");
      onChange("");
    } else if (!autoState) {
      setAutoState(true);
      setValue("auto");
      onChange("auto");
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        border: "#333 solid 1px",
        marginBottom: "10px",
      }}
    >
      {title}
      <div style={{ display: "flex", alignItems: "center", maxWidth: "90%" }}>
        {!autoState && (
          <input
            disabled={autoState}
            style={{ width: "90%" }}
            type="number"
            onChange={handleInputChange}
            value={value}
          />
        )}

        {units && units.length > 0 && !autoState && (
          <select
            disabled={autoState}
            value={selectedUnit}
            onChange={handleUnitChange}
          >
            {units.map((unit) => (
              <option key={unit} value={unit}>
                {unit}
              </option>
            ))}
          </select>
        )}
      </div>
      {includeAuto && (
        <div
          style={{ display: "flex", flexDirection: "row", columnGap: "3px" }}
        >
          Auto:{" "}
          <input
            checked={autoState}
            onChange={(e) => {
              handleAutoChange(e);
            }}
            type="checkbox"
          />
        </div>
      )}
    </div>
  );
};

const SelectInput = ({ title, onChange, options, defautlValue }) => {
  const [selectedValue, setSelectedValue] = useState(defautlValue);

  useEffect(() => {
    if (defautlValue === undefined) {
      setSelectedValue("");
    } else {
      setSelectedValue(defautlValue);
    }
  }, [defautlValue]);

  useEffect(() => {
    onChange(selectedValue);
  }, [selectedValue]);

  function handleSelectOption(e) {
    setSelectedValue(e.target.value);
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        border: "#333 solid 1px",
        marginBottom: "10px",
      }}
    >
      {title}
      <select value={selectedValue} onChange={handleSelectOption}>
        <option value={""}>{"undefined"}</option>;
        {options?.map((option) => {
          return <option value={option}>{option}</option>;
        })}
      </select>
    </div>
  );
};

const Checkbox = ({ title, onChange, inputValue }) => {
  const [checked, setChecked] = useState(inputValue || false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: 2,
        border: "#333 solid 1px",
        marginBottom: "10px",
      }}
    >
      {title}:{" "}
      <input
        onChange={(e) => {
          setChecked(!checked);
          onChange(!checked);
        }}
        checked={checked}
        type="checkbox"
      />
    </div>
  );
};

const SideBarCategoreis = ({
  categoryInfo = {},
  activeButtonValue,
  setActiveButtonValue,
  active,
  setActive,
  setNewImageSelector,
  fileSelectorActive,
  setFileSelectorActive,
}) => {
  return (
    <div style={{ marginRight: "12px" }}>
      <div
        onClick={() => {
          setActive(true);
        }}
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "6px",
          borderBottom: "#000 solid 2px",
          boxShadow: "-3px 2px #333",
          backgroundColor: "papayawhip",
          rowGap: "10px",
        }}
      >
        <label>{categoryInfo?.category}</label>
        {!active && (
          <FontAwesomeIcon size="30px" color="black" icon={faCaretUp} />
        )}
        {active && (
          <FontAwesomeIcon size="30px" color="black" icon={faCaretDown} />
        )}
      </div>
      {active &&
        categoryInfo?.buttons?.map((button) => {
          if (button?.type === "plain") {
            return (
              <PlainButton
                active={activeButtonValue === button?.value}
                onClick={() => {
                  button?.onClick(button);
                  setActiveButtonValue(button?.value);
                }}
                value={button?.value}
              />
            );
          } else if (button?.type === "slide") {
            return (
              <SlideButton
                active={activeButtonValue === button?.value}
                innerButtons={button?.innerButtons}
                value={button?.value}
                onClick={() => {
                  button?.onClick(button?.value);
                  setActiveButtonValue(button?.value);
                }}
              />
            );
          } else if (button?.type === "input") {
            return (
              <TextInput
                onChange={(e) => {
                  button?.onChange(e?.target?.value);
                }}
                title={button?.title}
                inputValue={button?.inputValue}
              />
            );
          } else if (button?.type === "number") {
            return (
              <NumberInput
                includeAuto={button.includeAuto}
                title={button?.title}
                inputValue={button?.inputValue}
                units={button.units ? button.units : []}
                onChange={(e) => {
                  button.onChange(e);
                }}
              />
            );
          } else if (button?.type === "select") {
            return (
              <SelectInput
                defautlValue={button.inputValue}
                onChange={(e) => {
                  button?.onChange(e);
                }}
                title={button?.title}
                options={button?.options}
              />
            );
          } else if (button?.type === "color") {
            return (
              <ColorInput
                onChange={(e) => {
                  button?.onChange(e.target.value);
                }}
                title={button?.title}
                inputValue={button?.inputValue}
              />
            );
          } else if (button?.type === "image") {
            return (
              <ImageInput
                onChange={(e) => {
                  button?.onChange(e);
                }}
                title={button?.title}
                inputValue={button?.inputValue}
                setNewImageSelector={setNewImageSelector}
                fileSelectorActive={fileSelectorActive}
                setFileSelectorActive={setFileSelectorActive}
              />
            );
          } else if (button?.type === "checkbox") {
            return (
              <Checkbox
                title={button?.title}
                inputValue={button?.inputValue}
                onChange={(e) => {
                  button?.onChange(e);
                }}
              />
            );
          }
        })}
    </div>
  );
};

export const SideBar = ({
  side,
  buttons = [
    {
      value: "",
      type: "plain",
      onClick: () => {},
      onChange: () => {},
      innerButtons: [],
      inputValue: "",
      title: "",
      category: "",
    },
  ],
  saveBtn = { active: false, onClick: () => {} },
  extraBtn = { active: false, onClick: () => {}, value: "something" },
  setNewImageSelector,
  fileSelectorActive,
  setFileSelectorActive,
}) => {
  // buttons format : [{value: "value", type: plain || slide}]
  const [activeButtonValue, setActiveButtonValue] = useState("");
  const [categorizedButtons, setCategorizedButtons] = useState([
    { category: "", buttons: [] },
  ]);

  const [activeCategory, setActiveCategory] = useState("");

  useEffect(() => {
    function categorizeButtons() {
      let allCategories = [];
      let newCategorizedButtons = [];
      for (const button of buttons) {
        if (!allCategories.includes(button.category)) {
          allCategories = [...allCategories, button.category];
        }
      }

      for (const category of allCategories) {
        newCategorizedButtons = [
          ...newCategorizedButtons,
          {
            category: category,
            buttons: buttons.map((button) => {
              if (button.category === category) {
                return button;
              } else {
                return;
              }
            }),
          },
        ];
      }

      setCategorizedButtons(newCategorizedButtons);
    }

    categorizeButtons();
  }, [buttons]);
  return (
    <div
      style={{
        borderLeft: side === "right" && "1px grey solid",
        borderRight: side === "left" && "1px grey solid",
        backgroundColor: "white",
        width: "200px",
        zIndex: 1,
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
        overflow: "scroll",
        height: "700px",
      }}
    >
      {categorizedButtons.map((category) => {
        if (!category.category) {
          return category?.buttons?.map((button) => {
            if (button?.type === "plain") {
              return (
                <PlainButton
                  active={activeButtonValue === button?.value}
                  onClick={() => {
                    button?.onClick(button);
                    setActiveButtonValue(button?.value);
                  }}
                  value={button?.value}
                />
              );
            } else if (button?.type === "slide") {
              return (
                <SlideButton
                  active={activeButtonValue === button?.value}
                  innerButtons={button?.innerButtons}
                  value={button?.value}
                  onClick={() => {
                    button?.onClick(button?.value);
                    setActiveButtonValue(button?.value);
                  }}
                />
              );
            } else if (button?.type === "input") {
              return (
                <TextInput
                  onChange={(e) => {
                    button?.onChange(e.target.value);
                  }}
                  title={button?.title}
                  inputValue={button?.inputValue}
                />
              );
            } else if (button?.type === "color") {
              <ColorInput
                onChange={(e) => {
                  button?.onChange(e.target.value);
                }}
                title={button?.title}
                inputValue={button?.inputValue}
              />;
            }
          });
        } else if (category.category) {
          return (
            <SideBarCategoreis
              setFileSelectorActive={setFileSelectorActive}
              fileSelectorActive={fileSelectorActive}
              setNewImageSelector={setNewImageSelector}
              setActive={(e) => setActiveCategory(category.category)}
              active={activeCategory === category.category}
              activeButtonValue={activeButtonValue}
              setActiveButtonValue={setActiveButtonValue}
              categoryInfo={category}
            />
          );
        }
      })}

      {saveBtn.active && (
        <button onClick={saveBtn.onClick} style={{ alignSelf: "flex-end" }}>
          save
        </button>
      )}
      {extraBtn.active && (
        <button onClick={extraBtn.onClick} style={{ alignSelf: "flex-end" }}>
          {extraBtn.value}
        </button>
      )}
    </div>
  );
};

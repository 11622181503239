import { returnMobileAbasoluteContainerSideBarButtons } from "./mobileButtons/absoluteContainerSidebarButtons";
import { returnMobileFormSideBarButtons } from "./mobileButtons/formSidebarButtons";
import { returnMobileImageSideBarButtons } from "./mobileButtons/imageSidebarButtons";
import { returnMobileInputSideBarButtons } from "./mobileButtons/inputSidebarButtons";
import { returnMobileLinkTextSideBarButtons } from "./mobileButtons/linkTextSidebarButtons";
import { returnMobileMenuItemsContainerSidebarButtons } from "./mobileButtons/menuItemsContainer";
import { returnMobileMobileMenuSideBarButtons } from "./mobileButtons/mobileMenuSidebarButtons";
import { returnMobileRelativeContainerSideBarButtons } from "./mobileButtons/relativeContainerSidebarButtons";
import { returnMobileSectionSideBarButtons } from "./mobileButtons/sectionSidebarButtons";
import { returnMobileSlideSideBarButtons } from "./mobileButtons/slideSideBarButton";
import { returnMobileStripBarButtons } from "./mobileButtons/stripSidebarButtons";
import { returnMobileSubmitButtonSideBarButtons } from "./mobileButtons/submitButtonSidebarButtons";
import { returnMobileTextSideBarButtons } from "./mobileButtons/textSidebarButtons";
import { returnPcAbasoluteContainerSideBarButtons } from "./pcButtons/absoluteContainerSidebarButtons";
import { returnPcFormSideBarButtons } from "./pcButtons/formSidebarButtons";
import { returnPcImageSideBarButtons } from "./pcButtons/imageSidebarButtons";
import { returnPcInputSideBarButtons } from "./pcButtons/inputSidebarButtons";
import { returnPcLinkTextSideBarButtons } from "./pcButtons/linkTextSidebarButtons";
import { returnPcMenuItemsContainerSidebarButtons } from "./pcButtons/menuItemsContainer";
import { returnPcMobileMenuSideBarButtons } from "./pcButtons/mobileMenuSidebarButtons";
import { returnPcRelativeContainerSideBarButtons } from "./pcButtons/relativeContainerSidebarButtons";
import { returnPcSectionSideBarButtons } from "./pcButtons/sectionSidebarButtons";
import { returnPcSlideSideBarButtons } from "./pcButtons/slideSideBarButton";
import { returnPcStripBarButtons } from "./pcButtons/stripSidebarButtons";
import { returnPcSubmitButtonSideBarButtons } from "./pcButtons/submitButtonSidebatButtons";
import { returnPcTextSideBarButtons } from "./pcButtons/textSidebarButtons";

export function returnSidebarButtonsArray(
  selectedComponent,
  currentSchool,
  setSelectedComponent,
  templateId,
  selectedComponentPath,
  setSelectedComponentId,
  setSelectedComponentPath,
  fileComponentsObject,
  setFileComponentsObject,
  codeFile,
  setNewComponentSelector,
  editorMode,
  codeFiles,
  editorClipBoard,
  setEditorClipBoard
) {
  if (editorMode === "pc") {
    /* PC */
    return (selectedComponent?.type === "text" ||
      selectedComponent?.type === "heading" ||
      selectedComponent?.type === "heading2" ||
      selectedComponent?.type === "heading3" ||
      selectedComponent?.type === "heading4" ||
      selectedComponent?.type === "icon") &&
      !selectedComponent.component
      ? returnPcTextSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          codeFile,
          editorClipBoard,
          setEditorClipBoard
        )
      : selectedComponent?.type === "menu"
      ? returnPcMobileMenuSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setNewComponentSelector
        )
      : selectedComponent?.type === "menuItemsContainer"
      ? returnPcMenuItemsContainerSidebarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          setNewComponentSelector
        )
      : selectedComponent.type === "Link Text" && !selectedComponent.component
      ? returnPcLinkTextSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          codeFile,
          codeFiles,
          setEditorClipBoard
        )
      : selectedComponent.type === "button" && !selectedComponent.component
      ? returnPcSubmitButtonSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          codeFile
        )
      : (selectedComponent.type === "container" ||
          selectedComponent.type === "box") &&
        !selectedComponent.component
      ? returnPcAbasoluteContainerSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setNewComponentSelector,
          editorClipBoard,
          setEditorClipBoard
        )
      : selectedComponent.type === "form" && !selectedComponent.component
      ? returnPcFormSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setNewComponentSelector
        )
      : selectedComponent.type === "relativeContainer" &&
        !selectedComponent.component
      ? returnPcRelativeContainerSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setNewComponentSelector,
          editorClipBoard,
          setEditorClipBoard
        )
      : selectedComponent.type === "image" && !selectedComponent.component
      ? returnPcImageSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setEditorClipBoard
        )
      : selectedComponent.type === "section" && !selectedComponent.component
      ? returnPcSectionSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setNewComponentSelector,
          editorClipBoard,
          setEditorClipBoard
        )
      : selectedComponent.component === "slide"
      ? returnPcSlideSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setNewComponentSelector
        )
      : selectedComponent.type === "strip"
      ? returnPcStripBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          editorClipBoard,
          setEditorClipBoard
        )
      : selectedComponent.type === "input" ||
        selectedComponent.type === "textArea"
      ? returnPcInputSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          codeFile
        )
      : [];
  } else if (editorMode === "mobile") {
    return (selectedComponent?.type === "text" ||
      selectedComponent?.type === "heading" ||
      selectedComponent?.type === "heading2" ||
      selectedComponent?.type === "heading3" ||
      selectedComponent?.type === "heading4" ||
      selectedComponent?.type === "icon") &&
      !selectedComponent.component
      ? returnMobileTextSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          codeFile
        )
      : selectedComponent.type === "Link Text" && !selectedComponent.component
      ? returnMobileLinkTextSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          codeFile,
          codeFiles,
          setEditorClipBoard
        )
      : selectedComponent.type === "button" && !selectedComponent.component
      ? returnMobileSubmitButtonSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          codeFile
        )
      : (selectedComponent.type === "container" ||
          selectedComponent.type === "box") &&
        !selectedComponent.component
      ? returnMobileAbasoluteContainerSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setNewComponentSelector,
          editorClipBoard,
          setEditorClipBoard
        )
      : selectedComponent.type === "form" && !selectedComponent.component
      ? returnMobileFormSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setNewComponentSelector
        )
      : selectedComponent.type === "strip"
      ? returnMobileStripBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          setNewComponentSelector
        )
      : selectedComponent?.type === "menu"
      ? returnMobileMobileMenuSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setNewComponentSelector
        )
      : selectedComponent?.type === "menuItemsContainer"
      ? returnMobileMenuItemsContainerSidebarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          setNewComponentSelector
        )
      : selectedComponent.type === "relativeContainer" &&
        !selectedComponent.component
      ? returnMobileRelativeContainerSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setNewComponentSelector,
          editorClipBoard,
          setEditorClipBoard
        )
      : selectedComponent.type === "image" && !selectedComponent.component
      ? returnMobileImageSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setEditorClipBoard
        )
      : selectedComponent.type === "section" && !selectedComponent.component
      ? returnMobileSectionSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setNewComponentSelector,
          editorClipBoard
        )
      : selectedComponent.component === "slide"
      ? returnMobileSlideSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          currentSchool,
          templateId,
          setNewComponentSelector
        )
      : selectedComponent.type === "input" ||
        selectedComponent.type === "textArea"
      ? returnMobileInputSideBarButtons(
          setSelectedComponent,
          selectedComponent,
          setSelectedComponentId,
          setSelectedComponentPath,
          setFileComponentsObject,
          selectedComponentPath,
          fileComponentsObject,
          codeFile
        )
      : [];
  }
}

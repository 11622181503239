import "../css/App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

export const QuestionNotification = ({
  question,
  onAccept,
  onDecline,
  Accept,
  Decline,
  backgroundColor,
  border,
  data,
}) => {
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255,255,255,0.7)",
        zIndex: 10,
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "35%",
          transform: "translateX(-50%)",
          padding: "12px",
          borderRadius: "12px",
          minWidth: "40%",
          maxWidth: "80%",
          backgroundColor: backgroundColor ? backgroundColor : "aliceblue",
          border: border ? border : "2px solid transparent",
          borderImage: border ? border : "linear-gradient(blue, steelblue) 1",
        }}
      >
        <FontAwesomeIcon
          color="orange"
          fontSize={"25px"}
          icon={faTriangleExclamation}
        />
        <h4>{question}</h4>
        <div style={{ display: "flex", gap: "20px" }}>
          <button
            className="button2"
            onClick={() => {
              onAccept(data);
            }}
          >
            {Accept ? Accept : "yes"}
          </button>
          <button
            className="button2"
            onClick={() => {
              onDecline(data);
            }}
          >
            {Decline ? Decline : "No"}
          </button>
        </div>
      </div>
    </div>
  );
};

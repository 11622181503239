import "../src/css/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Site from "./Site";
import { FreeAccountTsAndCs } from "./mainApp/freeAccountTsandCs";
import { About } from "./mainApp/about";
import { ContactUs } from "./mainApp/contactUs";
import { PrivacyPolicy } from "./mainApp/privacyPolicy";
import { RequestAccountRemoval } from "./mainApp/requestAccountRemoval";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Site />} />
        <Route path="freeAccountTsAndCs" element={<FreeAccountTsAndCs />} />
        <Route path="about" element={<About />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="requestAccountRemoval" element={<RequestAccountRemoval/>} />
        <Route path="contactUs" element={<ContactUs />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;

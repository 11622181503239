export function returnPlainIcon(name, icon) {
  return {
    index: "5",
    icon: icon,
    active: true,
    name: name,
    type: "icon",
    mobileStyles: {},

    styles: {
      left: "10px",
      position: "absolute",
      top: "10px",
      color: "#000",
      fontWeight: "800",
      padding: "0px",
      margin: "0px",
      width: "40px",
    },
  };
}
const iconNames = ["faAngleDoubleDown", "fabars", "faCameraRetro"];

export const icons = [
  {
    category: "Icon",
    index: "5",
    active: true,
    name: "plainIcon",
    type: "icon",
    icon: "faAngleDoubleDown",
    validParents: [
      "form",
      "section",
      "box",
      "container",
      "relativeContainer",
      "button",
    ],
  },
  {
    category: "Icon",
    index: "5",
    styles: { width: "60px" },
    active: true,
    name: "plainIcon",
    type: "icon",
    icon: "faCameraRetro",
    validParents: [
      "form",
      "section",
      "box",
      "container",
      "relativeContainer",
      "button",
    ],
  },
  {
    category: "Icon",
    index: "5",
    styles: { width: "60px" },
    active: true,
    name: "plainIcon",
    type: "icon",
    icon: "faBars",
    validParents: [
      "form",
      "section",
      "box",
      "container",
      "relativeContainer",
      "button",
    ],
  },
  {
    category: "Icon",
    index: "5",
    styles: { width: "60px" },
    active: true,
    name: "plainIcon",
    type: "icon",
    icon: "faXmark",
    validParents: [
      "form",
      "section",
      "box",
      "container",
      "relativeContainer",
      "button",
    ],
  },
  {
    category: "Icon",
    index: "5",
    styles: { width: "60px" },
    active: true,
    name: "plainIcon",
    type: "icon",
    icon: "faPhone",
    validParents: [
      "form",
      "section",
      "box",
      "container",
      "relativeContainer",
      "button",
    ],
  },
  {
    category: "Icon",
    index: "5",
    styles: { width: "60px" },
    active: true,
    name: "plainIcon",
    type: "icon",
    icon: "faCalendarDays",
    validParents: [
      "form",
      "section",
      "box",
      "container",
      "relativeContainer",
      "button",
    ],
  },
  {
    category: "Icon",
    index: "5",
    styles: { width: "60px" },
    active: true,
    name: "plainIcon",
    type: "icon",
    icon: "faClock",
    validParents: [
      "form",
      "section",
      "box",
      "container",
      "relativeContainer",
      "button",
    ],
  },
  {
    category: "Icon",
    index: "5",
    styles: { width: "60px" },
    active: true,
    name: "plainIcon",
    type: "icon",
    icon: "faLocationArrow",
    validParents: [
      "form",
      "section",
      "box",
      "container",
      "relativeContainer",
      "button",
    ],
  },
];

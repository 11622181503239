import { useEffect, useRef, useState } from "react";
import { updateObjectAtPath } from "../../../../helperFunctions/updateObjectAtPath";
import { getImageUrlFromStorage } from "../../../../firebase-config";

export const TemplateSection = ({
  children,
  style,
  infoObject,
  setSelectedComponent,
  selectedComponentPath,
  setSelectedComponentPath,
  fileComponentsObject,
  setFileComponentsObject,
  selectedComponent,
  id,
  selectedComponentId,
  setSelectedComponentId,
  editorMode,
}) => {
  const [componentStyles, setComponentStyles] = useState({});

  /* This Effect is used to make sure the componentStyles stateVariable is always up to date */
  useEffect(() => {
    if (editorMode === "pc") {
      setComponentStyles({ ...infoObject?.styles });
    } else if (editorMode === "mobile") {
      setComponentStyles({
        ...infoObject?.styles,

        ...infoObject?.mobileStyles,
      });
    }
  }, [editorMode, infoObject]);

  /* This effect updates the main components object, when the selectedComponet object changes */
  useEffect(() => {
    if (id === selectedComponentId) {
      setFileComponentsObject(
        updateObjectAtPath(
          fileComponentsObject,
          selectedComponentPath,
          selectedComponent
        )
      );
    }
  }, [selectedComponent]);

  return (
    <section
      style={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        margin: 0,
        backgroundImage: `url(${infoObject?.backgroundImage?.imgPathUrl})`,
        ...componentStyles,
        ...style,
        width: "100%",
        boxShadow:
          id === selectedComponentId
            ? "inset 0 0 10px 5px rgba(255, 119, 0, 0.9)"
            : infoObject?.styles?.boxShadow,
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (selectedComponentId !== id) {
          setSelectedComponent(infoObject);
          setSelectedComponentId(infoObject.name);
          console.log(selectedComponentPath);
          setSelectedComponentPath(selectedComponentPath);
        }
      }}
    >
      <div
        style={{
          width:
            editorMode === "mobile"
              ? "360px"
              : "980px" /* pc: 980px, mobile: 320px, */,
          height: "100%",
          border: "2px dashed #000",
          margin: "0 auto",
          position: "relative",
        }}
      >
        {selectedComponent.isDragging &&
          (editorMode === "pc"
            ? selectedComponent?.isPcComponentHorizontallyCentered
            : editorMode === "tablet"
            ? selectedComponent.isTabletComponentHorizontallyCentered
            : selectedComponent.isMobileComponentHorizontallyCentered) && (
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "0px",
                bottom: "0px",
                transform: "translateX(-50%)",
                border: "dotted #0034ff 2px",
                padding: 0,
                margin: 0,
                zIndex: 10000,
              }}
            ></div>
          )}
        {children}
      </div>
    </section>
  );
};

import { useEffect, useRef, useState } from "react";

export const TemplateDom = ({
  children,
  onAddSection,
  setDomSize,
  componentsObject,
  setEditorMode,
  editorMode,
}) => {
  const templateDomRef = useRef(null);
  useEffect(() => {
    const domWidth = templateDomRef?.current?.offsetWidth;
    const domHeight = templateDomRef?.current?.offsetHeight;
    setDomSize({ width: domWidth, height: domHeight });
  }, [
    templateDomRef?.current?.offsetWidth,
    templateDomRef?.current?.offsetHeight,
    componentsObject,
  ]);

  /*
   Both The Editor and the website "<body/>" Will keep the minWidth of 980px.
   While the breaking point for mediaQueries will be 780px
   */

  return (
    <div
      ref={templateDomRef}
      style={{
        backgroundColor: "#fff",
        height: "70vh",
        overflow: "auto",
        margin: "0px auto",
        position: "relative",
        width:
          editorMode === "pc"
            ? "1020px"
            : editorMode === "mobile"
            ? "400px"
            : "1020px",
      }}
    >
      {children}
      <button onClick={() => onAddSection()}>new section</button>
    </div>
  );
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/myLogo.png";
import { deleteStudent } from "../../../firebase-config";

export function Students({
  setPageState,
  currentStudent,
  students,
  handleStudentDelete,
  onStudentSelect,
  onGoBack,
}) {
  function goBack() {
    /* setPageState("initial"); */
    onGoBack();
  }
  return (
    <div className="console">
      <div className="consoleHeader">
        <div className="backButton" onClick={goBack}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>

        <h2>{currentStudent.class}</h2>
        <img className="consoleLogo" alt="logo" src={logo} />
      </div>
      <div className="classRoomsContainer">
        <ol className="students classRooms">
          {students.map((student) => (
            <li
              onClick={() => {
                onStudentSelect(student);
              }}
              className="classRoom"
              key={student.childName}
            >
              {student?.childName} {student?.surname}
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleStudentDelete(student);
                }}
                style={{
                  position: "absolute",
                  right: "60px",
                  zIndex: 30,
                }}
              >
                <FontAwesomeIcon
                  className="deleteButton"
                  style={{ fontSize: "20px" }}
                  size="2x"
                  icon={faTrashCan}
                />
              </div>
            </li>
          ))}
        </ol>
        <div className="createClassRoomBtnContainer">
          <button
            className="button createClassRoomBtn"
            onClick={() => setPageState("classEditor")}
          >
            Add New Student
          </button>
        </div>
      </div>
    </div>
  );
}

import { cloneAndRename } from "../../../../../helperFunctions/cloneAndRename";
import { deleteObjectAtPath } from "../../../../../helperFunctions/deleteObjectAtPath";
import { getValueAtPath } from "../../../../../helperFunctions/getValueAtPath";
import { generateRandomId } from "../../../../../helperFunctions/randomIdGenerator";
import { separateNumberFromUnit } from "../../../../../helperFunctions/separateNumberFromUnit";
import { addNewObjectAtPath } from "../../../../../helperFunctions/updateObjectAtPath";

export function returnMobileLinkTextSideBarButtons(
  setSelectedComponent,
  selectedComponent,
  setSelectedComponentId,
  setSelectedComponentPath,
  setFileComponentsObject,
  selectedComponentPath,
  fileComponentsObject,
  codeFile,
  codeFiles,
  setEditorClipBoard
) {
  const codeFileIds = codeFiles.map((currentFile) => {
    return currentFile.id;
  });
  return [
    {
      inputValue: selectedComponent?.value,
      title: "Value",
      type: "input",
      category: "Value",
      onChange: (v) => {
        setSelectedComponent({ ...selectedComponent, value: v });
      },
    },
    {
      inputValue: selectedComponent?.linkType,
      title: "Link Type",
      type: "select",
      category: "Link",
      options: ["Relative Link", "Absolute Link"],
      onChange: (v) => {
        setSelectedComponent({ ...selectedComponent, linkType: v });
      },
    },
    {
      inputValue: selectedComponent?.link,
      title: "Link",
      type: selectedComponent.linkType === "Relative Link" ? "select" : "input",
      category: "Link",
      options: [...codeFileIds],
      onChange: (v) => {
        setSelectedComponent({ ...selectedComponent, link: v });
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.color,
      title: "Text Color",
      category: "Color",
      type: "color",
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["mobileStyles", "color"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: { ...selectedComponent.mobileStyles, color: v },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.backgroundColor,
      title: "Background Color",
      category: "Background",
      type: "color",
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "backgroundColor",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              backgroundColor: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent.mobileStyles.backgroundColor ? true : false,
      title: "Background Visiblility",
      type: "checkbox",
      category: "Background",
      onChange: async (v) => {
        if (v) {
          try {
            setSelectedComponent({
              ...selectedComponent,
              mobileStyles: {
                ...selectedComponent.mobileStyles,
                backgroundColor: "rgb(255,255,255)",
              },
            });
          } catch (error) {
            console.log(error);
          }
        } else {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "backgroundColor",
            ])
          );
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.paddingLeft,
      title: "Padding Left",
      type: "number",
      category: "Padding",
      units: ["px"],
      includeAuto: true,
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "paddingLeft",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              paddingLeft: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.paddingRight,
      title: "Padding Right",
      type: "number",
      category: "Padding",
      units: ["px"],
      includeAuto: true,
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "paddingRight",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              paddingRight: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.paddingBottom,
      title: "Padding Bottom",
      type: "number",
      category: "Padding",
      units: ["px"],
      includeAuto: true,
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "paddingBottom",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              paddingBottom: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.paddingTop,
      title: "Padding Top",
      type: "number",
      category: "Padding",
      units: ["px"],
      includeAuto: true,
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "paddingTop",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              paddingTop: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.visibility,
      title: "Visibility",
      type: "select",
      options: ["visible", "hidden", "collapse"],
      category: "Flex Styles",
      onChange: async (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "visibility",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              visibility: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.zIndex,
      title: "Z-Index",
      type: "number",
      category: "Flex Styles",
      /*   units: ["px", "%"], */
      /*  includeAuto: true, */
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["mobileStyles", "zIndex"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              zIndex: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.width,
      title: "Width",
      type: "number",
      category: "Size",
      units: ["px", "%"],
      includeAuto: true,
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["mobileStyles", "width"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              width: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.height,
      title: "Height",
      type: "number",
      category: "Size",
      units: ["px", "%"],
      includeAuto: true,
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["mobileStyles", "height"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              height: v,
            },
          });
        }
      },
    },

    {
      inputValue: selectedComponent?.mobileStyles?.borderRadius,
      title: "Border Raduis",
      category: "Border",
      type: "number",
      units: ["px", "%"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "borderRadius",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              borderRadius: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.borderStyle,
      title: "Border Style",
      category: "Border",
      type: "select",
      options: ["solid", "dashed", "dotted", "double"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "borderStyle",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              borderStyle: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.borderWidth,
      title: "Border Width",
      category: "Border",
      type: "number",
      units: ["px", "%"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "borderWidth",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              borderWidth: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.borderColor,
      title: "Border Color",
      category: "Border",
      type: "color",
      onChange: (v) => {
        setSelectedComponent({
          ...selectedComponent,
          mobileStyles: {
            ...selectedComponent.mobileStyles,
            borderColor: v,
          },
        });
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.letterSpacing,
      title: "Letter Spacing",
      category: "Spacing",
      type: "number",
      units: ["px", "%"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "letterSpacing",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              letterSpacing: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.wordSpacing,
      title: "Word Spacing",
      category: "Spacing",
      type: "number",
      units: ["px", "%"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "wordSpacing",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: { ...selectedComponent.mobileStyles, wordSpacing: v },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.textAlign,
      title: "Text Align",
      category: "Text Alignment",
      type: "select",
      options: ["center", "left", "right", "justify"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["mobileStyles", "textAlign"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: { ...selectedComponent.mobileStyles, textAlign: v },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.textDecorationStyle,
      title: "Text Decoration Style",
      options: ["solid", "double", "dotted", "dashed", "wavy"],
      category: "Text Decoration",
      type: "select",
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "textDecorationStyle",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              textDecorationStyle: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.textDecorationLine,
      title: "Text Decoration Line",
      options: ["overline", "underline"],
      category: "Text Decoration",
      type: "select",
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "textDecorationLine",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              textDecorationLine: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.textDecorationThickness,
      title: "Text Decoration Thickness",
      category: "Text Decoration",
      type: "number",
      units: ["px"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "textDecorationThickness",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: {
              ...selectedComponent.mobileStyles,
              textDecorationThickness: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.textDecorationColor,
      title: "Text Decoration Color",
      category: "Text Decoration",
      type: "color",
      onChange: (v) => {
        setSelectedComponent({
          ...selectedComponent,
          mobileStyles: {
            ...selectedComponent.mobileStyles,
            textDecorationColor: v,
          },
        });
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.fontWeight,
      title: "Font Weight",
      options: ["300", "400", "500", "600", "700", "800"],
      category: "Font Styles",
      type: "select",
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "fontWeight",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: { ...selectedComponent.mobileStyles, fontWeight: v },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.fontFamily,
      title: "Font Family",
      options: [
        "'Times New Roman', Times, serif",
        "Arial, sans-serif",
        "Helvetica, sans-serif",
        "'Lucida Console', 'Courier New', monospace",
        "'Courier New', monospace",
        "Georgia, serif",
        "'Palatino Linotype', 'Book Antiqua', Palatino, serif",
        "'Trebuchet MS', sans-serif",
        "'Verdana', Geneva, sans-serif",
        "'Garamond', Baskerville, 'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif",
        "'Century Gothic', sans-serif",
        "'Arial Black', Gadget, sans-serif",
        "'Impact', Charcoal, sans-serif",
        "'Comic Sans MS', cursive, sans-serif",
        "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        "cursive, 'Courier New', 'Times New Roman'",
      ],
      category: "Font Styles",
      type: "select",
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, [
              "mobileStyles",
              "fontFamily",
            ])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: { ...selectedComponent.mobileStyles, fontFamily: v },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.mobileStyles?.fontSize,
      title: "Font Size",
      category: "Size",
      type: "number",
      units: ["px", "%"],
      includeAuto: true,
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["mobileStyles", "fontSize"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            mobileStyles: { ...selectedComponent.mobileStyles, fontSize: v },
          });
        }
      },
    },
    {
      value: "Go to Parent",
      type: "plain",
      onClick: () => {
        if (selectedComponentPath.length > 1) {
          const newSelectedComponent = getValueAtPath(
            fileComponentsObject,
            selectedComponentPath.slice(0, -2)
          );
          setSelectedComponent(newSelectedComponent);
          setSelectedComponentId(newSelectedComponent.name);
          setSelectedComponentPath(selectedComponentPath.slice(0, -2));
        } else {
          console.log("no more parents");
        }
      },
    },
    {
      value: "Clone",
      type: "plain",
      onClick: () => {
        const pathToParent = [...selectedComponentPath].slice(0, -2);
        const selectedComponent = getValueAtPath(
          fileComponentsObject,
          selectedComponentPath
        );
        const cloneName = `${selectedComponent.name}clone${generateRandomId(
          2,
          []
        )}`;

        let newComponent = {
          ...getValueAtPath(fileComponentsObject, selectedComponentPath),
          name: cloneName,
          styles: {
            ...selectedComponent?.styles,
            top:
              separateNumberFromUnit(selectedComponent?.styles?.top)[0] +
              20 +
              "px",
          },
        };

        delete newComponent?.mobileStyles?.top;
        delete newComponent?.mobileStyles?.left;

        setFileComponentsObject(
          addNewObjectAtPath(
            fileComponentsObject,
            [...pathToParent, "nestedComponents", cloneName],
            newComponent
          )
        );
        setSelectedComponent(newComponent);
        setSelectedComponentPath([
          ...pathToParent,
          "nestedComponents",
          cloneName,
        ]);
        setSelectedComponentId(cloneName);
      },
    },
    {
      type: "plain",
      value: "copy",
      onClick: () => {
        setEditorClipBoard({
          ...cloneAndRename(selectedComponent),
          name: selectedComponent.name + "Clone" + generateRandomId(3, []),
        });
        setSelectedComponent({});
        setSelectedComponentId("");
        setSelectedComponentPath([]);
      },
    },
    {
      type: "plain",
      value: "delete",
      onClick: () => {
        setFileComponentsObject(
          deleteObjectAtPath(fileComponentsObject, [...selectedComponentPath])
        );
        console.log(
          deleteObjectAtPath(codeFile, ["components", ...selectedComponentPath])
        );
        setSelectedComponent({});
        setSelectedComponentId("");
        setSelectedComponentPath([]);
      },
    },
  ];
}

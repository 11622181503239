import {
  faArrowLeft,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/myLogo.png";
import { useState } from "react";

export const ComponentTester = ({}) => {
  return (
    <div>
      <SignUpStep3 subjects={[]} />
    </div>
  );
};

export const SignUpStep3 = ({
  features = [
    { name: "messages", displayName: "One Way Messanger" },
    { name: "attendanceAndBehavior", displayName: "Attendance And Behavior" },
    {
      name: "homeworksAndAssignments",
      displayName: "Homeworks And Assignments",
    },
    {
      name: "dailyAttendanceAndBehavior",
      displayName: "Daily Attendance And Behavior",
    },
    {
      name: "upcomingTests",
      displayName: "Upcoming Tests",
    },
    {
      name: "Test Results",
      displayName: "Test Results",
    },
  ],
  createAdminAccount,
  setPageState,
}) => {
  const [activeFeatures, setActuveFeatures] = useState({});

  function changeActiveFeatures(feature) {
    let myActiveFeatures = { ...activeFeatures };

    if (myActiveFeatures[feature]) {
      myActiveFeatures[feature] = !myActiveFeatures[feature];
    } else {
      myActiveFeatures[feature] = true;
    }

    setActuveFeatures(myActiveFeatures);
  }
  return (
    <div
      className="formsContainer console"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="formHeader">
        <div className="backButton" onClick={() => setPageState("step2")}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      <h3>Step3: Please Choose which empEd features would you like to use.</h3>
      <div style={{ width: "60%" }} className="form">
        {features.map((feature) => (
          <div style={{ borderBottom: "rgba(50, 50, 100, 0.6) solid 2px" }}>
            <label htmlFor="">{feature?.displayName}</label>
            <input
              onChange={(e) => {
                changeActiveFeatures(feature?.name);
              }}
              checked={activeFeatures[feature?.name]}
              style={{ marginBottom: "20px" }}
              type="checkbox"
              name=""
              id=""
            />
          </div>
        ))}
      </div>
      <button
        onClick={
          () =>
            console.log(
              activeFeatures
            ) /*  createAdminAccount(activeFeatures) */
        }
        className="button"
      >
        Done
      </button>
      <div className="paragraphContainer">
        <FontAwesomeIcon
          color="orange"
          fontSize={"25px"}
          icon={faTriangleExclamation}
        />
        <h3>
          Note: Make sure you add all your schools subjects as this is a very
          important step to help setup your account properly.
        </h3>
      </div>
    </div>
  );
};

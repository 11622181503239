export function updateObjectAtPath(obj, path, newValue) {
    // Make a copy of the original object to avoid mutation
    const updatedObject = { ...obj };
  
    // Traverse the object using the path
    let current = updatedObject;
    for (let i = 0; i < path.length - 1; i++) {
      const key = path[i];
      if (!current[key] || typeof current[key] !== 'object') {
        // If the path doesn't exist, create an empty object
        current[key] = {};
      }
      current = current[key];
    }
  
    // Update the value at the final key in the path
    const lastKey = path[path.length - 1];
    current[lastKey] = newValue;
  
    return updatedObject;
  }
  
/* 







*/


export  function addNewObjectAtPath(obj, path, newValue) {
    // Make a copy of the original object to avoid mutation
    const updatedObject = { ...obj };
  
    // Traverse the object using the path
    let current = updatedObject;
    for (let i = 0; i < path.length - 1; i++) {
      const key = path[i];
      if (!current[key] || typeof current[key] !== 'object') {
        // If the path doesn't exist, create an empty object
        current[key] = {};
      }
      current = current[key];
    }

    const lastKey = path[path.length - 1];

    // For in loop to modify the indexes of the siblings if they
    for (const objectKey in current){
      
        if(typeof current[objectKey].index === "number"){
            console.log(`${current[objectKey].index} `);
            if(current[objectKey].index >= newValue.index){
                current[objectKey].index = current[objectKey].index + 1
             
            }
        }
        
    }
    

    // Update the value at the final key in the path
   
    current[lastKey] = newValue;
  
    return updatedObject;
  }
  

  
   
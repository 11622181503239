import { Link } from "react-router-dom";

export const MyDropDown = ({ menuItems, active, setActive }) => {
  return (
    <div
      className="dropDown"
      onClick={() => setActive(false)}
      style={{
        position: "fixed",
        top: active ? "0" : "-2000px",
        width: "100%",
        height: "100%",
        /*  backgroundColor: active
          ? "rgb(255,255,255, .8)"
          : "rgb(255,255,255, .0)", */
        display: "block",
        zIndex: 20,
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          position: "absolute",
          top: "66px",
          transition: "top ease-out 0.8s",
          boxShadow: "0 3px 5px #333",
        }}
      >
        {menuItems.map((item) => (
          <div
            className="dropDownItem"
            onClick={
              item.onClick
                ? item.onClick
                : () => {
                    return;
                  }
            }
            style={{
              zIndex: 2,
              padding: "10px",
              margin: "0",
              borderBottom: "2px solid rgb(0,110,250)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                fontWeight: 700,
                color: "rgb(0,110,250)",
              }}
            >
              {item.route ? (
                <Link
                  style={{ color: "rgb(0,110,250)", textDecoration: "none" }}
                  to={item.route}
                >
                  {item.title}
                </Link>
              ) : (
                item.title
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

/* This dropDown component takes an array of objects as props, each object should contain 
 2 fields, "title" and "onClick", title is the text that will be displayed on the menu and 
  onClick is the function that will be called when the title is pressed*/

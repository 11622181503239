export const NewSubmissionIndicator = ({ style }) => {
  return (
    <div
      style={{
        backgroundColor: "red",
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        ...style,
      }}
    >
      New
    </div>
  );
};

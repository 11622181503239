import { useState } from "react";

const Item = ({ item }) => {
  const [hovering, setHovering] = useState(false);

  return (
    <li
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
      onClick={() => {
        item.onClick();
      }}
      style={{
        display: "flex",
        backgroundColor: "#fff",
        width: "90%",
        marginBottom: "6px",
        marginTop: "6px",
        padding: "5px",
        borderBottom: "#333 double 4px",
        cursor: hovering ? "pointer" : "default",
      }}
    >
      <span style={{ fontSize: "18px", fontWeight: hovering ? "700" : "400" }}>
        {item?.value}
      </span>
    </li>
  );
};

export const List = ({ items = [] }) => {
  return (
    <ul style={{ backgroundColor: "#fff", width: "80%", height: "65vh" }}>
      {items.map((item) => {
        return <Item item={item} />;
      })}
    </ul>
  );
};

import { collection, onSnapshot } from "firebase/firestore";
import { FormsSelector } from "../components/formSelector";
import { useEffect, useState } from "react";
import { db } from "../../../firebase-config";

export const AllForms = ({
  currentSchool,
  setSelectedFormId,
  setPageState,
}) => {
  const [forms, setForms] = useState([]);
  useEffect(() => {
    const currentForms = [];
    onSnapshot(
      collection(db, "schools", currentSchool.schoolId, "forms"),
      (snap) => {
        snap.forEach((docSnap) => {
          currentForms.push(docSnap.data());
        });
        setForms(currentForms);
      }
    );
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      <h1>Forms</h1>
      <FormsSelector
        setPageState={setPageState}
        setSelectedFormId={setSelectedFormId}
        forms={forms}
        currentSchool={currentSchool}
      />
    </div>
  );
};

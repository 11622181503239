import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { NewSubmissionIndicator } from "./newSubmissionIndicator";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase-config";
const FormField = ({ value, field }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "5px",
        margin: 0,
        padding: 0,
        borderBottom: "solid 2px #333",
      }}
    >
      <p style={{ fontSize: "20px", fontWeight: "800" }}>{field}</p> <p>:</p>
      <p style={{}}>{value}</p>
    </div>
  );
};

export const FormSubmission = ({
  submission,
  selectedSubmissionId,
  setSelectedSubmissionId,
  formFieldKeys,
  selectedFormId,
  mainFormKey,
  currentSchool,
}) => {
  const [currentSubmission, setCurrentSubmission] = useState({});

  useEffect(() => {
    setCurrentSubmission(submission);
  }, [submission]);
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "5px",
        width: "100%",
        position: "relative",
      }}
      className="SubmissionContaioner"
    >
      {currentSubmission.isSubmissionNew && (
        <NewSubmissionIndicator
      
          style={{ position: "absolute", right: "2px", top: "0px" }}
        />
      )}
      <div
        onClick={(e) => {
          const submissionDocRef = doc(
            db,
            "schools",
            currentSchool.schoolId,
            "forms",
            selectedFormId,
            "submissions",
            currentSubmission["Submission Id"]
          );
          setSelectedSubmissionId(currentSubmission["Submission Id"]);

          if (currentSubmission.isSubmissionNew) {
            updateDoc(submissionDocRef, { isSubmissionNew: false });
          }
        }}
        style={{
          backgroundColor: "papayawhip",
          padding: "3px",
          boxShadow: "2px 3px #333",
          marginBottom: "12px",
          display: "flex",
          flexDirection: "row",

          alignItems: "center",
        }}
      >
        <p style={{ flexGrow: 1, flexShrink: 0 }} className="submissionId">
          {currentSubmission[mainFormKey] || currentSubmission["Submission Id"]}
        </p>
        {selectedSubmissionId !== currentSubmission["Submission Id"] && (
          <FontAwesomeIcon size="30px" color="black" icon={faCaretUp} />
        )}
        {selectedSubmissionId === currentSubmission["Submission Id"] && (
          <FontAwesomeIcon size="30px" color="black" icon={faCaretDown} />
        )}
      </div>

      <div className="submission">
        {selectedSubmissionId === currentSubmission["Submission Id"] &&
          formFieldKeys.map((fieldKey) => {
            if (
              fieldKey !== "isSubmissionNew" &&
              fieldKey !== "Submission Id"
            ) {
              return (
                <FormField
                  field={fieldKey}
                  value={currentSubmission[fieldKey]}
                />
              );
            }
          })}
      </div>
    </div>
  );
};

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../firebase-config";
import { useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { MyLoader } from "../reausable Components/myLoader";
import logo from "../assets/myLogo.png";
import { QuestionNotification } from "../reausable Components/questionNotification";
import { generateRandomId } from "../helperFunctions/randomIdGenerator";

export const SignUpStep3 = ({
  features = [
    { name: "messages", displayName: "One Way Messanger" },
    { name: "attendanceAndBehavior", displayName: "Attendance And Behavior" },
    {
      name: "homeworksAndAssignments",
      displayName: "Homeworks And Assignments",
    },
    {
      name: "dailyAttendanceAndBehavior",
      displayName: "Daily Attendance And Behavior",
    },
    {
      name: "upcomingTests",
      displayName: "Upcoming Tests",
    },
    {
      name: "TestResults",
      displayName: "Test Results",
    },
  ],
  createAdminAccount,
  setPageState,
}) => {
  const [activeFeatures, setActuveFeatures] = useState({});

  function changeActiveFeatures(feature) {
    let myActiveFeatures = { ...activeFeatures };

    if (myActiveFeatures[feature]) {
      myActiveFeatures[feature] = !myActiveFeatures[feature];
    } else {
      myActiveFeatures[feature] = true;
    }

    setActuveFeatures(myActiveFeatures);
  }
  return (
    <div
      className="formsContainer console"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="formHeader">
        <div className="backButton" onClick={() => setPageState("step2")}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      <h3>Step3: Please Choose which empEd features would you like to use.</h3>
      <div style={{ width: "60%" }} className="form">
        {features.map((feature) => (
          <div style={{ borderBottom: "rgba(50, 50, 100, 0.6) solid 2px" }}>
            <label htmlFor="">{feature.displayName}</label>
            <input
              onChange={(e) => {
                changeActiveFeatures(feature.name);
              }}
              checked={activeFeatures[feature.name]}
              style={{ marginBottom: "20px" }}
              type="checkbox"
              name=""
              id=""
            />
          </div>
        ))}
      </div>
      <button
        onClick={() => createAdminAccount(activeFeatures)}
        className="button"
      >
        Done
      </button>
      <div className="paragraphContainer">
        <FontAwesomeIcon
          color="orange"
          fontSize={"25px"}
          icon={faTriangleExclamation}
        />
        <h3>
          Note: Make Take your time to look through our features, as this
          process cannot be reversed
        </h3>
      </div>
    </div>
  );
};

export const SignUpStep2 = ({
  subjects,
  setSubjects,
  createAdminAccount,
  setPageState,
}) => {
  const subjectsLength = subjects.length;

  function addNewSubject() {
    setSubjects([...subjects, ""]);
  }
  return (
    <div
      className="formsContainer console"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="formHeader">
        <div className="backButton" onClick={() => setPageState("step1")}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      <h3>Step2: Add all the schools subjects</h3>
      <div className="form">
        {subjects.map((subject, i) => (
          <div>
            {i + 1 === subjectsLength ? (
              <input
                type="text"
                onChange={(e) => {
                  const newSubjects = [...subjects];
                  newSubjects[i] = e.target.value;
                  setSubjects(newSubjects);
                }}
                value={subjects[i]}
              />
            ) : (
              subject
            )}
          </div>
        ))}
        <button onClick={addNewSubject}>Add</button>
      </div>
      <button onClick={() => setPageState("step3")} className="button">
        Next
      </button>
      <div className="paragraphContainer">
        <FontAwesomeIcon
          color="orange"
          fontSize={"25px"}
          icon={faTriangleExclamation}
        />
        <h3>
          Note: Make sure you add all your schools subjects as this is a very
          important step to help setup your account properly.
        </h3>
      </div>
    </div>
  );
};

export const BeforeSignUp = ({ setAppState }) => {
  return (
    <div className="formsContainer ">
      <div className="consoleHeader">
        <div className="backButton" onClick={() => setAppState("frontPage")}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      <h1>Before You Sign Up</h1>
      <div className="paragraphContainer">
        <FontAwesomeIcon
          color="orange"
          fontSize={"25px"}
          icon={faTriangleExclamation}
        />
        <h2>
          Plese Note, only schools can create their own accounts. So only press
          continue if you are a school leader, such as the principal of the
          school! Otherwize read the{" "}
          <span style={{ fontWeight: 800 }}>"For Parents and Teachers"</span>{" "}
          section bellow.
        </h2>
        <div style={{ display: "flex", gap: "5px" }}>
          <h5>
            You may recieve a phone call from our support team to verify your
            school. You will still have access to all services even before
            verification, however if we fail to verify your information your
            account will be suspended and your 'IP adrress' may get flagged.
          </h5>
        </div>
        <div style={{ display: "flex", gap: "6px" }}>
          <button onClick={() => setAppState("signup")} className="button2">
            Continue
          </button>
          <button onClick={() => setAppState("frontPage")} className="button2">
            Cancel
          </button>
        </div>
      </div>
      <div className="paragraphContainer">
        <h3>Sign Up and Empower Your Educational Journey</h3>
        <p>
          At EmpEd, we are dedicated to fostering strong partnerships between
          schools, parents, and teachers to create a seamless and enriched
          educational experience. Join us in this journey of empowerment by
          signing up today.
        </p>
        <h4>For Schools:</h4>
        <p>Unlock the Full Potential of EmpEd</p>
        <ul>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>Admin Console Access:</span> As
              a school, you gain access to our powerful Admin Console,
              absolutely free. With this console, you can efficiently manage and
              facilitate communication between teachers, parents, and students.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>Control and Convenience:</span>{" "}
              Schools have the unique ability to create and manage parent and
              teacher accounts effortlessly. Seamlessly integrate EmpEd into
              your educational ecosystem, ensuring transparency and engagement.
            </p>
          </li>
        </ul>
        <h4>For Parents and Teachers:</h4>
        <p>Don't Miss Out – Advocate for Your School</p>
        <ul>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>Empower Your School:</span>{" "}
              Parents and teachers, you hold the key to enhancing your school's
              communication and educational support. Advocate for EmpEd within
              your institution to unlock its full potential.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>
                User-Friendly Registration:
              </span>{" "}
              Once your school is registered, parents and teachers can easily
              create their accounts, gaining access to valuable features like
              Homework and Assignment Reports, One Way Messenger, Attendance &
              Behavior Tracking, and much more.
            </p>
          </li>
        </ul>
        <h4>How to Get Started:</h4>
        <ol>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>For Schools:</span> You can
              register your school on our website or contact our support team.
              We'll provide you with a free admin account. Schools don't pay a
              cent.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: 700 }}>For Parents and Teachers:</span>{" "}
              Speak to your school administration about the benefits of EmpEd.
              Encourage them to register and create accounts for parents and
              teachers, making educational communication more efficient and
              transparent.
            </p>
          </li>
        </ol>
        <h4>Join Us Today and Transform Education</h4>
        <p>
          EmpEd is more than just an app; it's a platform that empowers
          educational institutions, teachers, parents, and students. By signing
          up and embracing EmpEd, you're taking a significant step towards a
          more connected, informed, and supportive educational community.
        </p>
        <p>
          Start the conversation within your school, and let's work together to
          transform education. Join EmpEd today and experience the future of
          educational communication.
        </p>
      </div>
    </div>
  );
};

export const SignUp = ({
  loginInfo,
  setLoginInfo,
  setAppState,
  setCurrentSchool,
  setNotify,
  setLoading,
}) => {
  const [signupState, setSignupState] = useState("step1");
  const [subjects, setSujects] = useState([""]);
  /*  const [loading, setLoading] = useState(false); */
  function handleSchoolNameChange(e) {
    setLoginInfo({ ...loginInfo, schoolName: e.target.value });
  }
  function handlePrincipalNameChange(e) {
    setLoginInfo({ ...loginInfo, principalName: e.target.value });
  }
  function handleSchoolEmailChange(e) {
    setLoginInfo({ ...loginInfo, email: e.target.value });
  }
  function handleSchoolTelephoneChange(e) {
    setLoginInfo({ ...loginInfo, tell: e.target.value });
  }
  function handlePasswordChange(e) {
    setLoginInfo({ ...loginInfo, password: e.target.value });
  }

  async function createAdminAccount(activeFeatures) {
    setLoading(true);
    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        loginInfo.email,
        loginInfo.password
      );

      const newSchoolId = response.user.uid;
      const user = response.user;
      const userId = user.uid;
      const newSchoolRef = doc(db, "schools", newSchoolId);

      await Promise.all([
        setDoc(newSchoolRef, {
          ...loginInfo,
          specialId: userId,
          schoolId: newSchoolId,
          subjects: subjects.filter((subject) => subject !== ""),
          schoolPublicId: generateRandomId(20, []),
          activeFeatures: {
            ...activeFeatures,
          },
        }),

        setDoc(doc(db, "allUsers", newSchoolId), { accountType: "admin" }),
      ]);

      const response2 = await getDoc(newSchoolRef);
      setCurrentSchool(response2.data());
      setAppState("frontPage");

      setNotify({
        on: true,
        type: "notification",
        notification: "Admin Account created successfully",
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setNotify({
        on: true,
        type: "error",
        notification: "Something went wrong while creating your account",
        solution:
          "Please check your internet connection and try again, if the issue persist contact support",
      });
    }
  }
  return signupState === "step1" ? (
    <div className="formsContainer">
      {/*   {loading && <MyLoader />} */}
      <div className="formHeader">
        <div className="backButton" onClick={() => setAppState("frontPage")}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      <h1>Create New Admin Account</h1>
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          setSignupState("step2");
        }}
      >
        <div>
          <label>School Name: </label>
          <input
            required
            onChange={(e) => handleSchoolNameChange(e)}
            placeholder="Imaginary Primary School"
          />
        </div>
        <div>
          <label>Principal: </label>
          <input
            required
            onChange={(e) => handlePrincipalNameChange(e)}
            placeholder="Mr M.L. Masapho"
          />
        </div>
        <div>
          <label>School Telephone: </label>
          <input
            type="tel"
            required
            onChange={(e) => handleSchoolTelephoneChange(e)}
            placeholder="011 ** ** / +27 *** *** ****"
          />
        </div>
        <div>
          <label>School Email: </label>
          <input
            required
            onChange={(e) => handleSchoolEmailChange(e)}
            placeholder="imaginarySchool55@gmail.com"
          />
        </div>

        <div>
          <label>Password: </label>
          <input
            type="password"
            name="password"
            required
            onChange={(e) => handlePasswordChange(e)}
          />
        </div>
        <button className="button">Next Step</button>
      </form>
      <h4 style={{ color: "darkblue" }}>
        already have an account?{" "}
        <span
          onClick={() => {
            setAppState("login");
          }}
          className="textLink"
        >
          Log In To Your Account
        </span>{" "}
        !
      </h4>
    </div>
  ) : signupState === "step2" ? (
    <SignUpStep2
      setSubjects={setSujects}
      subjects={subjects}
      setPageState={setSignupState}
    />
  ) : (
    signupState === "step3" && (
      <SignUpStep3
        createAdminAccount={createAdminAccount}
        setPageState={setSignupState}
      />
    )
  );
};

export const Login = ({
  loginInfo,
  setLoginInfo,
  setAppState,
  setNotify,
  setLoading,
}) => {
  /* const [loading, setLoading] = useState(false); */

  function handleEmailChange(e) {
    setLoginInfo({ ...loginInfo, email: e.target.value });
  }

  function handlePasswordChange(e) {
    setLoginInfo({ ...loginInfo, password: e.target.value });
  }

  async function signIn() {
    try {
      setLoading(true);
      const response = await signInWithEmailAndPassword(
        auth,
        loginInfo.email,
        loginInfo.password
      );
      setAppState("frontPage");
      setNotify({
        on: true,
        type: "notification",
        notification: "Logged In successfully",
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setNotify({
        on: true,
        type: "error",
        notification: "Something went wrong when trying to log you in",
        solution:
          "Pease check your login info and internet connection and try again",
      });
    }
  }

  return (
    <div className="formsContainer">
      {/*  {loading && <MyLoader />} */}
      <div className="formHeader">
        <div className="backButton" onClick={() => setAppState("frontPage")}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      <h1>Login to admin account</h1>
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault(e);
          signIn();
        }}
      >
        <div>
          <label htmlFor="email">Email: </label>
          <input
            name="email"
            required
            onChange={(e) => {
              handleEmailChange(e);
            }}
            value={loginInfo.email}
          />
        </div>

        <div>
          <label htmlFor="password">Password: </label>
          <input
            type="password"
            name="password"
            required
            onChange={(e) => {
              handlePasswordChange(e);
            }}
            value={loginInfo.password}
          />
        </div>
        <button className="button2">Login</button>
        <button
          className="button2"
          type="button"
          onClick={() => setAppState("signup")}
        >
          Create New Account
        </button>
      </form>
    </div>
  );
};

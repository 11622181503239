import { Audio } from "react-loader-spinner";

export const MyLoader = () => {
  return (
    <div
      style={{
        position: "fixed",
        minWidth: "100%",
        minHeight: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 200,
        backgroundColor: "rgba(255,255,255, 45%)",
      }}
    >
      <Audio height="100" width="100" color="grey" ariaLabel="loading" />
    </div>
  );
};

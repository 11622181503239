import { generateRandomId } from "./randomIdGenerator";

export function getValueAtPath(obj, path) {
  // Ensure the input is valid
  if (!obj || !path || !Array.isArray(path)) {
    return undefined;
  }

  // Iterate through the path array to access nested properties
  let currentObj = obj;
  for (let key of path) {
    if (currentObj) {
      if (Array.isArray(currentObj) && !Number.isNaN(Number(key))) {
        // If the current object is an array and the key is a valid index
        key = Number(key);
        if (key < 0 || key >= currentObj.length) {
          return undefined; // Index out of bounds
        }
      } else if (currentObj.hasOwnProperty(key)) {
        currentObj = currentObj[key];
        continue;
      }
    }

    // Return undefined if the path is not valid
    return "invalid Path ";
  }

  return currentObj;
}

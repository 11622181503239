import { useEffect, useState } from "react";
import {
  getAllImagesUrlsFromStorage,
  getImageUrlFromStorage,
} from "../../../../firebase-config";

const ImageViewer = ({ image, setSelectedImage, selectedImage }) => {
  const [hovering, setHovering] = useState();
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    async function getImageUrl() {
      const url = await getImageUrlFromStorage(image?.fullPath);
      setImageUrl(url);
    }

    getImageUrl();
  }, []);
  return (
    <img
      onClick={() => {
        setSelectedImage(image);
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      src={imageUrl}
      alt=""
      style={{
        width: "100%",
        borderRadius: hovering ? "25px" : "5px",
        border:
          selectedImage?.name === image?.name
            ? "solid blue 2px"
            : hovering
            ? "solid steelblue 2px"
            : "solid lightblue 2px",
        padding: "5px",
        boxSizing: "border-box",
      }}
    />
  );
};

export const ImageSelector = ({
  onImageChoose,
  setFileSelectorActive,
  setImageSelector,
}) => {
  const [allImages, setAllImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  useEffect(() => {
    async function getImages() {
      let imageFiles = await getAllImagesUrlsFromStorage(
        `schools/De7vzAzZgiSYm8W2VPwljGoxb8h2/savedWebsiteTemplates/hellowrld/images`
      );

      setAllImages([...imageFiles]);
    }
    getImages();
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(255,255,255,0.6)",
        zIndex: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "110px",
      }}
    >
      <div
        style={{
          width: "70%",
          backgroundColor: "white",
          padding: "2px",
          border: "steelblue solid 2px",
          borderRadius: "12px",
          height: "650px",
          position: "relative",
        }}
      >
        <h2
          style={{
            width: "100%",
            borderBottom: "solid 1.8px #333",
            textAlign: "center",
            fontSize: "28px",
            color: "blue",
          }}
        >
          Choose An Image
        </h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, auto)",
            gridTemplateRows: "repeat(auto-fit, auto)",
            gap: "10px",
            height: "80%",
            overflow: "auto",
          }}
        >
          <div
            style={{
              border: "solid steelblue 2px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              onClick={() => {
                setFileSelectorActive(
                  (prevFileSelectorActive) => !prevFileSelectorActive
                );
                setImageSelector({ active: false });
              }}
              className="button"
            >
              Upload +
            </button>
          </div>
          {allImages?.map((image) => {
            return (
              <ImageViewer
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                image={image}
              />
            );
          })}
        </div>

        <button
          style={{ position: "absolute", bottom: "5px", left: "8px" }}
          onClick={() => {
            onImageChoose(selectedImage);
          }}
          className="button"
        >
          Choose
        </button>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";

const FormInput = ({ title, onChange, value, type }) => {
  return (
    <div
      style={{
        boxSizing: "border-box",
        borderBottom: "#000 1px solid",
        display: "flex",
        flexDirection: "column",
        gap: "1px",
        padding: "8px",
      }}
    >
      <label
        style={{ fontSize: "19px", fontWeight: "bold", marginBottom: "2px" }}
        htmlFor="elementId"
      >
        {title}:
      </label>
      <input
        value={value}
        onChange={onChange}
        style={{ width: "70%", border: "#333 solid 1px" }}
        type={type ? type : "text"}
        id="elementId"
      />
    </div>
  );
};

const FormSelect = ({ options, title, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(options[0]);

  useEffect(() => {
    onChange(selectedValue);
  }, [selectedValue]);

  function handleSelectOption(e) {
    setSelectedValue(e.target.value);
  }
  return (
    <div
      style={{
        boxSizing: "border-box",
        borderBottom: "#000 1px solid",
        display: "flex",
        flexDirection: "column",
        gap: "1px",
        padding: "8px",
      }}
    >
      <label
        style={{ fontSize: "19px", fontWeight: "bold", marginBottom: "2px" }}
        htmlFor="elementId"
      >
        {title}:
      </label>
      <select
        style={{ width: "71%" }}
        value={selectedValue}
        onChange={handleSelectOption}
      >
        {options?.map((option) => {
          return (
            <option style={{ maxWidth: "200px" }} value={option}>
              {option}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export const FloatingForm = ({
  inputs = [
    { type: "", value: "", title: "", onChange: () => {}, options: [] },
  ],
  onSubmit,
  zIndex,
  heading,
}) => {
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "absolute",
        backgroundColor: "rgba(255,255,255, 0.7)",
        display: "flex",
        top: "1px",
        bottom: "1px",
        left: "1px",
        right: "1px",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        zIndex: zIndex ? zIndex : 1,
      }}
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await onSubmit();
        }}
        style={{
          backgroundColor: "#fff",
          minWidth: "40%",
          padding: "10px",
          border: "lightblue 2px solid",
          borderRadius: "10px",
        }}
      >
        <h3>{heading}</h3>
        {inputs?.map((input) => {
          if (input.type === "text") {
            return (
              <FormInput
                onChange={input.onChange}
                value={input.value}
                title={input.title}
              />
            );
          } else if (input.type === "select") {
            return (
              <FormSelect
                onChange={input.onChange}
                title={input.title}
                options={input.options}
              />
            );
          } else if (input.type === "file") {
            return (
              <FormInput
                type={"file"}
                onChange={input.onChange}
                value={input.value}
                title={input.title}
              />
            );
          }
        })}
        <button className="button" type="submit">
          Done
        </button>
      </form>
    </div>
  );
};

import { getValueAtPath } from "../../../../helperFunctions/getValueAtPath";
import { TemplateButton } from "../domElements/templateButton";
import { TemplateComplexDiv } from "../domElements/templateComplexDiv";
import { TemplateDiv } from "../domElements/templateDiv";
import { TemplateH1 } from "../domElements/templateH1";
import { TemplateImage } from "../domElements/templateImg";
import { TemplateInput } from "../domElements/templateInput";
import { TemplateP } from "../domElements/templateP";
import { TemplateSection } from "../domElements/templateSection";
import { useEffect } from "react";
import { TemplateStrip } from "../domElements/templateStrip";
import { TemplateSlide } from "../domElements/templateSlide";
import { TemplateMenu } from "../domElements/templateMenu";
import { TemplateTextArea } from "../domElements/templateTextArea";
import { TemplateIcon } from "../domElements/templateIcon";

export const DynamicRecursiveComponentRenderer = ({
  components,
  setFileComponentsObject,
  fileComponentsObject,
  setSelectedComponent,
  selectedComponentId,
  setSelectedComponentId,
  selectedComponentPath,
  selectedComponent,
  setSelectedComponentPath,
  templateDomWidth,
  templateDomHeight,
  editorMode,
}) => {
  useEffect(() => {
    console.log(components);
  }, []);

  return (
    <>
      {components?.map((currentComponent) => {
        if (
          currentComponent.type === "text" ||
          currentComponent.type === "Link Text"
        ) {
          return (
            <TemplateP
              editorMode={editorMode}
              domWidth={templateDomWidth}
              domHeight={templateDomHeight}
              setSelectedComponentPath={setSelectedComponentPath}
              key={currentComponent.name}
              setSelectedComponentId={setSelectedComponentId}
              selectedComponentId={selectedComponentId}
              id={currentComponent.name}
              selectedComponent={selectedComponent}
              setFileComponentsObject={setFileComponentsObject}
              fileComponentsObject={fileComponentsObject}
              selectedComponentPath={[
                ...selectedComponentPath,
                currentComponent.name,
              ]}
              infoObject={
                getValueAtPath(fileComponentsObject, [
                  ...selectedComponentPath,
                  currentComponent.name,
                ]) !== undefined
                  ? getValueAtPath(fileComponentsObject, [
                      ...selectedComponentPath,
                      currentComponent.name,
                    ])
                  : { name: "no-name" }
              }
              setSelectedComponent={setSelectedComponent}
            />
          );
        } else if (currentComponent.type === "button") {
          return (
            <TemplateButton
              editorMode={editorMode}
              domWidth={templateDomWidth}
              domHeight={templateDomHeight}
              setSelectedComponentPath={setSelectedComponentPath}
              key={currentComponent.name}
              setSelectedComponentId={setSelectedComponentId}
              selectedComponentId={selectedComponentId}
              id={currentComponent.name}
              selectedComponent={selectedComponent}
              setFileComponentsObject={setFileComponentsObject}
              fileComponentsObject={fileComponentsObject}
              selectedComponentPath={[
                ...selectedComponentPath,
                currentComponent.name,
              ]}
              infoObject={getValueAtPath(fileComponentsObject, [
                ...selectedComponentPath,
                currentComponent.name,
              ])}
              setSelectedComponent={setSelectedComponent}
            />
          );
        } else if (currentComponent.type === "icon") {
          return (
            <TemplateIcon
              editorMode={editorMode}
              domWidth={templateDomWidth}
              domHeight={templateDomHeight}
              setSelectedComponentPath={setSelectedComponentPath}
              key={currentComponent.name}
              setSelectedComponentId={setSelectedComponentId}
              selectedComponentId={selectedComponentId}
              id={currentComponent.name}
              selectedComponent={selectedComponent}
              setFileComponentsObject={setFileComponentsObject}
              fileComponentsObject={fileComponentsObject}
              selectedComponentPath={[
                ...selectedComponentPath,
                currentComponent.name,
              ]}
              infoObject={getValueAtPath(fileComponentsObject, [
                ...selectedComponentPath,
                currentComponent.name,
              ])}
              setSelectedComponent={setSelectedComponent}
            />
          );
        } else if (
          currentComponent.type === "heading" ||
          currentComponent.type === "heading2" ||
          currentComponent.type === "heading3" ||
          currentComponent.type === "heading4"
        ) {
          return (
            <TemplateH1
              editorMode={editorMode}
              domWidth={templateDomWidth}
              domHeight={templateDomHeight}
              setSelectedComponentPath={setSelectedComponentPath}
              key={currentComponent.name}
              setSelectedComponentId={setSelectedComponentId}
              selectedComponentId={selectedComponentId}
              id={currentComponent.name}
              selectedComponent={selectedComponent}
              setFileComponentsObject={setFileComponentsObject}
              fileComponentsObject={fileComponentsObject}
              selectedComponentPath={[
                ...selectedComponentPath,
                currentComponent.name,
              ]}
              infoObject={getValueAtPath(fileComponentsObject, [
                ...selectedComponentPath,
                currentComponent.name,
              ])}
              setSelectedComponent={setSelectedComponent}
            />
          );
        } else if (currentComponent.type === "input") {
          return (
            <TemplateInput
              editorMode={editorMode}
              domWidth={templateDomWidth}
              domHeight={templateDomHeight}
              setSelectedComponentPath={setSelectedComponentPath}
              key={currentComponent.name}
              setSelectedComponentId={setSelectedComponentId}
              selectedComponentId={selectedComponentId}
              id={currentComponent.name}
              selectedComponent={selectedComponent}
              setFileComponentsObject={setFileComponentsObject}
              fileComponentsObject={fileComponentsObject}
              selectedComponentPath={[
                ...selectedComponentPath,
                currentComponent.name,
              ]}
              infoObject={getValueAtPath(fileComponentsObject, [
                ...selectedComponentPath,
                currentComponent.name,
              ])}
              setSelectedComponent={setSelectedComponent}
            />
          );
        } else if (currentComponent.type === "textArea") {
          return (
            <TemplateTextArea
              editorMode={editorMode}
              domWidth={templateDomWidth}
              domHeight={templateDomHeight}
              setSelectedComponentPath={setSelectedComponentPath}
              key={currentComponent.name}
              setSelectedComponentId={setSelectedComponentId}
              selectedComponentId={selectedComponentId}
              id={currentComponent.name}
              selectedComponent={selectedComponent}
              setFileComponentsObject={setFileComponentsObject}
              fileComponentsObject={fileComponentsObject}
              selectedComponentPath={[
                ...selectedComponentPath,
                currentComponent.name,
              ]}
              infoObject={getValueAtPath(fileComponentsObject, [
                ...selectedComponentPath,
                currentComponent.name,
              ])}
              setSelectedComponent={setSelectedComponent}
            />
          );
        } else if (currentComponent.type === "image") {
          return (
            <TemplateImage
              editorMode={editorMode}
              domWidth={templateDomWidth}
              domHeight={templateDomHeight}
              setSelectedComponentPath={setSelectedComponentPath}
              key={currentComponent.name}
              setSelectedComponentId={setSelectedComponentId}
              selectedComponentId={selectedComponentId}
              id={currentComponent.name}
              selectedComponent={selectedComponent}
              setFileComponentsObject={setFileComponentsObject}
              fileComponentsObject={fileComponentsObject}
              selectedComponentPath={[
                ...selectedComponentPath,
                currentComponent.name,
              ]}
              infoObject={getValueAtPath(fileComponentsObject, [
                ...selectedComponentPath,
                currentComponent.name,
              ])}
              setSelectedComponent={setSelectedComponent}
            />
          );
        } else if (
          currentComponent.type === "container" ||
          currentComponent.type === "relativeContainer"
        ) {
          return (
            <TemplateComplexDiv
              editorMode={editorMode}
              domWidth={templateDomWidth}
              domHeight={templateDomHeight}
              setSelectedComponentPath={setSelectedComponentPath}
              infoObject={
                getValueAtPath(fileComponentsObject, [
                  ...selectedComponentPath,
                  currentComponent.name,
                ]) !== undefined
                  ? getValueAtPath(fileComponentsObject, [
                      ...selectedComponentPath,
                      currentComponent.name,
                    ])
                  : {
                      name: "no-name",
                      fullObject: fileComponentsObject,
                      path: selectedComponentPath,
                    }
              }
              key={currentComponent.name}
              setSelectedComponentId={setSelectedComponentId}
              setSelectedComponent={setSelectedComponent}
              id={currentComponent.name}
              selectedComponentId={selectedComponentId}
              setFileComponentsObject={setFileComponentsObject}
              fileComponentsObject={fileComponentsObject}
              selectedComponentPath={[
                ...selectedComponentPath,
                currentComponent.name,
              ]}
              selectedComponent={selectedComponent}
            >
              <DynamicRecursiveComponentRenderer
                editorMode={editorMode}
                templateDomHeight={templateDomHeight}
                templateDomWidth={templateDomWidth}
                setSelectedComponentPath={setSelectedComponentPath}
                selectedComponent={selectedComponent}
                key={currentComponent.name + "-nested"}
                components={currentComponent.nestedComponents}
                setFileComponentsObject={setFileComponentsObject}
                fileComponentsObject={fileComponentsObject}
                setSelectedComponent={setSelectedComponent}
                selectedComponentId={selectedComponentId}
                setSelectedComponentId={setSelectedComponentId}
                selectedComponentPath={[
                  ...selectedComponentPath,
                  currentComponent.name,
                  "nestedComponents",
                ]}
              />
            </TemplateComplexDiv>
          );
        } else if (
          currentComponent.type === "box" ||
          currentComponent.type === "form"
        ) {
          return (
            <TemplateDiv
              editorMode={editorMode}
              domWidth={templateDomWidth}
              domHeight={templateDomHeight}
              setSelectedComponentPath={setSelectedComponentPath}
              infoObject={
                getValueAtPath(fileComponentsObject, [
                  ...selectedComponentPath,
                  currentComponent.name,
                ]) !== undefined
                  ? getValueAtPath(fileComponentsObject, [
                      ...selectedComponentPath,
                      currentComponent.name,
                    ])
                  : {
                      name: "no-name",
                      fullObject: fileComponentsObject,
                      path: selectedComponentPath,
                    }
              }
              key={currentComponent.name}
              setSelectedComponentId={setSelectedComponentId}
              setSelectedComponent={setSelectedComponent}
              id={currentComponent.name}
              selectedComponentId={selectedComponentId}
              setFileComponentsObject={setFileComponentsObject}
              fileComponentsObject={fileComponentsObject}
              selectedComponentPath={[
                ...selectedComponentPath,
                currentComponent.name,
              ]}
              selectedComponent={selectedComponent}
            >
              <DynamicRecursiveComponentRenderer
                editorMode={editorMode}
                templateDomHeight={templateDomHeight}
                templateDomWidth={templateDomWidth}
                setSelectedComponentPath={setSelectedComponentPath}
                selectedComponent={selectedComponent}
                key={currentComponent.name + "-nested"}
                components={currentComponent.nestedComponents}
                setFileComponentsObject={setFileComponentsObject}
                fileComponentsObject={fileComponentsObject}
                setSelectedComponent={setSelectedComponent}
                selectedComponentId={selectedComponentId}
                setSelectedComponentId={setSelectedComponentId}
                selectedComponentPath={[
                  ...selectedComponentPath,
                  currentComponent.name,
                  "nestedComponents",
                ]}
              />
            </TemplateDiv>
          );
        } else if (
          currentComponent.type === "strip" ||
          currentComponent.type === "menuItemsContainer"
        ) {
          return (
            <TemplateStrip
              editorMode={editorMode}
              domWidth={templateDomWidth}
              domHeight={templateDomHeight}
              setSelectedComponentPath={setSelectedComponentPath}
              infoObject={
                getValueAtPath(fileComponentsObject, [
                  ...selectedComponentPath,
                  currentComponent.name,
                ]) !== undefined
                  ? getValueAtPath(fileComponentsObject, [
                      ...selectedComponentPath,
                      currentComponent.name,
                    ])
                  : {
                      name: "no-name",
                      fullObject: fileComponentsObject,
                      path: selectedComponentPath,
                    }
              }
              key={currentComponent.name}
              setSelectedComponentId={setSelectedComponentId}
              setSelectedComponent={setSelectedComponent}
              id={currentComponent.name}
              selectedComponentId={selectedComponentId}
              setFileComponentsObject={setFileComponentsObject}
              fileComponentsObject={fileComponentsObject}
              selectedComponentPath={[
                ...selectedComponentPath,
                currentComponent.name,
              ]}
              selectedComponent={selectedComponent}
            >
              <DynamicRecursiveComponentRenderer
                editorMode={editorMode}
                templateDomHeight={templateDomHeight}
                templateDomWidth={templateDomWidth}
                setSelectedComponentPath={setSelectedComponentPath}
                selectedComponent={selectedComponent}
                key={currentComponent.name + "-nested"}
                components={currentComponent.nestedComponents}
                setFileComponentsObject={setFileComponentsObject}
                fileComponentsObject={fileComponentsObject}
                setSelectedComponent={setSelectedComponent}
                selectedComponentId={selectedComponentId}
                setSelectedComponentId={setSelectedComponentId}
                selectedComponentPath={[
                  ...selectedComponentPath,
                  currentComponent.name,
                  "nestedComponents",
                ]}
              />
            </TemplateStrip>
          );
        } else if (currentComponent.type === "slide") {
          return (
            <TemplateSlide
              editorMode={editorMode}
              domWidth={templateDomWidth}
              domHeight={templateDomHeight}
              setSelectedComponentPath={setSelectedComponentPath}
              infoObject={
                getValueAtPath(fileComponentsObject, [
                  ...selectedComponentPath,
                  currentComponent.name,
                ]) !== undefined
                  ? getValueAtPath(fileComponentsObject, [
                      ...selectedComponentPath,
                      currentComponent.name,
                    ])
                  : {
                      name: "no-name",
                      fullObject: fileComponentsObject,
                      path: selectedComponentPath,
                    }
              }
              key={currentComponent.name}
              setSelectedComponentId={setSelectedComponentId}
              setSelectedComponent={setSelectedComponent}
              id={currentComponent.name}
              selectedComponentId={selectedComponentId}
              setFileComponentsObject={setFileComponentsObject}
              fileComponentsObject={fileComponentsObject}
              selectedComponentPath={[
                ...selectedComponentPath,
                currentComponent.name,
              ]}
              selectedComponent={selectedComponent}
            >
              <DynamicRecursiveComponentRenderer
                editorMode={editorMode}
                templateDomHeight={templateDomHeight}
                templateDomWidth={templateDomWidth}
                setSelectedComponentPath={setSelectedComponentPath}
                selectedComponent={selectedComponent}
                key={currentComponent.name + "-nested"}
                components={currentComponent.nestedComponents}
                setFileComponentsObject={setFileComponentsObject}
                fileComponentsObject={fileComponentsObject}
                setSelectedComponent={setSelectedComponent}
                selectedComponentId={selectedComponentId}
                setSelectedComponentId={setSelectedComponentId}
                selectedComponentPath={[
                  ...selectedComponentPath,
                  currentComponent.name,
                  "nestedComponents",
                ]}
              />
            </TemplateSlide>
          );
        } else if (currentComponent.type === "menu") {
          return (
            <TemplateMenu
              editorMode={editorMode}
              domWidth={templateDomWidth}
              domHeight={templateDomHeight}
              setSelectedComponentPath={setSelectedComponentPath}
              infoObject={
                getValueAtPath(fileComponentsObject, [
                  ...selectedComponentPath,
                  currentComponent.name,
                ]) !== undefined
                  ? getValueAtPath(fileComponentsObject, [
                      ...selectedComponentPath,
                      currentComponent.name,
                    ])
                  : {
                      name: "no-name",
                      fullObject: fileComponentsObject,
                      path: selectedComponentPath,
                    }
              }
              key={currentComponent.name}
              setSelectedComponentId={setSelectedComponentId}
              setSelectedComponent={setSelectedComponent}
              id={currentComponent.name}
              selectedComponentId={selectedComponentId}
              setFileComponentsObject={setFileComponentsObject}
              fileComponentsObject={fileComponentsObject}
              selectedComponentPath={[
                ...selectedComponentPath,
                currentComponent.name,
              ]}
              selectedComponent={selectedComponent}
            >
              <DynamicRecursiveComponentRenderer
                editorMode={editorMode}
                templateDomHeight={templateDomHeight}
                templateDomWidth={templateDomWidth}
                setSelectedComponentPath={setSelectedComponentPath}
                selectedComponent={selectedComponent}
                key={currentComponent.name + "-nested"}
                components={currentComponent.nestedComponents}
                setFileComponentsObject={setFileComponentsObject}
                fileComponentsObject={fileComponentsObject}
                setSelectedComponent={setSelectedComponent}
                selectedComponentId={selectedComponentId}
                setSelectedComponentId={setSelectedComponentId}
                selectedComponentPath={[
                  ...selectedComponentPath,
                  currentComponent.name,
                  "nestedComponents",
                ]}
              />
            </TemplateMenu>
          );
        } else if (currentComponent.type === "section") {
          return (
            <TemplateSection
              editorMode={editorMode}
              setSelectedComponentPath={setSelectedComponentPath}
              infoObject={getValueAtPath(fileComponentsObject, [
                ...selectedComponentPath,
                currentComponent.name,
              ])}
              key={currentComponent.name}
              setSelectedComponentId={setSelectedComponentId}
              setSelectedComponent={setSelectedComponent}
              id={currentComponent.name}
              selectedComponentId={selectedComponentId}
              setFileComponentsObject={setFileComponentsObject}
              fileComponentsObject={fileComponentsObject}
              selectedComponentPath={[
                ...selectedComponentPath,
                currentComponent.name,
              ]}
              selectedComponent={selectedComponent}
            >
              <DynamicRecursiveComponentRenderer
                editorMode={editorMode}
                templateDomHeight={templateDomHeight}
                templateDomWidth={templateDomWidth}
                setSelectedComponentPath={setSelectedComponentPath}
                selectedComponent={selectedComponent}
                key={currentComponent.name + "-nested"}
                components={currentComponent.nestedComponents}
                setFileComponentsObject={setFileComponentsObject}
                fileComponentsObject={fileComponentsObject}
                setSelectedComponent={setSelectedComponent}
                selectedComponentId={selectedComponentId}
                setSelectedComponentId={setSelectedComponentId}
                selectedComponentPath={[
                  ...selectedComponentPath,
                  currentComponent.name,
                  "nestedComponents",
                ]}
              />
            </TemplateSection>
          );
        }
      })}
    </>
  );
};

import { useEffect, useState } from "react";
import {
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import {
  db,
  auth,
  changeParentAccountToHybrid,
  getUserBasicInfo,
  adminCreateAccountWithEmailAndPassword,
  deleteClassroom,
  deleteStudent,
} from "../../firebase-config";

import { fetchSignInMethodsForEmail } from "firebase/auth";
import { NewStudentForm } from "./pages/newStudentForm";
import { Classrooms } from "./pages/classRooms";
import { Students } from "./pages/students";
import "../../css/console.css";
import "../../css/App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/myLogo.png";
import { QuestionNotification } from "../../reausable Components/questionNotification";
import { StudentDisplayer } from "../studentDisplayer";

const ClassCreator = ({
  setClassRooms,
  setPageState,
  currentSchool,
  setLoading,
  setNotify,
  onGoBack,
}) => {
  const [newClassRoom, setNewClassRoom] = useState({
    className: "",
    teacherSurname: "",
    teacherTitle: "",
    teacherEmail: "",
    teacherPassword: "",
    grade: null,
  });
  const emptyClassRoom = {
    grade: null,
    className: "",
    teacherSurname: "",
    teacherTitle: "",
    studentCount: "",
  };

  function handleTeacherSurnameChange(e) {
    setNewClassRoom({ ...newClassRoom, teacherSurname: e.target.value });
  }
  function handleClassNameChange(e) {
    setNewClassRoom({ ...newClassRoom, className: e.target.value });
  }
  function handleGradeChange(e) {
    setNewClassRoom({ ...newClassRoom, grade: e.target.value });
  }

  function handleTeacherTitleChange(e) {
    setNewClassRoom({ ...newClassRoom, teacherTitle: e.target.value });
  }
  function handleTeacherPasswordChange(e) {
    setNewClassRoom({ ...newClassRoom, teacherPassword: e.target.value });
  }
  function handleTeacherEmailChange(e) {
    setNewClassRoom({ ...newClassRoom, teacherEmail: e.target.value });
  }

  async function createClassRoom() {
    const classRoomsRef = collection(
      db,
      "schools",
      currentSchool.schoolId,
      "classrooms"
    );
    const classRoomRef = doc(
      db,
      "schools",
      currentSchool.schoolId,
      "classrooms",
      newClassRoom.className.toUpperCase()
    );
    try {
      setLoading(true);
      const singInMethods = await fetchSignInMethodsForEmail(
        auth,
        newClassRoom.teacherEmail
      );
      /* checking if the teachers account exists somewhere on my system
       before attempting to create it*/
      if (singInMethods.length === 0) {
        console.log("account does not exist");
        console.log("creating new teacher account");

        await setDoc(classRoomRef, {
          className: newClassRoom.className.toUpperCase(),
          classTeacher: `${newClassRoom.teacherTitle} ${newClassRoom.teacherSurname}`,
          grade: newClassRoom.grade,
        });
        const teacherInfo = await adminCreateAccountWithEmailAndPassword({
          email: newClassRoom.teacherEmail,
          password: newClassRoom.teacherPassword,
        });

        const teacherUserId = teacherInfo.data.userId;
        const newTeacherRef = doc(
          db,
          "schools",
          currentSchool.schoolId,
          "users",
          teacherUserId
        );

        const newUserRef = doc(db, "allUsers", teacherUserId);
        await setDoc(newUserRef, {
          accountType: "teacher",
          schoolId: currentSchool.schoolId,
          teacherFullName: `${newClassRoom.teacherTitle} ${newClassRoom.teacherSurname}`,
        });

        await setDoc(newTeacherRef, {
          accountType: "teacher",
          class: newClassRoom.className.toUpperCase(),
          email: newClassRoom.teacherEmail,
          password: newClassRoom.teacherPassword,
          surname: newClassRoom.teacherSurname,
          title: newClassRoom.teacherTitle,
          subjects: currentSchool.subjects,
          classGrade: newClassRoom.grade,
        });

        setNewClassRoom(emptyClassRoom);
        setPageState("initial");
        setLoading(false);
        setNotify({
          on: true,
          type: "notification",
          notification: "New class room successfully created",
        });
      } else {
        setLoading(true);
        console.log("account already exists");
        console.log("Attempting to change parent account to hybrid");
        const userBasicInfo = await getUserBasicInfo({
          email: newClassRoom.teacherEmail,
        });
        /* if the account already exists I need no make sure It does not belong to
         a teacher as you cant be a teacher at two schools at once */
        if (
          userBasicInfo.data.accountType === "teacher" ||
          userBasicInfo.data.accountType === "hybrid"
        ) {
          setNotify({
            on: true,
            type: "error",
            notification:
              "It would seem this account already belongs to a teacher",
            solution:
              "Please try again with a different email, and if the issue persists contact support",
          });
          throw new Error(
            "An error occured, It would seem this account already belongs to a Teacher"
          );
        } else {
          /* if the account does not belong to a teacher, it belongs
          to a parent, so I can change it to hybrid and add the teacher
          cridentials */
          const serverResponse = await changeParentAccountToHybrid({
            email: newClassRoom.teacherEmail,
            schoolId: currentSchool.schoolId,
            class: newClassRoom.className.toUpperCase(),
            surname: newClassRoom.teacherSurname,
            title: newClassRoom.teacherTitle,
            subjects: currentSchool.subjects,
            classGrade: newClassRoom.grade,
          });
          console.log(serverResponse);
          setPageState("initial");
          setLoading(false);
          setNotify({
            on: true,
            type: "notification",
            notification: "New class room successfully created",
          });
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  function handleBackPress() {
    /*  setPageState("initial"); */
    onGoBack();
  }

  return (
    <div className="console">
      <div className="consoleHeader">
        <div className="backButton" onClick={handleBackPress}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>
        <img alt="logo" src={logo} className="consoleLogo" />
      </div>

      <div className="formsContainer">
        <h1 style={{ marginTop: "0" }}>Create New Classroom</h1>
        <form
          className="form"
          onSubmit={(e) => {
            e.preventDefault();
            createClassRoom();
          }}
        >
          <div>
            <label>Grade: </label>
            <input
              type="number"
              required
              onChange={(e) => {
                handleGradeChange(e);
              }}
              value={newClassRoom.grade}
              placeholder="7"
            />

            <label>Class Name: </label>
            <input
              required
              onChange={(e) => {
                handleClassNameChange(e);
              }}
              value={newClassRoom.className}
              placeholder="7A"
            />
          </div>
          <div>
            <label>Class Teacher Surname: </label>
            <input
              required
              onChange={(e) => {
                handleTeacherSurnameChange(e);
              }}
              value={newClassRoom.teacherSurname}
              placeholder="Latha"
            />
          </div>
          <div>
            <label>Class Teacher Title: </label>
            <input
              required
              onChange={(e) => {
                handleTeacherTitleChange(e);
              }}
              value={newClassRoom.teacherTitle}
              placeholder="Mr"
            />
          </div>
          <div>
            <label>Class Teacher Email: </label>
            <input
              required
              onChange={(e) => {
                handleTeacherEmailChange(e);
              }}
              value={newClassRoom.teacherEmail}
              placeholder="fakeEmail@gmail.com"
            />
          </div>
          <div>
            <label>Class Teacher Password: </label>
            <input
              type="text"
              required
              onChange={(e) => {
                handleTeacherPasswordChange(e);
              }}
              value={newClassRoom.teacherPassword}
            />
          </div>
          <button className="button">Add ClassRoom</button>
        </form>
      </div>
    </div>
  );
};

export function AdminConsole({
  setCurrentStudent,
  currentStudent,
  setAppState,
  emptyObject,
  currentSchool,
  setLoading,
  setNotify,
}) {
  const [classrooms, setClassRooms] = useState([]);
  const [pageState, setPageState] = useState("initial");
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [questionNotify, setQuestionNotify] = useState({
    on: false,
    question: "",
    onAccept: undefined,
    onDecline: undefined,
    data: null,
  });

  useEffect(() => {
    const classRoomsRef = collection(
      db,
      "schools",
      currentSchool.schoolId,
      "classrooms"
    );
    let unsubscribe;
    try {
      setLoading(true);
      unsubscribe = onSnapshot(classRoomsRef, (snapshot) => {
        const arr = [];
        snapshot.forEach((docSnap) => {
          arr.push({ ...docSnap.data(), docId: docSnap.id });
        });
        setClassRooms(arr);
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }

    return () => {
      unsubscribe();
    };
  }, [currentStudent, currentSchool, setLoading]);

  useEffect(() => {
    const studentsRef = query(
      collection(db, "schools", currentSchool.schoolId, "users"),
      where("class", "==", currentStudent.class)
    );

    onSnapshot(studentsRef, (snapshot) => {
      const arr = [];
      snapshot.forEach((docSnap) => {
        arr.push({ ...docSnap.data(), docId: docSnap.id });
      });
      const filteredArr = arr.filter((student) => {
        if (student.accountType === "teacher") {
          return false;
        } else {
          return true;
        }
      });
      setStudents(filteredArr);
    });
  }, [currentStudent, currentSchool]);

  function handleClassRoomSelect(classRoom) {
    setCurrentStudent({
      ...currentStudent,
      class: classRoom.className,
      classGrade: classRoom.grade,
    });
    setPageState("students");
  }

  async function handleClassRoomDelete(classRoom) {
    try {
      setLoading(true);
      await deleteClassroom({
        classRoom,
        schoolId: currentSchool.schoolId,
      });
      setLoading(false);
      setNotify({
        on: true,
        type: "notification",
        notification: "Classroom successfully deleted",
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
      setNotify({
        on: true,
        type: "notification",
        notification:
          "Something went wrong while trying to delete this classroom",
        solution: "Please contact support about this issue immediately",
      });
    }
  }

  async function handleStudentDelete(student) {
    try {
      setLoading(true);
      await deleteStudent({ student, childId: student.docId });
      setLoading(false);
      setNotify({
        on: true,
        type: "notification",
        notification: "Student successfully deleted",
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
      setNotify({
        on: true,
        type: "notification",
        notification:
          "Something went wrong while trying to delete this student",
        solution: "Please contact support about this issue immediately",
      });
    }
  }

  function logOut() {
    auth.signOut();
    setAppState("login");
  }

  return (
    <div style={{ width: "100%" }}>
      {questionNotify.on && (
        <QuestionNotification
          data={questionNotify.data}
          onDecline={questionNotify.onDecline}
          onAccept={questionNotify.onAccept}
          question={questionNotify.question}
        />
      )}
      {pageState === "initial" && (
        <Classrooms
          onGoBack={() => {
            setAppState("frontPage");
          }}
          currentSchool={currentSchool}
          setAppState={setAppState}
          setPageState={setPageState}
          handleClassRoomSelect={handleClassRoomSelect}
          handleClassRoomDelete={(classRoom) => {
            setQuestionNotify({
              on: true,
              data: classRoom,
              question: `Are you sure you want to delete this classRoom and the teacher account associated with it? None of the students will be deleted and as soon as the  ${classRoom.className} class is re-created all the students will be automatically added.`,
              onAccept: () => {
                setQuestionNotify({ ...questionNotify, on: false });
                handleClassRoomDelete(classRoom);
              },
              onDecline: () => {
                setQuestionNotify({ on: false });
              },
            });
          }}
          classrooms={classrooms}
        />
      )}
      {pageState === "classCreator" && (
        <ClassCreator
          onGoBack={() => {
            setPageState("initial");
          }}
          setNotify={setNotify}
          setLoading={setLoading}
          currentSchool={currentSchool}
          setPageState={setPageState}
          setClassRooms={setClassRooms}
        />
      )}
      {pageState === "students" && (
        <Students
          onGoBack={() => {
            setPageState("initial");
          }}
          onStudentSelect={(student) => {
            setSelectedStudent(student);
            setPageState("studentViewer");
          }}
          handleStudentDelete={(student) => {
            setQuestionNotify({
              on: true,
              data: student,
              question: `Are you sure you want to delete this student? This action can not be reversed`,
              onAccept: () => {
                setQuestionNotify({ ...questionNotify, on: false });
                handleStudentDelete(student);
              },
              onDecline: () => {
                setQuestionNotify({ on: false });
              },
            });
          }}
          setLoading={setLoading}
          currentSchool={currentSchool}
          setStudents={setStudents}
          students={students}
          currentStudent={currentStudent}
          setPageState={setPageState}
        />
      )}
      {pageState === "classEditor" && (
        <NewStudentForm
          setNotify={setNotify}
          setLoading={setLoading}
          setPageState={setPageState}
          setAppState={setAppState}
          currentSchool={currentSchool}
          emptyObject={emptyObject}
          currentStudent={currentStudent}
          setCurrentStudent={setCurrentStudent}
        />
      )}
      {pageState === "studentViewer" && (
        <StudentDisplayer
          onBackPress={() => setPageState("students")}
          selectedStudent={selectedStudent}
        />
      )}
    </div>
  );
}

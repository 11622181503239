import { generateRandomId } from "./randomIdGenerator";

export function cloneAndRename(myObj) {
  // Function to deep clone an object
  function deepClone(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }
    let clonedObj = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        clonedObj[key] = deepClone(obj[key]); // Recursively deep clone nested objects
      }
    }
    return clonedObj;
  }

  // Deep clone the input object
  let obj = deepClone(myObj);

  // Check if the input is an object
  if (typeof obj !== "object" || obj === null) {
    return;
  }

  // Recursively iterate over nestedComponents and update keys and name properties
  function updateKeysAndNames(obj) {
    if (obj.hasOwnProperty("nestedComponents")) {
      for (let key in obj.nestedComponents) {
        if (obj.nestedComponents.hasOwnProperty(key)) {
          // Update the key
          let updatedKey = key + "Clone" + generateRandomId(3, []);
          // Recursively call the function for nested objects
          updateKeysAndNames(obj.nestedComponents[key]);
          // Update the name property after appending "clone" to avoid duplicate
          if (obj.nestedComponents[key].hasOwnProperty("name")) {
            obj.nestedComponents[key].name = updatedKey;
          }
          // Remove the old key and add the updated one
          Object.defineProperty(
            obj.nestedComponents,
            updatedKey,
            Object.getOwnPropertyDescriptor(obj.nestedComponents, key)
          );
          delete obj.nestedComponents[key];
        }
      }
    }
  }

  updateKeysAndNames(obj); // Call the recursive function to update keys and names
  console.log(obj);
  return obj;
}

export function returnHeading3(name) {
  return {
    index: "5",
    value: "Heading 3",
    active: true,
    name: name,
    type: "heading3",
    mobileStyles: {},

    styles: {
      fontSize: "18.72px",
      left: "10px",
      position: "absolute",
      top: "10px",
      color: "#000",
      fontWeight: "800",
      alignSelf: "center",
      padding: "0px",
      margin: "0px",
    },
  };
}

export const heading3 = {
  category: "Text",
  index: "5",
  value: "Heading 3",
  active: true,
  name: "heading3",
  type: "heading3",
  validParents: ["form", "section", "box", "container", "relativeContainer"],
  styles: {
    fontSize: "18.72px",
    fontWeight: "800",
    position: "relative",
    color: "#000",
    margin: "auto",
  },
};

import { useEffect, useState } from "react";
import { WebsiteTemplateSelector } from "./pages/webTempSelector";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase-config";
import { WebPagesEditor } from "./pages/webPagesEditor";

export const WebsiteMananger = ({ currentSchool, setAppState, setLoading }) => {
  const [templates, setTemplates] = useState([{}]);
  const [savedTemplates, setSavedTemplates] = useState([{}]);

  const [pageState, setPageState] = useState("templateSelector");

  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [isTemplateSaved, setIsTemplateSaved] = useState(false);

  useEffect(() => {
    const templatesRef = collection(db, "websiteTemplates");
    const savedTemplatesRef = collection(
      db,
      "schools",
      currentSchool.schoolId,
      "savedTemplates"
    );

    if (currentSchool.savedTemplates) {
      console.log("saved");
      const savedTempUnsub = onSnapshot(savedTemplatesRef, (querySnap) => {
        const savedTemplates = [];
        querySnap.forEach((doc) => {
          savedTemplates.push({ ...doc.data(), id: doc.id });
        });
        setSavedTemplates(savedTemplates);
      });

      const tempUnsub = onSnapshot(templatesRef, (querySnap) => {
        const templates = [];
        querySnap.forEach((doc) => {
          templates.push({ ...doc.data(), id: doc.id });
        });
        setTemplates(templates);
      });

      return () => {
        savedTempUnsub();
        tempUnsub();
      };
    } else {
      console.log("not saved");
      const tempUnsub = onSnapshot(templatesRef, (querySnap) => {
        const templates = [];
        querySnap.forEach((doc) => {
          templates.push({ ...doc.data(), id: doc.id });
        });
        setTemplates(templates);
      });

      return () => {
        tempUnsub();
      };
    }
  }, [currentSchool]);

  useEffect(() => {
    console.log(templates);
  }, [templates]);
  return (
    <div style={{}}>
      {pageState === "templateSelector" && (
        <WebsiteTemplateSelector
          isTemplateSaved={isTemplateSaved}
          setIsTemplateSaved={setIsTemplateSaved}
          onGoBack={() => {
            setAppState("frontPage");
          }}
          onClick={(template) => {
            setSelectedTemplate(template);
            setPageState("webPageEditor");
          }}
          savedTemplates={savedTemplates}
          templates={templates}
          currentSchool={currentSchool}
        />
      )}

      {pageState === "webPageEditor" && (
        <WebPagesEditor
          setIsTemplateSaved={setIsTemplateSaved}
          isTemplateSaved={isTemplateSaved}
          setLoading={setLoading}
          onGoBack={() => {
            setPageState("templateSelector");
          }}
          currentSchool={currentSchool}
          template={selectedTemplate}
        />
      )}
    </div>
  );
};

export function arrayToObject(arr = []) {
    let newObj = {};
  
    for (const obje of arr) {
      let newObjProperties = { ...obje }; // Copy all properties from the current object
  
      if (obje.nestedComponents1) {
        let newNestedObj1 = {};
  
        for (const nestedObje1 of obje.nestedComponents1) {
          let newNestedObjProperties1 = { ...nestedObje1 }; // Copy all properties from the nested object
  
          if (nestedObje1.nestedComponents2) {
            let newNestedObj2 = {};
  
            for (const nestedObje2 of nestedObje1.nestedComponents2) {
              let newNestedObjProperties2 = { ...nestedObje2 }; // Copy all properties from the nested object
  
              newNestedObj2 = {
                ...newNestedObj2,
                [nestedObje2.name]: newNestedObjProperties2,
              };
            }
  
            newNestedObjProperties1.nestedComponents2 = newNestedObj2;
          }
  
          newNestedObj1 = {
            ...newNestedObj1,
            [nestedObje1.name]: newNestedObjProperties1,
          };
        }
  
        newObjProperties.nestedComponents1 = newNestedObj1;
      }
  
      newObj = {
        ...newObj,
        [obje.name]: newObjProperties,
      };
    }
  
    return newObj;
  }
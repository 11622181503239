import { setDoc, doc } from "firebase/firestore";
import { getStudents } from "../../../helperFunctions/dataFetcher";
import { useEffect, useState } from "react";
import {
  addChildToExistingParent,
  adminCreateAccountWithEmailAndPassword,
  auth,
  changeTeacherAccountToHybrid,
  db,
  getUserBasicInfo,
} from "../../../firebase-config";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { generateRandomId } from "../../../helperFunctions/randomIdGenerator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "../../../css/forms.css";
import logo from "../../../assets/myLogo.png";

const VerifyAccountOwnership = ({
  userInfo,
  currentStudent,
  currentSchool,
  setLoading,
  setPageState,
  setNotify,
}) => {
  return (
    <div
      style={{
        zIndex: 3,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255,255,255, 0.7)",
      }}
      className="formsContainer"
    >
      <div className="form">
        {userInfo.data.accountType === "teacher" && (
          <h4>{`This account already exists and belongs to ${userInfo.data.teacherFullName}`}</h4>
        )}
        {userInfo.data.accountType !== "teacher" && (
          <h4>{`This account already exists and Belongs to
           ${userInfo.data?.parentName} ${userInfo.data?.parentSurname}`}</h4>
        )}
        <p>{`Please Press Continue to add ${currentStudent?.childName} to this account`}</p>
        <button
          onClick={async () => {
            try {
              setLoading(true);
              const statuss = await addChildToExistingParent({
                email: currentStudent?.parentEmail,
                childName: currentStudent?.childName,
                schoolId: currentSchool?.schoolId,
                principalName: currentSchool?.principalName,
                schoolName: currentSchool?.schoolName,
                surname: currentStudent?.surname,
                parentName: currentStudent?.parentName,
                class: currentStudent?.class,
                classGrade: currentStudent.classGrade,
              });
              console.log(statuss);
              setLoading(false);
              setPageState("students");
              setNotify({
                on: true,
                type: "notification",
                notification:
                  "Student successfully registered and added to class room",
              });
            } catch (error) {
              console.error(error);
              setNotify({
                on: true,
                type: "error",
                notification:
                  "An unknown error occured during the account creation proccess",
                solution:
                  "Please check your internet connection and try again. If the issue persists please 'Contact Support'.",
              });
            }
          }}
          className="button"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export function NewStudentForm({
  setCurrentStudent,
  currentStudent,
  emptyObject,
  setPageState,
  currentSchool,
  setLoading,
  setNotify,
}) {
  const [students, setStudents] = useState([]);
  const [veify, setVerify] = useState({ active: false, message: undefined });
  const [userBasicInfo, setUserBasicInfo] = useState({});
  const newChildId = generateRandomId();
  useEffect(() => {
    getStudents(currentStudent, setStudents, currentSchool);
  }, [currentSchool, currentStudent]);

  function handleStudentNameChange(e) {
    setCurrentStudent({ ...currentStudent, childName: e.target.value });
  }
  function handleStudentSurnameChange(e) {
    setCurrentStudent({ ...currentStudent, surname: e.target.value });
  }

  function handleStudentPasswordChange(e) {
    setCurrentStudent({ ...currentStudent, password: e.target.value });
  }
  function handleParentNameChange(e) {
    setCurrentStudent({ ...currentStudent, parentName: e.target.value });
  }

  function handleParentEmailChange(e) {
    setCurrentStudent({ ...currentStudent, parentEmail: e.target.value });
  }

  async function createUser() {
    let userInfo;
    try {
      setLoading(true);
      userInfo = await getUserBasicInfo({
        email: currentStudent.parentEmail,
      });
      setUserBasicInfo(userInfo);
    } catch (error) {
      console.log("userNot found");
    }
    try {
      setLoading(true);

      const singInMethods = await fetchSignInMethodsForEmail(
        auth,
        currentStudent.parentEmail
      );

      if (singInMethods.length === 0) {
        console.log("account doesnt exist");
        console.log("creating new account");
        const userInfo = await adminCreateAccountWithEmailAndPassword({
          email: currentStudent.parentEmail,
          password: currentStudent.password,
        });

        const userId = userInfo.data.userId;
        const newObj = {
          ...currentStudent,
          schoolId: currentSchool.schoolId,
          parentId: userId,
        };
        await setDoc(doc(db, "allUsers", userId), {
          accountType: "parent_free",
          parentName: currentStudent.parentName,
          parentSurname: currentStudent.surname,
        });

        await setDoc(doc(db, "allUsers", userId, "children", newChildId), {
          name: currentStudent.childName,
          schoolId: currentSchool.schoolId,
          schoolName: currentSchool.schoolName,
          principalName: currentSchool.principalName,
          docId: newChildId,
        });
        await setDoc(
          doc(db, "schools", currentSchool.schoolId, "users", newChildId),
          newObj
        );
        setPageState("students");
        setCurrentStudent(emptyObject);
        setLoading(false);
        setNotify({
          on: true,
          type: "notification",
          notification:
            "Student successfully registered and added to class room ",
          solution: "You may need to reload the page to see the changes",
          countDown: 20000,
        });
      } else if (userInfo.data.accountType !== "teacher") {
        setLoading(true);
        console.log("account already exists and is a parent");
        console.log("Adding child to already existing parent account");

        console.log(userInfo.data);
        setUserBasicInfo(userInfo);
        setVerify({ active: true, accountType: userInfo.data.accountType });
        setLoading(false);
      } else if (userInfo.data.accountType === "teacher") {
        setLoading(true);
        console.log("account already exists and its a teacher");
        console.log("transforming teacher account to hybrid");
        await changeTeacherAccountToHybrid({
          email: currentStudent.parentEmail,
          parentName: currentStudent.parentName,
          parentSurname: currentStudent.surname,
        });
        console.log(userInfo.data);
        setVerify({ active: true, accountType: userInfo.data.accountType });
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setNotify({
        on: true,
        type: "error",
        notification:
          "An unknown error occured during the account creation proccess",
        solution: "Please check you internet connection and try again",
      });
    }
  }

  function goBack() {
    setPageState("students");
  }

  return (
    <section className="console">
      <div className="consoleHeader">
        <div className="backButton" onClick={goBack}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>
        <h2>{currentStudent.class}</h2>
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      {veify?.active && (
        <VerifyAccountOwnership
          setNotify={setNotify}
          setPageState={setPageState}
          setLoading={setLoading}
          userInfo={userBasicInfo}
          currentSchool={currentSchool}
          currentStudent={currentStudent}
        />
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          createUser();
        }}
        className="studentEditor formsContainer"
      >
        <h1>register a new student</h1>
        <div className="form">
          <h5 style={{ fontSize: "20px", marginTop: "0", color: "blue" }}>
            student info
          </h5>
          <div className="">
            <label> student name: </label>
            <input
              required
              onChange={(e) => handleStudentNameChange(e)}
              value={currentStudent.childName}
            />
          </div>
          <div className="">
            <label> student surname: </label>
            <input
              required
              onChange={(e) => handleStudentSurnameChange(e)}
              value={currentStudent.surname}
            />
          </div>
        </div>
        <div className="form">
          <h5 style={{ fontSize: "20px", marginTop: "0", color: "blue" }}>
            parent info
          </h5>
          <div>
            <label> parent name: </label>
            <input
              required
              onChange={(e) => handleParentNameChange(e)}
              value={currentStudent.parentName}
            />
          </div>
          <div>
            <label> parent email: </label>
            <input
              type="email"
              required
              onChange={(e) => handleParentEmailChange(e)}
              value={currentStudent.parentEmail}
            />
          </div>
          <div>
            <label htmlFor="password"> password: </label>
            <input
              name="password"
              type="text"
              required
              onChange={(e) => handleStudentPasswordChange(e)}
              value={currentStudent.password}
            />
          </div>
        </div>
        <button className="button">create student</button>
      </form>
    </section>
  );
}

import "../src/css/App.css";
/* import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"; */
import { useEffect, useRef, useState } from "react";
import { auth, db } from "./firebase-config";
import { FrontPage } from "./mainApp/frontPage";

import { AdminConsole } from "./mainApp/adminConsole/adminConsole";
import { BeforeSignUp, Login, SignUp } from "./mainApp/login";
import { LetterSender } from "./mainApp/sendLetter/sendLetter";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { MyLoader } from "./reausable Components/myLoader";
import { NotificationBar } from "./reausable Components/myNotificationBar";
import { WebsiteMananger } from "./mainApp/websiteMananger/websiteMananger";
import { ConsoleContainer } from "./reausable Components/consoleContainer";
import { AllForms } from "./mainApp/formsMananger/pages/allForms";
import { FormsMananger } from "./mainApp/formsMananger/formsMananger";
import { SuperAdminConsole } from "./mainApp/superAdminConsole/superAdminConsole";
import { ComponentTester } from "./mainApp/componentTester";

function Site() {
  const [appState, setAppState] = useState("frontPage"); // frontPage,classCreator, classEditor, login, testing
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentSchool, setCurrentSchool] = useState();
  const [loading, setLoading] = useState(false);
  const mainPage = useRef(null);

  const emptyCurrentStudent = {
    class: "",
    childName: "",
    surname: "",
    parentName: "",
    parentEmail: "",
    password: "",
    accountType: "parent_free",
  };
  const [currentStudent, setCurrentStudent] = useState({
    class: "",
    childName: "",
    surname: "",
    parentName: "",
    parentEmail: "",
    password: "",
    accountType: "parent_free",
  });

  const [loginInfo, setLoginInfo] = useState({
    name: "",
    email: "",
    password: "",
    schoolName: "",
    userId: "",
  });

  const [notify, setNotify] = useState({
    on: false,
    notification: "",
    type: "",
  });

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        setLoggedIn(true);
      } else {
        // No user is signed in.
        setLoggedIn(false);
        setLoading(false);
      }
    });
  });

  useEffect(() => {
    if (loggedIn) {
      const schoolRef = query(
        collection(db, "schools"),
        where("specialId", "==", auth?.currentUser?.uid)
      );

      const unsub = onSnapshot(schoolRef, (querySnap) => {
        setLoading(true);
        let foundSuperAdmin = false; // Flag to determine if a super admin is found
        querySnap.forEach((school) => {
          // Update the current school with the data from Firestore
          setCurrentSchool(school.data());
        });

        // Check if the user is a super admin
        if (loggedIn && querySnap.size < 1) {
          foundSuperAdmin = true;
        }
        // If no school is found or if the user is a super admin, set accountType as superAdmin
        if (foundSuperAdmin) {
          setCurrentSchool({ accountType: "superAdmin" });
          console.log("superAdminLogin");
        }
        setLoading(false);
      });

      return () => {
        unsub();
      };
    }
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn && currentSchool?.schoolId) {
      setAppState("adminConsole");
    } else if (loggedIn && currentSchool?.accountType === "superAdmin") {
      setAppState("superAdminConsole");
    }
  }, [loggedIn, currentSchool?.schoolId, currentSchool?.accountType]);

  return (
    <ConsoleContainer
      sidebarButtons={
        currentSchool?.schoolId
          ? [
              {
                value: "School Maneger",
                type: "plain",
                onClick: () => {
                  setAppState("adminConsole");
                },
              },
              {
                value: "Letter Sender",
                type: "plain",
                onClick: () => {
                  setAppState("letterSender");
                },
              },

              {
                value: "Forms Manager",
                type: "slide",
                onClick: () => {
                  setAppState("formsMananger");
                },
              },
            ]
          : currentSchool?.accountType === "superAdmin"
          ? [
              {
                value: "Front Page",
                type: "slide",
                onClick: () => {
                  setAppState("frontPage");
                },
              },
            ]
          : []
      }
      active={
        appState === "adminConsole" ||
        appState === "letterSender" ||
        appState === "websiteMananger" ||
        appState === "formsMananger" ||
        appState === "superAdminConsole"
      }
    >
      <div ref={mainPage} className="page">
        {loading && <MyLoader />}
        {notify.on && <NotificationBar notify={notify} setNotify={setNotify} />}

        {appState === "superAdminConsole" && (
          <SuperAdminConsole
            setLoading={setLoading}
            setAppState={setAppState}
          />
        )}
        {appState === "adminConsole" && (
          <AdminConsole
            setNotify={setNotify}
            setLoading={setLoading}
            currentSchool={currentSchool}
            emptyObject={emptyCurrentStudent}
            currentStudent={currentStudent}
            setCurrentStudent={setCurrentStudent}
            setAppState={setAppState}
            setLoggedIn={setLoggedIn}
            loggedIn={loggedIn}
          />
        )}
        {appState === "websiteMananger" && (
          <div style={{ marginTop: "90px" }}>
            <WebsiteMananger
              setLoading={setLoading}
              setAppState={setAppState}
              currentSchool={currentSchool}
            />
          </div>
        )}

        {appState === "formsMananger" && (
          <FormsMananger currentSchool={currentSchool} />
        )}
        {appState === "login" && !loggedIn && (
          <Login
            setNotify={setNotify}
            setLoading={setLoading}
            setAppState={setAppState}
            setLoginInfo={setLoginInfo}
            loginInfo={loginInfo}
          />
        )}
        {appState === "beforeSignup" && (
          <BeforeSignUp setAppState={setAppState} />
        )}
        {appState === "signup" && !loggedIn && (
          <SignUp
            setNotify={setNotify}
            setLoading={setLoading}
            setCurrentSchool={setCurrentSchool}
            setAppState={setAppState}
            setLoginInfo={setLoginInfo}
            loginInfo={loginInfo}
          />
        )}
        {appState === "frontPage" && (
          <FrontPage
            setNotify={setNotify}
            setLoading={setLoading}
            currentSchool={currentSchool}
            setAppState={setAppState}
            loggedIn={loggedIn}
          />
        )}
        {appState === "letterSender" && (
          <LetterSender
            setNotify={setNotify}
            setLoading={setLoading}
            currentSchool={currentSchool}
            setAppState={setAppState}
          />
        )}

        {appState === "testing" && <ComponentTester />}
      </div>
    </ConsoleContainer>
  );
}
export default Site;

import { useEffect, useState } from "react";
import { WebsiteMananger } from "../../websiteMananger/websiteMananger";
import { oneLayerObjectToArray } from "../../../helperFunctions/objectToArray";
import { List } from "../../../reausable Components/list";

export const SchoolMananger = ({ selectedSchool, setAppState, setLoading }) => {
  const [state, setState] = useState("schoolInfo"); // schoolInfo , websiteMananger
  const [schoolInfoKeys, setSchoolInfoKeys] = useState([]);
  useEffect(() => {
    let tempSchoolFieldKeys = [];

    for (const key in selectedSchool) {
      tempSchoolFieldKeys.push(key);
    }
    setSchoolInfoKeys(tempSchoolFieldKeys);
  }, [selectedSchool]);

  return (
    <div style={{ paddingTop: "100px" /* paddingLeft: "60px"  */ }}>
      {state === "schoolInfo" && (
        <h1 style={{ textAlign: "center" }}>{selectedSchool.schoolName}</h1>
      )}
      {state === "websiteMananger" && (
        <WebsiteMananger
          setLoading={setLoading}
          setAppState={setAppState}
          currentSchool={selectedSchool}
        />
      )}

      {state === "schoolInfo" &&
        schoolInfoKeys?.map((field) => {
          if (typeof selectedSchool[field] === "string") {
            return (
              <p style={{ marginLeft: "60px" }}>
                <span
                  style={{ fontSize: "18px", fontWeight: "700", color: "blue" }}
                >
                  {field}
                </span>
                : {selectedSchool[field]}
              </p>
            );
          }
        })}
      {state === "schoolInfo" && (
        <button
          style={{ marginLeft: "60px" }}
          onClick={() => {
            setState("websiteMananger");
          }}
          className="button"
        >
          Manange Website
        </button>
      )}
    </div>
  );
};

import {
  getImageUrlFromStorage,
  uploadFileToStorage,
} from "../../../../../firebase-config";
import { cloneAndRename } from "../../../../../helperFunctions/cloneAndRename";
import { deleteObjectAtPath } from "../../../../../helperFunctions/deleteObjectAtPath";
import { getValueAtPath } from "../../../../../helperFunctions/getValueAtPath";
import { generateRandomId } from "../../../../../helperFunctions/randomIdGenerator";
import { addNewObjectAtPath } from "../../../../../helperFunctions/updateObjectAtPath";

export function returnPcAbasoluteContainerSideBarButtons(
  setSelectedComponent,
  selectedComponent,
  setSelectedComponentId,
  setSelectedComponentPath,
  setFileComponentsObject,
  selectedComponentPath,
  fileComponentsObject,
  currentSchool,
  templateId,
  setNewComponentSelector,
  editorClipBoard,
  setEditorClipBoard
) {
  return [
    {
      inputValue: selectedComponent?.styles?.backgroundColor,
      title: "Background Color",
      type: "color",
      category: "Background",
      onChange: async (v) => {
        try {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              backgroundColor: v,
            },
          });
        } catch (error) {
          console.log(error);
        }
      },
    },
    {
      inputValue: selectedComponent.styles.backgroundColor ? true : false,
      title: "Background Visiblility",
      type: "checkbox",
      category: "Background",
      onChange: async (v) => {
        if (v) {
          try {
            setSelectedComponent({
              ...selectedComponent,
              styles: {
                ...selectedComponent.styles,
                backgroundColor: "rgb(255,255,255)",
              },
            });
          } catch (error) {
            console.log(error);
          }
        } else {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "backgroundColor"])
          );
        }
      },
    },

    {
      inputValue: selectedComponent?.backgroundImage?.imgPathUrl,
      title: "Background Image",
      type: "image",
      category: "Background",
      onChange: async (v) => {
        try {
          const response = await uploadFileToStorage(
            `schools/${currentSchool.schoolId}/savedWebsiteTemplates/${templateId}/images/${v?.target?.files[0].name}`,
            v?.target?.files[0]
          );
          setSelectedComponent({
            ...selectedComponent,
            backgroundImage: {
              imgPathUrl: await getImageUrlFromStorage(response.ref.fullPath),
              fileName: response.ref.name,
            },
          });
        } catch (error) {
          throw error;
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.borderRadius,
      title: "Border Raduis",
      category: "Border",
      type: "number",
      units: ["px", "%"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "borderRadius"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              borderRadius: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.borderStyle,
      title: "Border Style",
      category: "Border",
      type: "select",
      options: ["solid", "dashed", "dotted", "double"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "borderStyle"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              borderStyle: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.borderWidth,
      title: "Border Width",
      category: "Border",
      type: "number",
      units: ["px"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "borderWidth"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              borderWidth: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.borderColor,
      title: "Border Color",
      category: "Border",
      type: "color",

      onChange: (v) => {
        setSelectedComponent({
          ...selectedComponent,
          styles: {
            ...selectedComponent.styles,
            borderColor: v,
          },
        });
      },
    },
    {
      inputValue: selectedComponent?.styles?.width,
      title: "Width",
      type: "number",
      category: "Size",
      units: ["px", "%"],
      includeAuto: true,
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "width"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              width: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.height,
      title: "Height",
      type: "number",
      category: "Size",
      units: ["px", "%"],
      includeAuto: true,
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "height"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              height: v,
            },
          });
        }
      },
    },

    {
      inputValue: selectedComponent?.styles?.zIndex,
      title: "Z-Index",
      type: "number",
      category: "Flex Styles",
      /*   units: ["px", "%"], */
      /*  includeAuto: true, */
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "zIndex"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              zIndex: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.visibility,
      title: "Visibility",
      type: "select",
      options: ["visible", "hidden", "collapse"],
      category: "Flex Styles",
      onChange: async (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "visibility"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              visibility: v,
            },
          });
        }
      },
    },

    {
      inputValue: selectedComponent?.styles?.overflow,
      title: "OverFlow",
      type: "select",
      category: "Flex Styles",
      options: ["auto", "visible", "scroll", "hidden"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "overflow"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              overflow: v,
            },
          });
        }
      },
    },
    {
      type: "plain",
      title: "add Element",
      value: "add Element",
      onClick: () => {
        /*  setNewElementForm({ ...newElementForm, active: true }); */
        setNewComponentSelector((newSelectedComponent) => ({
          ...newSelectedComponent,
          active: true,
        }));
      },
    },
    {
      value: "Go to Parent",
      type: "plain",
      onClick: () => {
        if (selectedComponentPath.length > 1) {
          const newSelectedComponent = getValueAtPath(
            fileComponentsObject,
            selectedComponentPath.slice(0, -2)
          );
          setSelectedComponent(newSelectedComponent);
          setSelectedComponentId(newSelectedComponent.name);
          setSelectedComponentPath(selectedComponentPath.slice(0, -2));
        } else {
          console.log("no more parents");
        }
      },
    },
    {
      type: "plain",
      value: "copy",
      onClick: () => {
        setEditorClipBoard({
          ...cloneAndRename(selectedComponent),
          name: selectedComponent.name + "Clone" + generateRandomId(3, []),
        });
        setSelectedComponent({});
        setSelectedComponentId("");
        setSelectedComponentPath([]);
      },
    },
    {
      type: "plain",
      value: "paste",
      onClick: () => {
        setFileComponentsObject(
          addNewObjectAtPath(
            fileComponentsObject,
            [...selectedComponentPath],
            editorClipBoard.name
          )
        );
        setSelectedComponent({});
        setSelectedComponentId("");
        setSelectedComponentPath([]);
      },
    },
    {
      type: "plain",
      value: "delete",
      onClick: () => {
        setFileComponentsObject(
          deleteObjectAtPath(fileComponentsObject, [...selectedComponentPath])
        );
        setSelectedComponent({});
        setSelectedComponentId("");
        setSelectedComponentPath([]);
      },
    },
  ];
}

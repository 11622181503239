export function deleteObjectAtPath(obj, path) {
  // Make a copy of the original object to avoid mutation
  const updatedObject = { ...obj };

  // Traverse the object using the path
  let current = updatedObject;
  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i];
    if (!current[key] || typeof current[key] !== "object") {
      // If the path doesn't exist, there's nothing to delete
      return updatedObject;
    }
    current = current[key];
  }

  // Delete the key and its corresponding value at the final key in the path
  const lastKey = path[path.length - 1];
  delete current[lastKey];

  return updatedObject;
}

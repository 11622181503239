export function returnMobileMenu(name) {
  return {
    type: "menu",
    styles: { color: "red", position: "absolute" },
    name: name,
    script: {
      string: `
const ${name}Buttons = document.querySelectorAll(".${name}Button");
const ${name} = document.getElementById("${name}");

${name}.style.position = "fixed"

    ${name}Buttons.forEach((menuButton) => {
        menuButton.addEventListener("click", (e) => {
        if (${name}.style.display !== "none") {
            ${name}.style.display = "none";
             document.body.style.overflow = "auto";
        } else {
          ${name}.style.display = "block";
           document.body.style.overflow = "hidden";
                }
          });
      });
                
              `,
    },
    backgroundImage: { imgPathUrl: "" },
    nestedComponents: {
      [name + "ItemsColumnContainer"]: {
        backgroundImage: { imgPathUrl: "" },
        name: name + "ItemsColumnContainer",
        type: "menuItemsContainer",
        styles: {
          width: "100%",
          height: "20%",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          rowGap: "20px",
          position: "absolute",
        },
        nestedComponents: {
          [`${name}PlainStripColumn1`]: {
            name: name + "PlainStripColumn1",
            type: "relativeContainer",
            mobileStyles: {},
            styles: {
              position: "relative",
              backgroundColor: "blue",
              height: "50px",
              width: "100%",
              /*   flexGrow: 1,
              flexShrink: 0, */
            },
            index: 1,
            backgroundImage: {
              imgPathUrl: "",
            },
          },
        },
      },
    },
  };
}

export const mobileMenu = {
  category: "Menu",
  type: "menu",
  styles: { color: "red" },
  validParents: ["section", "container", "relativeContainer"],
  name: "mobileMenu",
};

import { useState } from "react";
import { FloatingForm } from "./floatingForm";
import { PlainButton, SideBar } from "./sideBar";
import { addDoc, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export const ConsoleContainer = ({
  children,
  active,

  sidebarButtons,
}) => {
  const [sidebarActive, setSidebarActive] = useState(false);
  return active ? (
    <div
      style={{
        boxSizing: "border-box",
        padding: 0,

        margin: 0,
        width: "100vw",
        height: "100vh",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "80px",

          position: "absolute",
          top: 0,
        }}
      ></div>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          onClick={() => {
            setSidebarActive(!sidebarActive);
          }}
          style={{
            position: "absolute" /*  sidebarActive ? "absolute" : "relative" */,
            top: "70px",
            left: "0px",
            bottom: "0px",
            right: sidebarActive ? "0px" : "unset",
            backgroundColor: "rgba(255,255,255, 0.6)",
            zIndex: sidebarActive ? 5 : 0,
          }}
        >
          {!sidebarActive && (
            <div style={{ height: "100%", width: "40px" }}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setSidebarActive(!sidebarActive);
                }}
                style={{ paddingLeft: "8px" }}
              >
                <FontAwesomeIcon size="2x" icon={faBars} />
              </div>
            </div>
          )}
          {sidebarActive && (
            <SideBar side={"left"} buttons={[...sidebarButtons]} />
          )}
        </div>
        {children}

        {/*  <div
          style={{
            borderLeft: "1px grey solid",
            backgroundColor: "white",
            width: "200px",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        ></div> */}
      </div>
    </div>
  ) : (
    <div>{children}</div>
  );
};

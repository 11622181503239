import "../css/about.css";
import logo from "../assets/myLogo.png";
import { Link } from "react-router-dom";

export const PrivacyPolicy = () => {
  return (
    <section className="about console">
      <div className="consoleHeader">
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      <h2>Privacy Policy</h2>
      <div className="paragraphContainer">
        <h3>Information Collected</h3>
        <p>
          EmpEd collects basic information from users, including user email,
          user names, and, in the case of parents, the names of their children.
          This information is collected through forms within the app.
        </p>

        <h3>Purpose of Data Collection</h3>
        <p>
          All information collected is used exclusively for app functionality,
          such as user identification within the app and account creation.
        </p>

        <h3>Data Storage and Security</h3>
        <p>
          User data is stored in Google's Firebase Firestore database, which
          employs robust security measures. Important data, such as messages and
          homework assignments, can only be accessed by the users to whom they
          belong.
        </p>

        <h3>Third-Party Advertising</h3>
        <p>
          EmpEd displays ads provided by Google. In the future, personalized ads
          may be implemented, which could involve Google using user data to
          display relevant ads. Please note that personalized ads are not
          currently active.
        </p>

        <h3>User Data Management</h3>
        <p>
          Users have the ability to create and delete their accounts, along with
          all associated information. Additional options for editing data will
          be made available soon. Parents and teachers must contact the school
          office or administrator responsible for the EmpEd admin console to
          request the creation or deletion of their accounts.
        </p>

        <h3>Analytics</h3>
        <p>
          EmpEd utilizes Firebase Analytics to analyze user behavior for the
          purpose of improving the app.
        </p>

        <h3>Minors' Data</h3>
        <p>
          Data from minors is protected and used solely for in-app
          functionality.
        </p>

        <h3>Data Retention</h3>
        <p>
          Most user data is deleted at the end of every year, as all accounts
          must be re-created when students register for school and other users
          may change classes or roles.
        </p>

        <h3>Contact Information</h3>
        <p>
          For inquiries or concerns regarding data privacy, users can visit our
          website's{" "}
          <Link to="/contactUs" className="textLink">
            contact page
          </Link>
          .
        </p>

        <p>
          Please note that this privacy policy is subject to change. Users will
          be notified of any updates to the policy. By using EmpEd, you agree to
          abide by this privacy policy.
        </p>
      </div>
    </section>
  );
};

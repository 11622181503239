export function returnNumberInput(name) {
  return {
    name: name,
    type: "input",
    inputType: "number",
    inputName: name,
    mobileStyles: {},
    tabletStyles: {},
    styles: {
      backgroundColor: "white",
      position: "absolute",
    },
    placeHolder: "30",
  };
}

export const numberInput = {
  category: "Input",
  name: "NumberInput",
  type: "input",
  inputType: "number",
  validParents: ["form"],
  styles: {
    backgroundColor: "white",
  },
  placeHolder: "30",
};

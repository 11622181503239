export function returnForm(name, currentSchool) {
  return {
    type: "form",
    script: {
      string: `const ${name} = document.getElementById("${name}");
      ${name}.addEventListener("submit", function (event) {
        event.preventDefault(); // Prevent default form submission

        const formData = new FormData(event.target); // Get form data
        const formDataObject = Object.fromEntries(formData.entries());
        const loadingScreen = document.getElementById("loadingScreen");
     

        const finalFormObject = {
          formFields: formDataObject,
          formName: "${name}",
          schoolPublicId: "${currentSchool?.schoolPublicId}",
         
        };

        loadingScreen.style.visibility = "visible";
        fetch(
          "https://us-central1-classroomapp-9e7c6.cloudfunctions.net/submitForm",
          {
            method: "POST",
            body: JSON.stringify(finalFormObject),
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => {
             loadingScreen.style.visibility = "collapse";
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
             alert("Message Sent Succesfully");
            // Handle success response from Cloud Function
          })
          .catch((error) => {
            console.error("Error:", error);
            // Handle error
          });
      });`,
    },
    nestedComponents: {
      headingX3Q: {
        value: "Name:",
        index: 2,
        name: "headingX3Q",
        mobileStyles: {
          top: "119px",
          left: "14px",
        },
        type: "heading",
        isDragging: false,
        zIndex: 2,

        styles: {
          padding: "0px",
          left: "17px",
          fontWeight: "600",
          top: "136px",
          margin: "0px",
          fontFamily: "Georgia, serif",
          position: "absolute",
          fontSize: "16px",
          alignSelf: "center",
          color: "#000",
        },

        active: true,
      },
      SubmitButtonFmu: {
        value: "Submit",
        type: "button",
        buttonType: "submit",
        styles: {
          position: "absolute",
          padding: "0px",
          fontSize: "18px",
          fontWeight: "700",
          paddingRight: "2px",
          paddingLeft: "2px",
          top: "189px",
          backgroundColor: "#aa9d9d",
          left: "491px",
        },
        name: "SubmitButtonFmu",
        validParents: ["form"],
        tabletStyles: {
          left: "457px",
          top: "159px",
        },

        mobileStyles: {
          left: "212px",
          top: "190px",
        },
      },
      TextInputGZE: {
        type: "input",
        name: "TextInputGZE",
        mobileStyles: {
          width: "260px",
          left: "12px",
          top: "143px",
        },

        placeHolder: "text",
        inputType: "text",
        isDragging: false,
        inputName: "Name",
        styles: {
          position: "absolute",
          width: "400px",
          top: "164px",
          fontSize: "27px",
          left: "15px",
          backgroundColor: "white",
        },
      },
      headingr1d: {
        mobileStyles: {
          left: "0px",
          top: "9px",
        },

        zIndex: 4,
        type: "heading",
        index: 4,
        active: true,
        styles: {
          position: "absolute",
          width: "100%",
          textAlign: "center",
          margin: "0px",
          top: "12px",
          alignSelf: "center",
          left: "0px",
          color: "#000",
          fontWeight: "800",
          fontFamily:
            "'Garamond', Baskerville, 'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif",
          padding: "0px",
          fontSize: "24px",
        },

        name: "headingr1d",
        value: "My Form",
        offsetpcStyles: {
          height: 235,
          width: 600,
        },
      },
      headingYTo: {
        offsetmobileStyles: {
          height: 224,
          width: 300,
        },
        name: "headingYTo",
        type: "heading",
        active: true,
        value: "Age:",
        isDragging: false,
        mobileStyles: {
          top: "37px",
          left: "14px",
        },
        styles: {
          alignSelf: "center",
          left: "17px",
          margin: "0px",
          top: "55px",
          fontFamily: "Georgia, serif",
          position: "absolute",
          fontSize: "16px",
          fontWeight: "600",
          padding: "0px",
          color: "#000",
        },
        offsetpcStyles: {
          height: 235,
          width: 600,
        },
        index: 3,
        zIndex: 3,
      },
      NumberInput0zJ: {
        offsetpcStyles: {
          height: 235,
          width: 600,
        },
        placeHolder: "30",
        inputName: "Age",
        type: "input",
        mobileStyles: {
          left: "12px",
          top: "64px",
          width: "260px",
        },
        isDragging: false,
        offsetmobileStyles: {
          width: 300,
          height: 224,
        },
        name: "NumberInput0zJ",
        inputType: "number",
        styles: {
          position: "absolute",
          backgroundColor: "white",
          top: "81px",
          width: "400px",
          left: "14px",
          fontSize: "27px",
        },
      },
    },
    isDragging: false,
    zIndex: 6,
    offsetpcStyles: {
      width: 600,
      height: 235,
    },
    mobileStyles: {
      width: "300px",
      top: "1013px",
      height: "224px",
      left: "32px",
    },
    index: 6,
    offsettabletStyles: {
      height: 60,
      width: 240,
    },
    formName: "My Form",
    validParents: ["section"],
    name: name,

    styles: {
      borderRadius: "26px",
      height: "235px",
      width: "600px",
      top: "2px",
      left: "3px",
      backgroundColor: "#ebe5d1",
      position: "absolute",
    },
    inputType: "number",
    backgroundImage: {
      imgPathUrl: "",
    },
  };
}

export const form = {
  category: "Form",
  type: "form",

  nestedComponents: {
    headingX3Q: {
      value: "Name:",
      index: 2,
      name: "headingX3Q",
      mobileStyles: {
        top: "119px",
        left: "14px",
      },
      type: "heading",
      isDragging: false,
      zIndex: 2,

      styles: {
        padding: "0px",
        left: "17px",
        fontWeight: "600",
        top: "136px",
        margin: "0px",
        fontFamily: "Georgia, serif",
        position: "absolute",
        fontSize: "16px",
        alignSelf: "center",
        color: "#000",
      },

      active: true,
    },
    SubmitButtonFmu: {
      value: "Submit",
      type: "button",
      buttonType: "submit",
      styles: {
        position: "absolute",
        padding: "0px",
        fontSize: "18px",
        fontWeight: "700",
        paddingRight: "2px",
        paddingLeft: "2px",
        top: "189px",
        backgroundColor: "#aa9d9d",
        left: "491px",
      },
      name: "SubmitButtonFmu",
      validParents: ["form"],
      tabletStyles: {
        left: "457px",
        top: "159px",
      },

      mobileStyles: {
        left: "212px",
        top: "190px",
      },
    },
    TextInputGZE: {
      type: "input",
      name: "TextInputGZE",
      mobileStyles: {
        width: "260px",
        left: "12px",
        top: "143px",
      },

      placeHolder: "text",
      inputType: "text",
      isDragging: false,
      inputName: "Name",
      styles: {
        position: "absolute",
        width: "400px",
        top: "164px",
        fontSize: "27px",
        left: "15px",
        backgroundColor: "white",
      },
    },
    headingr1d: {
      mobileStyles: {
        left: "0px",
        top: "9px",
      },

      zIndex: 4,
      type: "heading",
      index: 4,
      active: true,
      styles: {
        position: "absolute",
        width: "100%",
        textAlign: "center",
        margin: "0px",
        top: "12px",
        alignSelf: "center",
        left: "0px",
        color: "#000",
        fontWeight: "800",
        fontFamily:
          "'Garamond', Baskerville, 'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif",
        padding: "0px",
        fontSize: "24px",
      },

      name: "headingr1d",
      value: "My Form",
      offsetpcStyles: {
        height: 235,
        width: 600,
      },
    },
    headingYTo: {
      offsetmobileStyles: {
        height: 224,
        width: 300,
      },
      name: "headingYTo",
      type: "heading",
      active: true,
      value: "Age:",
      isDragging: false,
      mobileStyles: {
        top: "37px",
        left: "14px",
      },
      styles: {
        alignSelf: "center",
        left: "17px",
        margin: "0px",
        top: "55px",
        fontFamily: "Georgia, serif",
        position: "absolute",
        fontSize: "16px",
        fontWeight: "600",
        padding: "0px",
        color: "#000",
      },
      offsetpcStyles: {
        height: 235,
        width: 600,
      },
      index: 3,
      zIndex: 3,
    },
    NumberInput0zJ: {
      offsetpcStyles: {
        height: 235,
        width: 600,
      },
      placeHolder: "30",
      inputName: "Age",
      type: "input",
      mobileStyles: {
        left: "12px",
        top: "64px",
        width: "260px",
      },
      isDragging: false,
      offsetmobileStyles: {
        width: 300,
        height: 224,
      },
      name: "NumberInput0zJ",
      inputType: "number",
      styles: {
        position: "absolute",
        backgroundColor: "white",
        top: "81px",
        width: "400px",
        left: "14px",
        fontSize: "27px",
      },
    },
  },
  isDragging: false,
  zIndex: 6,
  offsetpcStyles: {
    width: 600,
    height: 235,
  },
  mobileStyles: {
    width: "300px",
    top: "1013px",
    height: "224px",
    left: "32px",
  },
  index: 6,
  offsettabletStyles: {
    height: 60,
    width: 240,
  },
  formName: "My Form",
  validParents: ["section"],
  name: "Form",

  styles: {
    borderRadius: "26px",
    height: "235px",
    width: "600px",
    top: "2px",
    left: "3px",
    backgroundColor: "#ebe5d1",
    position: "relative",
  },
  inputType: "number",
  backgroundImage: {
    imgPathUrl: "",
  },
};

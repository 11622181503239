export function returnPlainButton(name) {
  return {
    index: "5",
    value: "Emp Ed",
    active: true,
    name: name,
    type: "Link Text",
    mobileStyles: {},
    styles: {
      borderRadius: "13px",
      fontSize: "24px",
      paddingTop: "2px",
      left: "10px",
      position: "absolute",
      top: "10px",
      backgroundColor: "#41a611",
      paddingBottom: "7px",
      paddingRight: "8px",
      paddingLeft: "8px",
      color: "#fffafa",
      fontWeight: "800",
      textDecorationThickness: "2px",
      alignSelf: "center",
    },
    link: "https://emped.ml-zaria.co.za/",
  };
}

export const plainButton = {
  category: "Button",
  validParents: [
    "form",
    "section",
    "box",
    "container",
    "relativeContainer",
    "strip",
    "menu",
  ],
  index: "5",
  value: "Emp Ed",
  active: true,
  name: "plainButton",
  type: "Link Text",
  styles: {
    borderRadius: "13px",
    fontSize: "24px",
    paddingTop: "2px",
    position: "relative",
    padding: "0px",
    margin: "auto",
    backgroundColor: "#41a611",
    paddingBottom: "7px",
    paddingRight: "8px",
    paddingLeft: "8px",
    color: "#fffafa",
    fontWeight: "800",
    textDecorationThickness: "2px",
    alignSelf: "center",
  },
  link: "https://emped.ml-zaria.co.za/",
};

export function returnSubmitButton(name) {
  return {
    name: name,
    type: "button",
    buttonType: "submit",
    validParents: ["form"],
    value: "Submit",
    styles: {
      position: "absolute",
      padding: "0px",
    },
    mobileStyles: {},
    tabletStyles: {},
  };
}

export const submitButton = {
  category: "Button",
  name: "SubmitButton",
  type: "button",
  buttonType: "submit",
  validParents: ["form"],
  value: "Submit",
  styles: { padding: "0px" },
};

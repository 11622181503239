export function returnHeading2(name) {
  return {
    index: "5",
    value: "Heading 2",
    active: true,
    name: name,
    type: "heading2",
    mobileStyles: {},

    styles: {
      fontSize: "24px",
      left: "10px",
      position: "absolute",
      top: "10px",
      color: "#000",
      fontWeight: "800",
      alignSelf: "center",
      padding: "0px",
      margin: "0px",
    },
  };
}

export const heading2 = {
  category: "Text",
  index: "5",
  value: "Heading 2",
  active: true,
  name: "heading2",
  type: "heading2",
  validParents: ["form", "section", "box", "container", "relativeContainer"],
  styles: {
    fontSize: "24px",
    fontWeight: "800",
    position: "relative",
    color: "#000",
    margin: "auto",
  },
};

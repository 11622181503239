import { auth } from "../firebase-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../css/frontPage.css";
import "../css/App.css";
import frame from "../assets/frame-3518727.jpg";
import logo from "../assets/myLogo.png";
import frontPage1 from "../assets/frontPage-1.png";
import screenShot from "../assets/myScreenShot.png";
import { MyDropDown } from "../reausable Components/myDropDown";
import { useState } from "react";
import { faAnglesDown, faAnglesUp } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

export const FrontPage = ({
  loggedIn,
  setAppState,
  currentSchool,
  setLoading,
  setNotify,
}) => {
  const [menuActive, setMenuActive] = useState(false);
  function logOut() {
    auth.signOut();
    setAppState("login");
  }

  return (
    <section className="frontPage">
      <MyDropDown
        active={menuActive}
        setActive={setMenuActive}
        menuItems={[
          {
            title: loggedIn && currentSchool ? "Log Out" : "Create Account",
            onClick: () => {
              loggedIn && currentSchool
                ? logOut()
                : setAppState("beforeSignup");
            },
          },
          {
            title: loggedIn && currentSchool ? "Go To Console" : "Log In",
            onClick: () => {
              loggedIn && currentSchool.schoolId
                ? setAppState("adminConsole")
                : currentSchool.accountType === "superAdmin"
                ? setAppState("superAdminConsole")
                : setAppState("login");
            },
          },
          {
            title: "About",
            route: "about",
          },
          {
            title: "Contact Us",
            route: "contactUs",
          },
        ]}
      />
      <header className="header">
        <button
          style={{
            backgroundColor: "steelblue",
            border: "none",
            borderRadius: "5px",
          }}
          className="menuButton"
          onClick={() => setMenuActive(!menuActive)}
        >
          {!menuActive && (
            <FontAwesomeIcon
              size="2x"
              style={{ color: "white" }}
              icon={faAnglesDown}
            />
          )}
          {menuActive && (
            <FontAwesomeIcon
              style={{ color: "white" }}
              size="2x"
              icon={faAnglesUp}
            />
          )}
        </button>
        <img
          alt="logo"
          src={logo}
          style={{ width: "80px", position: "absolute", left: "10px" }}
        />
        <nav className="navContainer">
          <ul className="navMenu">
            <li className="button3">
              <Link
                style={{ textDecoration: "none" }}
                className="button3 "
                to="contactUs"
              >
                Contact Us
              </Link>
            </li>
            <li className="button3">
              <Link
                style={{ textDecoration: "none" }}
                className="button3 "
                to="about"
              >
                About
              </Link>
            </li>
            {!loggedIn && (
              <li onClick={() => setAppState("login")} className="button">
                Log In
              </li>
            )}
            <li
              onClick={loggedIn ? logOut : () => setAppState("beforeSignup")}
              className="button"
            >
              {loggedIn ? "Log out" : "Create Account"}
            </li>
            {loggedIn && currentSchool && (
              <li
                onClick={() => {
                  if (currentSchool?.accountType === "superAdmin") {
                    setAppState("superAdminConsole");
                  } else {
                    setAppState("adminConsole");
                    setLoading(true);
                  }
                }}
                className="button"
              >
                Go to Console
              </li>
            )}
          </ul>
        </nav>
      </header>
      {/* <div className="announcement">
        <FontAwesomeIcon
          color="orange"
          fontSize={"25px"}
          style={{ transform: "translateY(10px)" }}
          icon={faTriangleExclamation}
        />
        <p style={{ maxWidth: "80%" }}>
          All schools that signup before the 28 Sep 2023 get Free access to all
          current and future services for life.{" "}
          <Link to="freeAccountTsAndCs" className="textLink">
            T's & C's apply!
          </Link>
        </p>
      </div> */}

      <main>
        <section className="hero">
          <div
            style={{ backgroundImage: `image(${frame})` }}
            className="heroHeadingContainer"
          >
            <h1 className="heroHeading">
              Empowering Education through Seamless Connectivity
            </h1>
            <p className="heroParagraph">
              EmpEd: Where technology bridges the gap between teachers, parents,
              and students.
            </p>

            <button
              onClick={
                loggedIn && currentSchool
                  ? () => setAppState("adminConsole")
                  : () => setAppState("beforeSignup")
              }
              style={{ marginLeft: "10px" }}
              className="button"
            >
              {loggedIn && currentSchool ? "Go To Console" : "Create Account"}
            </button>
          </div>
          <div className="heroImageContainer">
            <img className="heroCenterImage" src={screenShot} alt="" />
          </div>
        </section>
        <div className="pageDivider">
          <div></div>
        </div>
        <section className="about">
          <h2>About</h2>
          <div className="frontPageParagraphContainer">
            <h3>Services</h3>
            <p>
              At EmpEd, we are dedicated to delivering a suite of premium
              services tailored to educational institutions worldwide. Our app
              offers a comprehensive range of features, including:
            </p>
            <ul>
              <li>
                <p>
                  <span style={{ fontWeight: 700 }}>
                    Free Website for all schools -
                  </span>{" "}
                  As part of our commitment to supporting educational
                  institutions, we offer free website creation and hosting for
                  all schools using our platform. Enhance your school's online
                  presence effortlessly with EmpEd."
                </p>
              </li>
              <li>
                <p>
                  <span style={{ fontWeight: 700 }}>
                    Homework and Assignment Reporting -
                  </span>{" "}
                  Our Homework and Assignment Reporting feature empowers
                  teachers to effortlessly share homework and assignments with
                  parents, fostering seamless communication. Parents, in turn,
                  can access assignments for multiple children across various
                  schools at their convenience.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ fontWeight: 700 }}>One Way Messenger -</span>{" "}
                  Designed exclusively for teachers and parents, our One Way
                  Messenger facilitates effective communication. Teachers can
                  initiate messages to parents, who can respond only when
                  permitted by teachers, ensuring controlled and secure
                  interactions.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ fontWeight: 700 }}>
                    Attendance & Behavior Tracking -
                  </span>{" "}
                  For students in grades 4 to 12, our Attendance & Behavior
                  Tracking provides real-time updates on attendance and behavior
                  for each class period. Parents can access this information
                  through the EmpEd app, promoting transparency and engagement.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ fontWeight: 700 }}>
                    Daily Attendance & Behavior-
                  </span>{" "}
                  Tailored for grades 1 to 3, our Daily Attendance & Behavior
                  feature simplifies attendance and behavior tracking. It
                  provides a once-a-day snapshot of crucial information to keep
                  parents informed.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ fontWeight: 700 }}>
                    Upcoming Tests Notification -
                  </span>{" "}
                  EmpEd enables teachers to share timely information about
                  upcoming tests, enabling parents to support their children's
                  study efforts and ensure preparedness for examinations.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ fontWeight: 700 }}>
                    Test Results Sharing -
                  </span>{" "}
                  Our app allows teachers to promptly share students' test
                  results with parents, providing insight into academic progress
                  and achievements. Parents can easily access test results for
                  all their children in one place.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ fontWeight: 700 }}>
                    Letters and Announcements -
                  </span>{" "}
                  Utilizing the admin console, schools can efficiently
                  distribute letters to either the entire parent body or
                  specific classes. Parents can access these letters within the
                  EmpEd app, promoting effective school-home communication.
                </p>
              </li>
            </ul>
            <p>
              At EmpEd, we're committed to enhancing the educational experience
              for both educators and parents. Our services are designed to
              foster collaboration, transparency, and engagement, ultimately
              enriching the academic journey for students.
            </p>
          </div>
        </section>
        <section className="freeWebsite">
          <h2>Free Website</h2>
          <div className="frontPageParagraphContainer">
            <span
              style={{
                color: "Highlight",
                fontWeight: "400",
                fontSize: "19px",
              }}
            >
              As part of our commitment to supporting educational institutions,
              we offer free website creation and hosting for all schools using
              our platform. Enhance your school's online presence effortlessly
              with EmpEd.
            </span>
          </div>

          <div
            style={{
              width: "90%",
              display: "flex",

              gap: "30px",
            }}
            className="frontPageParagraphContainer"
          >
            <a href="https://emped-demosite.netlify.app">
              <img
                style={{ width: "300px", height: "auto" }}
                src={frontPage1}
                alt="frontPage1"
              ></img>
            </a>
            <p
              style={{
                color: "Highlight",
                fontWeight: "400",
                fontSize: "19px",
              }}
            >
              Our free Website contains unlimited pages, unlimited form inputs,
              and unlimited form submissions. All websites provided by us are
              fully managed, so you can contact us at any time to request
              changes to be made to you site and we will be right on it.
            </p>
          </div>
          <a href="https://emped-demosite.netlify.app">
            <button className="button">See Demo Site</button>
          </a>
        </section>
      </main>

      <footer></footer>
    </section>
  );
};

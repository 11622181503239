import { arrayToObject } from "../../../../../helperFunctions/arrayToObject";
import { deleteObjectAtPath } from "../../../../../helperFunctions/deleteObjectAtPath";
import { getValueAtPath } from "../../../../../helperFunctions/getValueAtPath";

import {
  objectToArray,
  oneLayerObjectToArray,
} from "../../../../../helperFunctions/objectToArray";
import { generateRandomId } from "../../../../../helperFunctions/randomIdGenerator";
import { separateNumberFromUnit } from "../../../../../helperFunctions/separateNumberFromUnit";
import { addNewObjectAtPath } from "../../../../../helperFunctions/updateObjectAtPath";

export function returnPcSlideSideBarButtons(
  setSelectedComponent,
  selectedComponent,
  setSelectedComponentId,
  setSelectedComponentPath,
  setFileComponentsObject,
  selectedComponentPath,
  fileComponentsObject
) {
  return [
    {
      inputValue: selectedComponent?.styles?.visibility,
      title: "Visibility",
      type: "select",
      options: ["visible", "hidden", "collapse"],
      category: "Flex Styles",
      onChange: async (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "visibility"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              visibility: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.zIndex,
      title: "Z-Index",
      type: "number",
      category: "Flex Styles",
      /*   units: ["px", "%"], */
      /*  includeAuto: true, */
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "zIndex"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              zIndex: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.height,
      title: "Height",
      type: "number",
      category: "Size",
      units: ["px", "%"],
      onChange: (v) => {
        if (v === "" || !v) {
          let slides = objectToArray(
            selectedComponent.nestedComponents[
              `${selectedComponent.name}-slidesContainer`
            ].nestedComponents
          );

          slides = slides.map((slide) => slide.name);

          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "height"])
          );
          slides.forEach((slide) => {
            setSelectedComponent(
              deleteObjectAtPath(selectedComponent, [
                "nestedComponents",
                `${selectedComponent.name}-slidesContainer`,
                "nestedComponents",
                slide,
                "styles",
                "height",
              ])
            );
          });
        } else {
          const slides = oneLayerObjectToArray(
            selectedComponent.nestedComponents[
              `${selectedComponent.name}-slidesContainer`
            ].nestedComponents
          );

          let newSlides = [];

          slides.forEach((slide) => {
            newSlides.push({
              // changing the height of the individual elements in the slide
              ...slide,
              styles: { ...slide.styles, height: v },
            });
          });

          setSelectedComponent({
            ...selectedComponent,
            nestedComponents: {
              ...selectedComponent.nestedComponents,
              [`${selectedComponent.name}-slidesContainer`]: {
                ...selectedComponent.nestedComponents[
                  `${selectedComponent.name}-slidesContainer`
                ],
                nestedComponents: {
                  ...arrayToObject(newSlides),
                },
              },
            }, // changing the height of the slide's top layer
            styles: {
              ...selectedComponent.styles,
              height: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.width,
      title: "Width",
      type: "number",
      category: "Size",
      units: ["px", "%"],
      onChange: (v) => {
        if (v === "" || !v) {
          let slides = objectToArray(
            selectedComponent.nestedComponents[
              `${selectedComponent.name}-slidesContainer`
            ].nestedComponents
          );

          slides = slides.map((slide) => slide.name);

          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "width"])
          );
          slides.forEach((slide) => {
            setSelectedComponent(
              deleteObjectAtPath(selectedComponent, [
                "nestedComponents",
                `${selectedComponent.name}-slidesContainer`,
                "nestedComponents",
                slide,
                "styles",
                "width",
              ])
            );
          });
        } else {
          const slides = oneLayerObjectToArray(
            selectedComponent.nestedComponents[
              `${selectedComponent.name}-slidesContainer`
            ].nestedComponents
          );

          let newSlides = [];

          slides.forEach((slide) => {
            newSlides.push({
              // changing the width of the individual elements in the slide
              ...slide,
              styles: { ...slide.styles, width: v },
            });
          });

          setSelectedComponent({
            ...selectedComponent,
            nestedComponents: {
              ...selectedComponent.nestedComponents,
              [`${selectedComponent.name}-slidesContainer`]: {
                ...selectedComponent.nestedComponents[
                  `${selectedComponent.name}-slidesContainer`
                ],
                nestedComponents: {
                  ...arrayToObject(newSlides),
                },
              },
            }, // changing the width of the slide's top layer
            styles: {
              ...selectedComponent.styles,
              width: v,
            },
          });
        }
      },
    },
    {
      value: "Clone",
      type: "plain",
      onClick: () => {
        const pathToParent = [...selectedComponentPath].slice(0, -2);
        const selectedComponent = getValueAtPath(
          fileComponentsObject,
          selectedComponentPath
        );
        const cloneName = `${selectedComponent.name}clone${generateRandomId(
          2,
          []
        )}`;

        let newComponent = {
          ...getValueAtPath(fileComponentsObject, selectedComponentPath),
          name: cloneName,
          styles: {
            ...selectedComponent?.styles,
            top:
              separateNumberFromUnit(selectedComponent?.styles?.top)[0] +
              20 +
              "px",
          },
        };

        delete newComponent?.mobileStyles?.top;
        delete newComponent?.mobileStyles?.left;

        setFileComponentsObject(
          addNewObjectAtPath(
            fileComponentsObject,
            [...pathToParent, "nestedComponents", cloneName],
            newComponent
          )
        );
        setSelectedComponent(newComponent);
        setSelectedComponentPath([
          ...pathToParent,
          "nestedComponents",
          cloneName,
        ]);
        setSelectedComponentId(cloneName);
      },
    },
    {
      value: "Go to Parent",
      type: "plain",
      onClick: () => {
        if (selectedComponentPath.length > 1) {
          const newSelectedComponent = getValueAtPath(
            fileComponentsObject,
            selectedComponentPath.slice(0, -2)
          );
          setSelectedComponent(newSelectedComponent);
          setSelectedComponentId(newSelectedComponent.name);
          setSelectedComponentPath(selectedComponentPath.slice(0, -2));
        } else {
          console.log("no more parents");
        }
      },
    },
    {
      type: "plain",
      value: "delete",
      onClick: () => {
        setFileComponentsObject(
          deleteObjectAtPath(fileComponentsObject, [...selectedComponentPath])
        );

        setSelectedComponent({});
        setSelectedComponentId("");
        setSelectedComponentPath([]);
      },
    },
    {
      type: "plain",
      title: "add Element",
      value: "Add Slide",
      onClick: () => {
        const numberOfSlides =
          selectedComponent.script.variables.numberOfSlides;

        const currentSlideWidth = selectedComponent.script.variables.slideWidth;

        const currentSlideHeight =
          selectedComponent.script.variables.slideHeight;

        const newSlideId = generateRandomId(4, []);
        /*  setNewElementForm({ ...newElementForm, active: true }); */
        const newSelectedComponent = addNewObjectAtPath(
          selectedComponent,
          [
            "nestedComponents",
            `${selectedComponent.name}-slidesContainer`,
            "nestedComponents",
            `${selectedComponent.name}-slide${newSlideId}`,
          ],
          {
            active: true,
            name: `${selectedComponent.name}-slide${newSlideId}`,
            type: "relativeContainer",
            styles: {
              width: `${currentSlideWidth}px`,
              height: `${currentSlideHeight || 337}px`,
              backgroundColor: "rgb(13,182,134)",
              display: "flex",
              position: "relative",
              padding: "0px",
              margin: "0px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              flexGrow: 1,
              flexShrink: 0,
            },
            index: "0",
            backgroundImage: {
              imgPathUrl: "",
            },
          }
        );

        setSelectedComponent({
          ...newSelectedComponent,
          script: {
            ...newSelectedComponent.script,
            variables: {
              ...newSelectedComponent.script.variables,
              numberOfSlides: numberOfSlides + 1,
            },
          },
        });
      },
    },
  ];
}

export function returnPlainStrip(name) {
  return {
    name: name,
    type: "strip",
    script: {
      variables: {
        numberOfColumns: 2,
      },
    },
    inputName: name,
    mobileStyles: {},
    styles: {
      position: "absolute",
      backgroundColor: "white",
      width: "100%",
      height: "200px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      columnGap: "8px",
      boxSizing: "border-box",
    },
    placeHolder: "30",
    nestedComponents: {
      [`${name}PlainStripColumn1`]: {
        name: name + "PlainStripColumn1",
        type: "relativeContainer",
        mobileStyles: {},
        styles: {
          position: "relative",
          backgroundColor: "blue",
          height: "100%",
          flexGrow: 1,
          flexShrink: 0,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        },
        index: 1,
        backgroundImage: {
          imgPathUrl: "",
        },
      },
      [`${name}PlainStripColumn2`]: {
        name: name + "PlainStripColumn2",
        type: "relativeContainer",
        mobileStyles: {},
        styles: {
          position: "relative",
          backgroundColor: "blue",
          height: "100%",
          flexGrow: 1,
          flexShrink: 0,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        },
        index: 2,
        backgroundImage: {
          imgPathUrl: "",
        },
      },
    },
    backgroundImage: {
      imgPathUrl: "",
    },
  };
}

export const plainStrip = {
  category: "Strip",
  name: "plainStrip",
  type: "strip",
  validParents: ["section"],
  styles: {
    backgroundColor: "white",
    width: "90%",
    height: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    columnGap: "8px",
  },
  placeHolder: "30",
  nestedComponents: {
    plainStripColumn1: {
      name: "plainStripColumn1",
      type: "relativeContainer",
      styles: {
        backgroundColor: "blue",
        width: "45%",
        height: "200px",
      },
      index: 1,
    },
    plainStripColumn2: {
      name: "plainStripColumn1",
      type: "relativeContainer",
      styles: {
        backgroundColor: "blue",
        width: "45%",
        height: "200px",
      },
      index: 2,
    },
  },
};

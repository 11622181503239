import { generateRandomId } from "../../../../helperFunctions/randomIdGenerator";
import { returnPlainButton, plainButton } from "./buttons/plainButton";
import { returnSubmitButton, submitButton } from "./buttons/submitButton";
import { returnForm, form } from "./forms/form";
import { icons, plainIcon, returnPlainIcon } from "./icons/plainIcon";
import { returnNumberInput, numberInput } from "./inputs/numberInput";
import { returnTextArea, textArea } from "./inputs/textArea";
import { returnTextInput, textInput } from "./inputs/textInput";
import { returnMobileMenu, mobileMenu } from "./menues/mobileMenu";
import { returnPlainSlide, plainSlide } from "./slideShows/plainSlide";
import { returnPlainStrip, plainStrip } from "./strips/plainStrip";
import { returnHeading1, heading1 } from "./text/heading1";
import { returnHeading2, heading2 } from "./text/heading2";
import { returnHeading3, heading3 } from "./text/heading3";
import { returnHeading4, heading4 } from "./text/heading4";
import { returnParagraph, paragraph } from "./text/paragraph";

export function returnComponentData(
  componentName,
  existingIds = [],
  setExistingComponentIds,
  currentSchool,
  category,
  icon
) {
  const name = `${componentName}${generateRandomId(3, existingIds)}`;
  setExistingComponentIds([...existingIds, name]);
  if (componentName === "plainSlide") {
    return returnPlainSlide(name);
  } else if (componentName === "plainStrip") {
    return returnPlainStrip(name);
  } else if (componentName === "mobileMenu") {
    return returnMobileMenu(name);
  } else if (componentName === "plainButton") {
    return returnPlainButton(name);
  } else if (componentName === "plainIcon") {
    return returnPlainIcon(name, icon);
  } else if (componentName === "heading") {
    return returnHeading1(name);
  } else if (componentName === "heading2") {
    return returnHeading2(name);
  } else if (componentName === "heading3") {
    return returnHeading3(name);
  } else if (componentName === "heading4") {
    return returnHeading4(name);
  } else if (componentName === "paragraph") {
    return returnParagraph(name);
  } else if (componentName === "TextInput") {
    return returnTextInput(name);
  } else if (componentName === "TextArea") {
    return returnTextArea(name);
  } else if (componentName === "NumberInput") {
    return returnNumberInput(name);
  } else if (componentName === "Form") {
    return returnForm(name, currentSchool);
  } else if (componentName === "SubmitButton") {
    return returnSubmitButton(name);
  } else if (category === "Image") {
    return {
      name: name,
      type: "image",
      buttonType: "submit",

      value: "Submit",
      styles: {
        position: "absolute",
        padding: "0px",
        width: "100px",
      },
      mobileStyles: {},
      tabletStyles: {},
      imgPathUrl:
        "https://firebasestorage.googleapis.com/v0/b/classroomapp-9e7c6.appspot.com/o/schools%2FDe7vzAzZgiSYm8W2VPwljGoxb8h2%2FsavedWebsiteTemplates%2Fhellowrld%2Fimages%2FnewBlueBird?alt=media&token=4ee8e426-a911-4d85-b4d3-746b9f5782fa",
    };
  }
}

/* Display Components */
export const componentsObjects = [
  {
    ...plainButton,
  },
  { ...mobileMenu },

  {
    ...plainSlide,
  },

  {
    ...plainStrip,
  },
  /* {
    ...plainIcon,
  }, */
  ...icons,

  {
    ...heading1,
  },

  {
    ...heading2,
  },

  {
    ...heading3,
  },

  {
    ...heading4,
  },

  {
    ...paragraph,
  },

  {
    ...textInput,
  },
  {
    ...textArea,
  },

  {
    ...numberInput,
  },

  { ...form },

  {
    ...submitButton,
  },

  /* Image */
  {
    category: "Image",
    name: "image",
    type: "image",
    buttonType: "submit",
    validParents: ["form", "section", "box", "container", "relativeContainer"],
    value: "Submit",
    styles: {
      position: "relative",
      padding: "0px",
      width: "200px",
    },
    imgPathUrl:
      "https://firebasestorage.googleapis.com/v0/b/classroomapp-9e7c6.appspot.com/o/schools%2FDe7vzAzZgiSYm8W2VPwljGoxb8h2%2FsavedWebsiteTemplates%2Fhellowrld%2Fimages%2FnewBlueBird?alt=media&token=4ee8e426-a911-4d85-b4d3-746b9f5782fa",
  },
];

import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  db,
  getImageUrlFromStorage,
  prepairTemplateForDeployment,
  saveHelloWorldWebTemplate,
} from "../../../firebase-config";
import { SideBar } from "../../../reausable Components/sideBar";

import { objectToArray } from "../../../helperFunctions/objectToArray";

import {
  addNewObjectAtPath,
  updateObjectAtPath,
} from "../../../helperFunctions/updateObjectAtPath";
import { FloatingForm } from "../../../reausable Components/floatingForm";

import { TemplateDom } from "../components/domElements/templateDom";

import { returnSidebarButtonsArray } from "../data/sideBarButtons/sidebarButtons";

import { NewComponentSelector } from "../components/customComponents/newComponentSelector";
import { DynamicRecursiveComponentRenderer } from "../components/customComponents/dynamicRecursiveComponentRenderer";
import { ImageSelector } from "../components/customComponents/imageSelector";
import { getNewIndexForObjectAtPath } from "../../../helperFunctions/getNewIndexForObjectAtPath";
import { convertFilePositioningToPercentage } from "../../../helperFunctions/convertAbsolutePostitionToPercentage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/myLogo.png";

export const TemplateEditor = ({
  codeFile,
  currentSchool,
  onCodeFileChange,
  editedCodeFiles,
  templateId,
  codeFiles,
  setLoading,
  editorClipBoard,
  setEditorClipBoard,
  setIsTemplateSaved,
}) => {
  const [selectedComponent, setSelectedComponent] = useState({});
  const [selectedComponentId, setSelectedComponentId] = useState("");

  const [fileComponentsArray, setFileComponentsArray] = useState([]);
  const [fileComponentsObject, setFileComponentsObject] = useState({});

  const [selectedComponentPath, setSelectedComponentPath] = useState([]);

  const [newSectionForm, setNewSectionForm] = useState({
    name: "",
    active: false,
  });

  const [newComponentSelector, setNewComponentSelector] = useState({
    active: false,
    selectedComponent: {},
  });
  const [newImageSelector, setNewImageSelector] = useState({
    active: false,
    selectedComponent: {},
  });

  const [existingComponentIds, setExistingComponentIds] = useState([]);

  const [templateDomSize, setTemplateDomSize] = useState({
    width: 0,
    height: 0,
  });
  const [editorMode, setEditorMode] = useState("pc"); /* pc, mobile*/
  const [sidebarButtonsArray, setSideBarButtonsArray] = useState();

  const [fileSelectorActive, setFileSelectorActive] = useState(false);

  useEffect(() => {
    const newSideBarButtonsArray = returnSidebarButtonsArray(
      selectedComponent,
      currentSchool,
      setSelectedComponent,
      templateId,
      selectedComponentPath,
      setSelectedComponentId,
      setSelectedComponentPath,
      fileComponentsObject,
      setFileComponentsObject,
      codeFile,
      setNewComponentSelector,
      editorMode,
      codeFiles,
      editorClipBoard,
      setEditorClipBoard
    );
    setSideBarButtonsArray(newSideBarButtonsArray);
  }, [
    selectedComponent,
    currentSchool,
    setSelectedComponent,
    templateId,
    selectedComponentPath,
    setSelectedComponentId,
    setSelectedComponentPath,
    fileComponentsObject,
    setFileComponentsObject,
    codeFile,
    setNewComponentSelector,
    editorMode,
    codeFiles,
    editorClipBoard,
    setEditorClipBoard,
  ]);

  useEffect(() => {
    setFileComponentsArray(objectToArray(codeFile?.components));
    console.log(objectToArray(codeFile?.components));
  }, [codeFile]);

  useEffect(() => {
    setFileComponentsObject(codeFile?.components);
  }, [codeFile]);

  useEffect(() => {
    onCodeFileChange({
      ...codeFile,
      components: fileComponentsObject,
      componentsArray: objectToArray(fileComponentsObject),
    });
    setFileComponentsArray(objectToArray(fileComponentsObject));
    console.log({ components: fileComponentsObject });
  }, [fileComponentsObject]);

  async function saveTemplate(myTemplateId) {
    const currentTemplateRef = [
      "schools",
      currentSchool.schoolId,
      "savedTemplates",
      myTemplateId,
    ];
    let currentCodeFilesRef = [
      "schools",
      currentSchool.schoolId,
      "savedTemplates",
      myTemplateId,
      "codeFiles",
    ];
    try {
      await setDoc(doc(db, ...currentTemplateRef), {
        templateName: myTemplateId,
      });
    } catch (error) {
      throw Error("Issue Updating Template Id: " + error);
    }

    codeFiles.map(async (codeFile) => {
      try {
        await setDoc(doc(db, ...currentCodeFilesRef, codeFile.id), {
          ...codeFile,
        });
      } catch (error) {
        throw Error("Issue Saving CodeFile " + codeFile.id + " :" + error);
      }
    });
  }

  async function addTemplate() {
    try {
      await setDoc(doc(db, "websiteTemplates", templateId), {
        templateName: templateId,
      });
    } catch (error) {
      throw Error("Issue Updating Template Id: " + error);
    }

    codeFiles.map(async (codeFile) => {
      try {
        await setDoc(
          doc(db, "websiteTemplates", templateId, "codeFiles", codeFile.id),
          {
            ...codeFile,
          }
        );
      } catch (error) {
        throw Error("Issue Saving CodeFile " + codeFile.id + " :" + error);
      }
    });
  }

  return (
    <div>
      <select
        style={{ alignSelf: "flex-start" }}
        onChange={(e) => {
          setEditorMode(e.target.value);
        }}
        value={editorMode}
        name="codeFiles"
      >
        <option value={"mobile"}>Moblie Phone</option>
        <option value={"pc"}>PC</option>
      </select>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          backgroundColor: "aliceblue",
        }}
      >
        <TemplateDom
          setEditorMode={setEditorMode}
          editorMode={editorMode}
          domSize={templateDomSize}
          componentsObject={fileComponentsObject}
          setDomSize={setTemplateDomSize}
          onAddSection={() => {
            setNewSectionForm({ ...newSectionForm, active: true });
          }}
        >
          <DynamicRecursiveComponentRenderer
            editorMode={editorMode}
            templateDomHeight={templateDomSize.height}
            templateDomWidth={templateDomSize.width}
            setSelectedComponentPath={setSelectedComponentPath}
            selectedComponent={selectedComponent}
            components={fileComponentsArray}
            setFileComponentsObject={setFileComponentsObject}
            fileComponentsObject={fileComponentsObject}
            setSelectedComponent={setSelectedComponent}
            selectedComponentId={selectedComponentId}
            setSelectedComponentId={setSelectedComponentId}
            selectedComponentPath={[]}
          />
        </TemplateDom>

        {newSectionForm.active && (
          <FloatingForm
            onSubmit={async () => {
              setFileComponentsObject(
                updateObjectAtPath(
                  fileComponentsObject,
                  [newSectionForm.name],
                  {
                    type: "section",
                    name: newSectionForm.name,
                    backgroundImage: {
                      imgPathUrl: "",
                    },
                    index: getNewIndexForObjectAtPath(fileComponentsObject, []),
                    styles: {
                      height: "300px",
                      width: "100%",
                      backgroundColor: "yellow",
                      position: "relative",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      overflow: "visible",
                      zIndex: 0,
                    },
                    tabletStyles: { width: "100%" },
                    mobileStyles: { width: "100%" },
                  }
                )
              );
              setNewSectionForm({ active: false, name: "" });
            }}
            zIndex={20}
            inputs={[
              {
                type: "text",
                title: "Name",
                onChange: (e) => {
                  setNewSectionForm({
                    ...newSectionForm,
                    name: e.target.value,
                  });
                },
              },
            ]}
          />
        )}

        {newComponentSelector?.active && (
          <NewComponentSelector
            editorMode={editorMode}
            templateId={templateId}
            currentSchool={currentSchool}
            selectedComponent={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            existingComponentIds={existingComponentIds}
            setExistingComponentIds={setExistingComponentIds}
            onChooseComponent={(component) => {
              const newPath = [...selectedComponentPath];

              const newIndex = getNewIndexForObjectAtPath(
                fileComponentsObject,
                [...newPath, "nestedComponents"]
              );
              setNewComponentSelector({
                active: false,
              });
              setFileComponentsObject(
                updateObjectAtPath(
                  fileComponentsObject,
                  [
                    ...selectedComponentPath,
                    "nestedComponents",
                    component.name,
                  ],
                  {
                    ...component,
                    zIndex: newIndex,
                    index: newIndex,
                  }
                )
              );
            }}
          />
        )}

        {/* ImageSelector */}
        {newImageSelector.active && (
          <ImageSelector
            setImageSelector={setNewImageSelector}
            setFileSelectorActive={setFileSelectorActive}
            onImageChoose={async (image) => {
              if (selectedComponent.type === "image") {
                setNewImageSelector({ active: false });
                setSelectedComponent({
                  ...selectedComponent,
                  type: "image",
                  fileName: image.name,
                  imgPathUrl: await getImageUrlFromStorage(image.fullPath),
                });
              } else {
                setNewImageSelector({ active: false });
                setSelectedComponent({
                  ...selectedComponent,
                  backgroundImage: {
                    imgPathUrl: await getImageUrlFromStorage(image.fullPath),
                    fileName: image.name,
                  },
                });
              }
            }}
          />
        )}

        <SideBar
          setFileSelectorActive={setFileSelectorActive}
          fileSelectorActive={fileSelectorActive}
          extraBtn={{
            active: true,
            onClick: async () => {
              setLoading(true);
              await addTemplate();
              setLoading(false);
            },
            value: "Save as Template",
          }}
          saveBtn={{
            active: true,
            onClick: async () => {
              setLoading(true);
              await saveTemplate(templateId);
              console.log("saved");

              const modifiedCodeFiles = editedCodeFiles?.map(
                (currentCodeFile) => {
                  if (currentCodeFile.componentsArray) {
                    return {
                      ...currentCodeFile,
                      componentsArray: convertFilePositioningToPercentage(
                        currentCodeFile.componentsArray
                      ),
                    };
                  } else {
                    return currentCodeFile;
                  }
                }
              );
              console.log(modifiedCodeFiles);
              console.log(
                await prepairTemplateForDeployment({
                  codeFiles: modifiedCodeFiles,
                  schoolInfo: currentSchool,
                  templateName: templateId,
                })
              );
              if (!currentSchool?.savedTemplates) {
                updateDoc(doc(db, "schools", currentSchool.schoolId), {
                  savedTemplates: true,
                });
              }
              setIsTemplateSaved(true);
              setLoading(false);
            },
          }}
          key={"rightSidebar"}
          buttons={sidebarButtonsArray}
          side={"right"}
          setNewImageSelector={setNewImageSelector}
        />
      </div>
    </div>
  );
};

export const WebPagesEditor = ({
  template,
  currentSchool,
  onGoBack,
  setLoading,
  isTemplateSaved,
  setIsTemplateSaved,
}) => {
  const [codeFiles, setCodeFiles] = useState([]);
  const [editedCodeFiles, setEditedCodeFiles] = useState([]);
  const [pageState, setPageState] = useState("pageEditor"); // pageSelector, PageEditor
  const [selectedCodeFile, setSelectedCodeFile] = useState(codeFiles[0]);
  const [selectedCodeFileId, setSelectedCodeFileId] = useState(
    codeFiles[0]?.Id
  );
  const [newPageForm, setNewPageForm] = useState();
  const [editorClipBoard, setEditorClipBoard] = useState({});

  //This Effect is used to set the default value for "selectedCodeFileId"
  useEffect(() => {
    setSelectedCodeFileId(codeFiles[0]?.id);
  }, [codeFiles]);

  //This Effect is used to keep the "selectedCodeFile" state variable up to date.
  useEffect(() => {
    for (let codeFile of codeFiles) {
      if (codeFile.id === selectedCodeFileId) {
        setSelectedCodeFile(codeFile);
      }
    }
  }, [selectedCodeFileId, codeFiles]);

  useEffect(() => {
    const savedTemplatesCodeFilesRef = collection(
      db,
      "schools",
      currentSchool.schoolId,
      "savedTemplates",
      template.id,
      "codeFiles"
    );
    const defaultTemplatesCodeFilesRef = collection(
      db,
      "websiteTemplates",
      template.id,
      "codeFiles"
    );

    const unsubscribe = onSnapshot(
      /* This snapshot listener will update codefiles everyTime they change in my database*/
      isTemplateSaved
        ? savedTemplatesCodeFilesRef
        : defaultTemplatesCodeFilesRef,
      (querySnapshot) => {
        const newCodeFiles = [];

        querySnapshot.forEach((doc) => {
          newCodeFiles.push({ ...doc.data() });
        });
        setCodeFiles(newCodeFiles);
        setEditedCodeFiles(newCodeFiles);
      }
    );

    return unsubscribe;
  }, []);

  function goBack() {
    onGoBack();
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="consoleHeader">
        <div className="backButton" onClick={goBack}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>

        <img alt="logo" src={logo} className="consoleLogo" />
      </div>
      <div
        style={{
          flexGrow: 1,
          marginLeft: "45px",
          display: "flex",
          flexDirection: "column",
        }}
        className="editorContainer"
      >
        <select
          defaultValue={codeFiles[0]?.id}
          style={{ alignSelf: "center" }}
          onChange={(e) => {
            if (e.target.value === "New Page") {
              setNewPageForm({ ...newPageForm, active: true });
            } else {
              setSelectedCodeFileId(e.target.value);
              console.log(e.target.value);
            }
          }}
          value={selectedCodeFileId}
          name="codeFiles"
        >
          {codeFiles.map((codeFile) => {
            return <option value={codeFile.id}>{codeFile.id}</option>;
          })}
          <option value={"New Page"}>New Page +1</option>
        </select>

        {newPageForm?.active && (
          <FloatingForm
            onSubmit={async () => {
              await setDoc(
                doc(
                  db,
                  "schools",
                  currentSchool.schoolId,
                  "savedTemplates",
                  template.id,
                  "codeFiles",
                  newPageForm?.name
                ),
                { id: newPageForm?.name }
              );
              setNewPageForm({ ...newPageForm, active: false });
            }}
            inputs={[
              {
                type: "text",
                title: "name",
                onChange: (e) => {
                  setNewPageForm({ ...newPageForm, name: e.target.value });
                },
              },
            ]}
          />
        )}

        {pageState === "pageEditor" && (
          <TemplateEditor
            setIsTemplateSaved={setIsTemplateSaved}
            setEditorClipBoard={setEditorClipBoard}
            editorClipBoard={editorClipBoard}
            setLoading={setLoading}
            codeFiles={editedCodeFiles}
            templateId={template.id}
            editedCodeFiles={editedCodeFiles}
            currentSchool={currentSchool}
            codeFile={selectedCodeFile}
            onCodeFileChange={(newCodeFile) => {
              const newCodeFiles = editedCodeFiles.map((codeFile, index) => {
                if (codeFile.id === selectedCodeFileId) {
                  return newCodeFile;
                } else {
                  return codeFile;
                }
              });
              setEditedCodeFiles(newCodeFiles);
            }}
          />
        )}
      </div>
    </div>
  );
};

import {
  getImageUrlFromStorage,
  uploadFileToStorage,
} from "../../../../../firebase-config";
import { cloneAndRename } from "../../../../../helperFunctions/cloneAndRename";
import { deleteObjectAtPath } from "../../../../../helperFunctions/deleteObjectAtPath";
import { getValueAtPath } from "../../../../../helperFunctions/getValueAtPath";
import { generateRandomId } from "../../../../../helperFunctions/randomIdGenerator";
import { separateNumberFromUnit } from "../../../../../helperFunctions/separateNumberFromUnit";
import { addNewObjectAtPath } from "../../../../../helperFunctions/updateObjectAtPath";

export function returnPcImageSideBarButtons(
  setSelectedComponent,
  selectedComponent,
  setSelectedComponentId,
  setSelectedComponentPath,
  setFileComponentsObject,
  selectedComponentPath,
  fileComponentsObject,
  currentSchool,
  templateId,
  setEditorClipBoard
) {
  return [
    {
      inputValue: selectedComponent?.imgPathUrl,
      title: "",
      type: "image",
      category: "Image",
      onChange: async (v) => {
        try {
          const response = await uploadFileToStorage(
            `schools/${currentSchool.schoolId}/savedWebsiteTemplates/${templateId}/images/${v?.target?.files[0].name}`,
            v?.target?.files[0]
          );
          console.log(v?.target?.files[0]);
          setSelectedComponent({
            ...selectedComponent,
            imgPathUrl: await getImageUrlFromStorage(response.ref.fullPath),
            fileName: response.ref.name,
            type: "image",
          });

          console.log("uploaded");
        } catch (error) {
          console.log("failed to upload");
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.height,
      title: "Height",
      type: "number",
      category: "Size",
      units: ["px", "%"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "height"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              height: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.width,
      title: "Width",
      type: "number",
      category: "Size",
      units: ["px", "%"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "width"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              width: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.visibility,
      title: "Visibility",
      type: "select",
      options: ["visible", "hidden", "collapse"],
      category: "Flex Styles",
      onChange: async (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "visibility"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              visibility: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.zIndex,
      title: "Z-Index",
      type: "number",
      category: "Flex Styles",
      /*   units: ["px", "%"], */
      /*  includeAuto: true, */
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "zIndex"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              zIndex: v,
            },
          });
        }
      },
    },
    {
      inputValue: selectedComponent?.styles?.rotate,
      title: "Rotate",
      type: "number",
      category: "Flex Styles",
      units: ["deg"],
      includeAuto: true,
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "rotate"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            styles: {
              ...selectedComponent.styles,
              rotate: v,
            },
          });
        }
      },
    },
    {
      value: "Go to Parent",
      type: "plain",
      onClick: () => {
        if (selectedComponentPath.length > 1) {
          const newSelectedComponent = getValueAtPath(
            fileComponentsObject,
            selectedComponentPath.slice(0, -2)
          );
          setSelectedComponent(newSelectedComponent);
          setSelectedComponentId(newSelectedComponent.name);
          setSelectedComponentPath(selectedComponentPath.slice(0, -2));
        } else {
          console.log("no more parents");
        }
      },
    },
    {
      value: "Clone",
      type: "plain",
      onClick: () => {
        const pathToParent = [...selectedComponentPath].slice(0, -2);
        const selectedComponent = getValueAtPath(
          fileComponentsObject,
          selectedComponentPath
        );
        const cloneName = `${selectedComponent.name}clone${generateRandomId(
          2,
          []
        )}`;

        let newComponent = {
          ...getValueAtPath(fileComponentsObject, selectedComponentPath),
          name: cloneName,
          styles: {
            ...selectedComponent?.styles,
            top:
              separateNumberFromUnit(selectedComponent?.styles?.top)[0] +
              20 +
              "px",
          },
        };

        delete newComponent?.mobileStyles?.top;
        delete newComponent?.mobileStyles?.left;

        setFileComponentsObject(
          addNewObjectAtPath(
            fileComponentsObject,
            [...pathToParent, "nestedComponents", cloneName],
            newComponent
          )
        );
        setSelectedComponent(newComponent);
        setSelectedComponentPath([
          ...pathToParent,
          "nestedComponents",
          cloneName,
        ]);
        setSelectedComponentId(cloneName);
      },
    },
    {
      type: "plain",
      value: "copy",
      onClick: () => {
        setEditorClipBoard({
          ...cloneAndRename(selectedComponent),
          name: selectedComponent.name + "Clone" + generateRandomId(3, []),
        });
        setSelectedComponent({});
        setSelectedComponentId("");
        setSelectedComponentPath([]);
      },
    },
    {
      type: "plain",
      value: "delete",
      onClick: () => {
        setFileComponentsObject(
          deleteObjectAtPath(fileComponentsObject, [...selectedComponentPath])
        );
        setSelectedComponent({});
        setSelectedComponentId("");
        setSelectedComponentPath([]);
      },
    },
  ];
}

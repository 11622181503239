import { useState } from "react";
import { AllForms } from "./pages/allForms";
import { FormSubmissions } from "./pages/formSubmissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import logo from "./../../assets/myLogo.png";

export const FormsMananger = ({ currentSchool }) => {
  const [pageState, setPageState] = useState("allForms"); // allForms, submissions;
  const [selectedFormId, setSelectedFormId] = useState("");
  function onBackPress() {
    setPageState("allForms");
  }
  return (
    <div style={{ marginTop: "100px" }}>
      <div className="consoleHeader">
        {pageState === "submissions" && (
          <div className="backButton" onClick={onBackPress}>
            <FontAwesomeIcon size="2x" icon={faArrowLeft} />
          </div>
        )}
        <img
          alt="logo"
          src={logo}
          style={{
            width: "80px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
      {pageState === "allForms" && (
        <AllForms
          setPageState={setPageState}
          setSelectedFormId={setSelectedFormId}
          currentSchool={currentSchool}
        />
      )}
      {pageState === "submissions" && (
        <FormSubmissions
          selectedFormId={selectedFormId}
          currentSchool={currentSchool}
        />
      )}
    </div>
  );
};

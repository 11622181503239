// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDSa5voF8N1mMinL75K4ovKmjj-FrA69YQ",
  authDomain: "classroomapp-9e7c6.firebaseapp.com",
  projectId: "classroomapp-9e7c6",
  storageBucket: "classroomapp-9e7c6.appspot.com",
  messagingSenderId: "1079909076722",
  appId: "1:1079909076722:web:ad44e5ed3727c2e0390a6b",
  measurementId: "G-F9SX4ZY3PP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Cloud Storage
const storage = getStorage(app);

export async function getImageUrlFromStorage(pathUrl) {
  const url = await getDownloadURL(ref(storage, pathUrl));
  return url;
}

export async function uploadFileToStorage(pathUrl, file) {
  const response = await uploadBytes(ref(storage, pathUrl), file);
  return response;
}

export async function getAllImagesUrlsFromStorage(pathUrl) {
  const items = [];

  (await listAll(ref(storage, pathUrl))).items.forEach((item) => {
    items.push(item);
    console.log(item.name);
    console.log(item.fullPath);
  });
  return items;
}

//Initialize Auth
export const auth = getAuth();

// Initialize Cloud functions
const functions = getFunctions(app);

export const addChildToExistingParent = httpsCallable(
  functions,
  "addChildToExistingParent"
);
export const getUserBasicInfo = httpsCallable(functions, "getUserBasicInfo");
export const changeTeacherAccountToHybrid = httpsCallable(
  functions,
  "changeTeacherAccountToHybrid"
);
export const changeParentAccountToHybrid = httpsCallable(
  functions,
  "changeParentAccountToHybrid"
);
export const adminCreateAccountWithEmailAndPassword = httpsCallable(
  functions,
  "adminCreateAccountWithEmailAndPassword"
);

export const deleteStudent = httpsCallable(functions, "deleteStudent");
export const deleteClassroom = httpsCallable(functions, "deleteClassroom");

export const saveHelloWorldWebTemplate = httpsCallable(
  functions,
  "saveHelloWorldWebTemplate"
);
export const prepairTemplateForDeployment = httpsCallable(
  functions,
  "prepairTemplateForDeployment"
);

import { addDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { generateRandomId } from "../../../helperFunctions/randomIdGenerator";
import { db } from "../../../firebase-config";
import { useState } from "react";
import { FloatingForm } from "../../../reausable Components/floatingForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/myLogo.png";

export const WebsiteTemplateSelector = ({
  templates,
  onClick,
  currentSchool,
  onGoBack,
  savedTemplates,
  isTemplateSaved,
  setIsTemplateSaved,
}) => {
  const [newTemplateForm, setNewTemplateForm] = useState({
    active: false,
    templateName: "",
  });
  async function createNewTemplate(name) {
    const templateName = name + generateRandomId(3, []);

    const newTemplateRef = doc(
      db,
      "schools",
      currentSchool.schoolId,
      "savedTemplates",
      templateName
    );

    const newFileRef = doc(
      db,
      "schools",
      currentSchool.schoolId,
      "savedTemplates",
      templateName,
      "codeFiles",
      "First Page"
    );

    const currentSchoolRef = doc(db, "schools", currentSchool.schoolId);

    await setDoc(newTemplateRef, { templateName });

    await setDoc(newFileRef, { id: "First Page" });
    await updateDoc(currentSchoolRef, { savedTemplates: true });
  }

  function goBack() {
    onGoBack();
  }

  return (
    <div>
      <div className="consoleHeader">
        <div className="backButton" onClick={goBack}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </div>

        <img alt="logo" src={logo} className="consoleLogo" />
      </div>
      <div style={{ marginLeft: "45px" }}>
        <h3 style={{ color: "blue" }}>Default Templates</h3>
        {templates?.map((template) => (
          <h4
            onClick={() => {
              onClick(template);
              setIsTemplateSaved(false);
            }}
            style={{}}
          >
            {template.id}
          </h4>
        ))}
        <div
          style={{ height: "2px", width: "100%", backgroundColor: "black" }}
        ></div>
        <h3 style={{ color: "blue" }}>Saved Templates</h3>
        {savedTemplates?.map((template) => (
          <h4
            onClick={() => {
              onClick(template);
              setIsTemplateSaved(true);
            }}
            style={{}}
          >
            {template.id}
          </h4>
        ))}
        {newTemplateForm.active && (
          <FloatingForm
            onSubmit={async () => {
              await createNewTemplate(newTemplateForm.templateName);
              setNewTemplateForm({ active: false });
            }}
            inputs={[
              {
                type: "text",
                title: "New Template Name",
                onChange: (e) => {
                  setNewTemplateForm({
                    ...newTemplateForm,
                    templateName: e?.target?.value,
                  });
                },
                value: newTemplateForm?.templateName,
              },
            ]}
          />
        )}
        <button
          onClick={(e) => {
            e.preventDefault();
            setNewTemplateForm({ active: true });
          }}
          style={{ position: "absolute" }}
          className="button"
        >
          New Template
        </button>
      </div>
    </div>
  );
};

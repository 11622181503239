export function separateNumberFromUnit(value = "") {
  // Use regular expression to separate numeric value and unit
  const match = value?.match(/^(-?[\d.]+)([a-zA-Z%]*)$/);

  if (match) {
    let numericValue = 0;
    let unit = "";

    numericValue = parseFloat(match[1]);
    unit = match[2];

    return [numericValue, unit];
  } else {
    // If no match, return default values
    return [];
  }
}

export function convertAbsolutePositionToPixels(parentWidth, parentHeight, childLeftPercentage, childTopPercentage) {
    // Convert percentages to pixels
    let childLeft = (childLeftPercentage / 100) * parentWidth;
    let childTop = (childTopPercentage / 100) * parentHeight;
   
    const childLeftString = childLeft.toString()
    const childTopString = childTop.toString()
    if(childLeftString > 7){
      childLeft = childLeftString.slice(0, 7)
    }
    if(childTopString > 7){
      childTop = childTopString.slice(0, 7)
    }

    
    // Return an object with the calculated pixel values
    return {
      left: Math.round(childLeft),
      top: Math.round(childTop) 
    };
  }
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../../firebase-config";
import { FormSubmission } from "../components/formSubmissionDisplayer";

export const FormSubmissions = ({ currentSchool, selectedFormId }) => {
  const [formSubmissions, setFormSubmissions] = useState([]);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState("");
  const [sortingFieldString, setSortingFieldString] = useState("");
  const [formFieldKeys, setFormFieldKeys] = useState([""]);
  const [mainFormKey, setMainFormKey] = useState("Name");

  useEffect(() => {
    const removeListener = onSnapshot(
      query(
        collection(
          db,
          "schools",
          currentSchool.schoolId,
          "forms",
          selectedFormId,
          "submissions"
        ),
        orderBy("serverTimeStamp", "desc")
      ),
      (snap) => {
        const currentFormSubmissions = [];
        snap.forEach((docSnap) => {
          let tempDoc = { ...docSnap.data(), ["Submission Id"]: docSnap.id };

          delete tempDoc.serverTimeStamp;
          currentFormSubmissions.push(tempDoc);
        });

        if (sortingFieldString === "") {
          setSortingFieldString(currentFormSubmissions[0]["Submission Id"]);
        }
        setFormSubmissions(currentFormSubmissions);
        console.log(currentFormSubmissions);
      }
    );

    return () => {
      removeListener();
    };
  }, [currentSchool.schoolId, selectedFormId, sortingFieldString]);

  useEffect(() => {
    const tempFormFieldKeys = [];
    for (let fieldKey in formSubmissions[0]) {
      tempFormFieldKeys.push(fieldKey);
    }
    setFormFieldKeys(tempFormFieldKeys);
  }, [formSubmissions]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      <h1>Forms</h1>
      <div style={{ width: "90%" }}>
        <select
          value={mainFormKey}
          onChange={(e) => {
            setMainFormKey(e.target.value);
          }}
        >
          {formFieldKeys.map((fieldKey) => {
            if (fieldKey !== "isSubmissionNew") {
              return <option value={fieldKey}>{fieldKey}</option>;
            }
          })}
        </select>
        {formSubmissions?.map((submission) => {
          return (
            <FormSubmission
              currentSchool={currentSchool}
              mainFormKey={mainFormKey}
              formFieldKeys={formFieldKeys}
              setSelectedSubmissionId={setSelectedSubmissionId}
              selectedSubmissionId={selectedSubmissionId}
              submission={submission}
              selectedFormId={selectedFormId}
            />
          );
        })}
      </div>
    </div>
  );
};

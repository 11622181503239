import { collection, doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../../firebase-config";

const FormItem = ({ formName, setSelectedFormId, formId, setPageState }) => {
  const [hovering, setHovering] = useState(false);
  return (
    <div
      onClick={() => {
        setSelectedFormId(formId);
        setPageState("submissions");
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={{
        cursor: hovering && "pointer",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "12px",
        paddingRight: "12px",
        boxShadow: hovering ? "3px 4px 3px 4px #2222ff" : "3px 4px #333",
      }}
    >
      <h3>{formName}</h3>
    </div>
  );
};
export const FormsSelector = ({ forms, setSelectedFormId, setPageState }) => {
  return (
    <div
      style={{
        width: "80%",
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
      }}
    >
      {forms.map((form) => {
        return (
          <FormItem
            setPageState={setPageState}
            setSelectedFormId={setSelectedFormId}
            formName={form?.displayName}
            formId={form.id}
          />
        );
      })}
    </div>
  );
};

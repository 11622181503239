import { useState } from "react";
import { SchoolsMananger } from "./pages/schoolsMananger";
import { SchoolMananger } from "./pages/schoolMananger";

export const SuperAdminConsole = ({ setAppState, setLoading }) => {
  const [pageState, setPageState] = useState("schoolsMananger");
  const [selectedSchool, setSelectedSchool] = useState({});
  return pageState === "schoolsMananger" ? (
    <SchoolsMananger
      setSelectedSchool={setSelectedSchool}
      setPageState={setPageState}
    />
  ) : pageState === "schoolMananger" ? (
    <SchoolMananger
      setLoading={setLoading}
      setAppState={setAppState}
      selectedSchool={selectedSchool}
    />
  ) : (
    ""
  );
};

import { useEffect, useState } from "react";
import { separateNumberFromUnit } from "../../../../helperFunctions/separateNumberFromUnit";

export const SlidePicker = ({
  infoObject,
  setSelectedComponent,
  editorMode,
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    setSelectedComponent((prevSelectedComponent) => ({
      ...prevSelectedComponent,
      nestedComponents: {
        ...prevSelectedComponent?.nestedComponents,
        [`${prevSelectedComponent?.name}-slidesContainer`]: {
          ...prevSelectedComponent?.nestedComponents[
            `${prevSelectedComponent?.name}-slidesContainer`
          ],
          styles: {
            ...prevSelectedComponent?.nestedComponents[
              `${prevSelectedComponent?.name}-slidesContainer`
            ]?.styles,
            transform:
              editorMode === "pc"
                ? `translateX(-${
                    currentSlideIndex *
                    separateNumberFromUnit(
                      prevSelectedComponent?.styles?.width
                    )[0]
                  }px)`
                : `translateX(-${
                    currentSlideIndex *
                    separateNumberFromUnit(
                      prevSelectedComponent.mobileStyles.width
                    )[0]
                  }px)`,
          },
        },
      },
    }));
  }, [
    editorMode,
    currentSlideIndex,
    infoObject.styles.width,
    infoObject.styles.height,
    infoObject.mobileStyles.width,
    infoObject.mobileStyles.height,
  ]);
  return (
    <div
      style={{
        backgroundColor: "darkblue",
        borderRadius: "4px",
        position: "absolute",
        padding: "5px",
        bottom: "1px",

        display: "flex",
        flexDirection: "row",
        columnGap: "3px",
      }}
    >
      <span
        onClick={() => {
          if (currentSlideIndex > 0) {
            setCurrentSlideIndex(currentSlideIndex - 1);
          }
        }}
        style={{
          backgroundColor: "blue",
          color: "whitesmoke",
          fontWeight: "800",
          padding: "2px",
        }}
      >
        {"<"}
      </span>
      <span style={{ color: "white", fontWeight: "700" }}>
        Slide {currentSlideIndex + 1} of{" "}
        {infoObject?.script?.variables?.numberOfSlides}
      </span>
      <span
        onClick={() => {
          if (
            currentSlideIndex + 1 <
            infoObject?.script?.variables?.numberOfSlides
          ) {
            setCurrentSlideIndex(currentSlideIndex + 1);
          }
        }}
        style={{
          backgroundColor: "blue",
          color: "whitesmoke",
          fontWeight: "800",
          padding: "2px",
        }}
      >
        {">"}
      </span>
    </div>
  );
};

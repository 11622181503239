export function returnTextArea(name) {
  return {
    name: name,
    type: "textArea",
    inputType: "text",
    mobileStyles: {},

    styles: {
      position: "absolute",
      backgroundColor: "white",
    },
    inputName: name,
    placeHolder: "text",
  };
}

export const textArea = {
  category: "Input",
  name: "TextArea",
  type: "textArea",
  inputType: "text",
  validParents: ["form"],
  styles: {
    backgroundColor: "white",
  },
  placeHolder: "text",
};

import { arrayToObject } from "../../../../../helperFunctions/arrayToObject";
import { deleteObjectAtPath } from "../../../../../helperFunctions/deleteObjectAtPath";

import {
  objectToArray,
  oneLayerObjectToArray,
} from "../../../../../helperFunctions/objectToArray";
import { generateRandomId } from "../../../../../helperFunctions/randomIdGenerator";
import { returnNumberOfSiblingAtPath } from "../../../../../helperFunctions/returnNumberOfSiblingsAtPath";
import { addNewObjectAtPath } from "../../../../../helperFunctions/updateObjectAtPath";

export function returnPcMenuItemsContainerSidebarButtons(
  setSelectedComponent,
  selectedComponent,
  setSelectedComponentId,
  setSelectedComponentPath,
  setFileComponentsObject,
  selectedComponentPath,
  fileComponentsObject,
  setNewComponentSelector
) {
  return [
    {
      inputValue: selectedComponent?.styles?.height,
      title: "Height",
      type: "number",
      category: "Size",
      units: ["px", "%"],
      onChange: (v) => {
        if (v === "" || !v) {
          setSelectedComponent(
            deleteObjectAtPath(selectedComponent, ["styles", "height"])
          );
        } else {
          setSelectedComponent({
            ...selectedComponent,
            // changing the height of the column's top layer
            styles: {
              ...selectedComponent.styles,
              height: v,
            },
          });
        }
      },
    },

    {
      type: "plain",
      value: "delete",
      onClick: () => {
        let currentObj = deleteObjectAtPath(fileComponentsObject, [
          ...selectedComponentPath,
        ]);

        setFileComponentsObject(currentObj);
        setSelectedComponent({});
        setSelectedComponentId("");
        setSelectedComponentPath([]);
      },
    },

    {
      type: "plain",
      title: "add Element",
      value: "Add Column",
      onClick: () => {
        const numberOfColumns =
          selectedComponent?.script?.variables?.numberOfColumns || 0;

        const newColumnName = `${
          selectedComponent.name
        }PlainStripColumn${generateRandomId(4, [])}`;

        /*  setNewElementForm({ ...newElementForm, active: true }); */
        const newSelectedComponent = addNewObjectAtPath(
          selectedComponent,
          ["nestedComponents", newColumnName],
          {
            active: true,
            name: newColumnName,
            type: "relativeContainer",
            styles: {
              height: "50px",
              width: "100%",

              backgroundColor: "blue",
              position: "relative",
              padding: "0px",
              margin: "0px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              /*  flexGrow: 1,
              flexShrink: 0, */
              minWidth: "10px",
            },
            index: numberOfColumns + 1,
            backgroundImage: {
              imgPathUrl: "",
            },
          }
        );
        setSelectedComponent({
          ...newSelectedComponent,
          script: {
            ...newSelectedComponent.script,
            variables: {
              ...newSelectedComponent.script.variables,
              numberOfColumns: numberOfColumns + 1,
            },
          },
        });
      },
    },
  ];
}

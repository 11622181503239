export function returnFormScript(name, currentSchool, displayName) {
  return `const ${name} = document.getElementById("${name}");
    ${name}.addEventListener("submit", function (event) {
      event.preventDefault(); // Prevent default form submission

      const formData = new FormData(event.target); // Get form data
      const formDataObject = Object.fromEntries(formData.entries());
      console.log(formDataObject);

      const finalFormObject = {
        formFields: formDataObject,
        formName: "${name}",
        schoolPublicId: "${currentSchool.schoolPublicId}",
        displayName: "${displayName}"
      };

      fetch(
        "https://us-central1-classroomapp-9e7c6.cloudfunctions.net/submitForm",
        {
          method: "POST",
          body: JSON.stringify(finalFormObject),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Success:", data);
          // Handle success response from Cloud Function
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle error
        });
    });`;
}
